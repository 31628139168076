import React, { useState } from 'react'
import { AppDashboardGridItemWrapper } from '../style'
// import { AppTab, AppTabContainer } from 'components/Common/AppButtonTab/ AppButtonTab.component'
import {
  AppTabContainerNew,
  AppTab as AppTabNew,
} from 'components/Common/AppButtonTab/AppButtonTabNew.component'
import { AppModalBox } from 'components/Common/AppModalBox/AppModalBox.component'
import AppCrossIcon from 'components/Common/AppSvgIcons/AppModalCrossIcon'
import { AppTask } from 'components/AppKanbanBoard/AppTask/AppTask.component'
import { AppButton } from 'components/Common/AppButton/AppButton.component'
import { AppToolTip } from 'components/Common/AppToolTip/AppToolTip.component'
import { AppToolTipIcon } from 'components/Common/AppToolTip/AppToolTipIcon'
import {
  BusinessGoalDateFilter,
  UserTask,
  UserTaskQuickFilter,
  UserTaskSource,
} from '__generated__/api-types-and-hooks'
import { useKanbanBoardData } from 'components/AppKanbanBoard/AppHook/AppKanbanBoard.hook'
import { useGetUserTasks } from 'appHooks/useGetUserTasks'
import { ITaskSummaryItem } from 'components/AppDashboard/interfaces'
import { AppTaskCreationConfirmation } from 'components/AppKanbanBoard/AppTaskCreationConfirmation/AppTaskCreationConfirmation.component'
import { getRoleFromPath } from 'utils/helper'
import { ROLES } from 'config'
import { AppTabContainer, AppTab } from 'components/Common/AppButtonTab/ AppButtonTab.component'
import { IAssigneeOptions, useGetBusinessProfile } from 'appHooks/useGetBusinessProfile'
import { AppTaskCard } from 'components/AppKanbanBoard/AppTaskCard/AppTaskCard.component'
import { categoryType } from 'appConfig/data'
import { ChevronDownIcon } from '@heroicons/react/outline'

interface ITasksSummaryHeaderProps {
  setShowAddTaskModal: (show: boolean) => void
}

interface ITasksSummaryBodyItemsProps {
  tasks: ITaskSummaryItem[]
  refetch: () => void
  assigneeOptions: IAssigneeOptions[]
  advisorsData: {
    label: string
    value: string
  }[]
  userAvatar: string
  userFirstName: string
  userLastName: string
  userName: string
  logo: string
}

const AppDashboardTasksSummary = () => {
  const [showAddTaskModal, setShowAddTaskModal] = useState(false)
  const [showAddTaskSuccessModal, setShowAddTaskSuccessModal] = useState(false)
  const [showAddTaskErrorModal, setShowAddTaskErrorModal] = useState(false)
  const [createdUserTask, setCreatedUserTask] = useState<UserTask>({})
  const [filter] = useState<Record<string, (string | number)[] | (string | number)>>({
    dateRange: BusinessGoalDateFilter.EntireGamePlan,
  })
  const {
    // userName,
    // advisorsData,
    // logo,
    userId,
    tenantId,
    taskPlan,
    // userAvatar,
    // userFirstName,
    // userLastName,
    goalEndDate,
    assigneeOptions,
  } = useKanbanBoardData({ filter })

  const { userAvatar, userFirstName, userLastName, userName, logo, advisorsData } =
    useGetBusinessProfile({})
  // const role = getRoleFromPath()
  const { todoTasks, inProgressTasks, blockedTasks, refetchUserTasks } = useGetUserTasks({
    filter: BusinessGoalDateFilter.Today,
    quickFilter: UserTaskQuickFilter.AllTasks,
    assigneeId: userId,
  })
  return (
    <>
      <AppDashboardGridItemWrapper>
        <div>
          <AppTabContainer
            showActiveTabHeader={true}
            tabContentClassName="mt-4"
            tabHeaderClassName="border-b border-grey-darker w-full py-2"
            tabHeaderTabsClassName="space-x-2 text-xs bg-grey-grey1x shadow-lg rounded border-grey-light"
          >
            <AppTab tabName="Today">
              <TasksSummaryHeader setShowAddTaskModal={setShowAddTaskModal} />
              <div className="mt-4">
                {/* {role?.includes(ROLES.BUSINESS_OWNER) ? ( */}
                <AppTabContainerNew tabAlignment="center">
                  <AppTabNew tabName="To Do" tabCount={todoTasks.length}>
                    <TasksSummaryBodyItems
                      tasks={todoTasks}
                      refetch={refetchUserTasks}
                      assigneeOptions={assigneeOptions}
                      userAvatar={userAvatar ?? ''}
                      userFirstName={userFirstName ?? ''}
                      userLastName={userLastName ?? ''}
                      userName={userName}
                      logo={logo}
                      advisorsData={advisorsData}
                    />
                  </AppTabNew>
                  <AppTabNew tabName="In Progress" tabCount={inProgressTasks.length}>
                    <TasksSummaryBodyItems
                      tasks={inProgressTasks}
                      refetch={refetchUserTasks}
                      assigneeOptions={assigneeOptions}
                      userAvatar={userAvatar ?? ''}
                      userFirstName={userFirstName ?? ''}
                      userLastName={userLastName ?? ''}
                      userName={userName}
                      logo={logo}
                      advisorsData={advisorsData}
                    />
                  </AppTabNew>
                  <AppTabNew tabName="Blocked" tabCount={blockedTasks.length}>
                    <TasksSummaryBodyItems
                      tasks={blockedTasks}
                      refetch={refetchUserTasks}
                      assigneeOptions={assigneeOptions}
                      userAvatar={userAvatar ?? ''}
                      userFirstName={userFirstName ?? ''}
                      userLastName={userLastName ?? ''}
                      userName={userName}
                      logo={logo}
                      advisorsData={advisorsData}
                    />
                  </AppTabNew>
                </AppTabContainerNew>
                {/* ) : (
                <div className="h-[300px] bg-app-white-100 flex items-center justify-center font-medium text-sm">
                  You cannot view this information
                 </div>
                )} */}
              </div>
            </AppTab>
          </AppTabContainer>
        </div>
      </AppDashboardGridItemWrapper>
      {showAddTaskModal && (
        <AppModalBox
          Icon={AppCrossIcon}
          height="max-h-[835px] h-full"
          width="max-w-2xl"
          title={`Create new Play`}
          showBorder={false}
          onClose={() => {
            setShowAddTaskModal(false)
          }}
          onBack={() => {
            setShowAddTaskModal(false)
          }}
        >
          <AppTask
            tenantId={tenantId}
            userId={userId}
            plan={taskPlan}
            source={UserTaskSource.Goal}
            setShowTaskModal={setShowAddTaskModal}
            setShowTaskSuccessModal={setShowAddTaskSuccessModal}
            setShowTaskErrorModal={setShowAddTaskErrorModal}
            setCreatedUserTask={setCreatedUserTask}
            refetch={refetchUserTasks}
            logo={logo ?? ''}
            goalEndDate={goalEndDate!}
            advisorsData={advisorsData}
            userName={userName}
            userAvatar={userAvatar ?? ''}
            userFirstName={userFirstName ?? ''}
            userLastName={userLastName ?? ''}
            assigneeOptions={assigneeOptions}
          />
        </AppModalBox>
      )}
      {(showAddTaskSuccessModal || showAddTaskErrorModal) && (
        <AppTaskCreationConfirmation
          showAddTaskSuccessModal={showAddTaskSuccessModal}
          setShowAddTaskModal={setShowAddTaskModal}
          setShowAddTaskSuccessModal={setShowAddTaskSuccessModal}
          setShowAddTaskErrorModal={setShowAddTaskErrorModal}
          createdUserTask={createdUserTask}
          userId={userId}
          userAvatar={userAvatar ?? ''}
          userFirstName={userFirstName ?? ''}
          userLastName={userLastName ?? ''}
        />
      )}
    </>
  )
}

const TasksSummaryHeader: React.FC<ITasksSummaryHeaderProps> = ({ setShowAddTaskModal }) => {
  const role = getRoleFromPath()
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-2">
        <h3 className="font-bold text-xl">Plays</h3>
        <AppToolTip variant="primary" name={'tasks'} text={'This is a summary of your tasks'}>
          <AppToolTipIcon />
        </AppToolTip>
      </div>
      <AppButton
        label="Add a Play"
        variant="secondary"
        onClick={() => setShowAddTaskModal(true)}
        size="md"
        disabled={!role?.includes(ROLES.BUSINESS_OWNER)}
      />
    </div>
  )
}

const TasksSummaryBodyItems: React.FC<ITasksSummaryBodyItemsProps> = ({
  tasks,
  refetch,
  assigneeOptions,
  userAvatar,
  userFirstName,
  userLastName,
  userName,
  logo,
  advisorsData,
}) => {
  const [showAll, setShowAll] = React.useState(false)
  const [showTaskDetails, setShowTaskDetails] = useState(false)
  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null)
  const displayedTasks = showAll ? tasks : tasks?.slice(0, 2)
  const hasMoreTasks = tasks?.length > 2
  const selectedTask = tasks?.find((task) => task.taskId === selectedTaskId)
  return (
    <div className="mt-8 h-[220px] md:h-[240px] -mb-4 -mx-4 px-4 md:-mx-8 md:px-8 overflow-y-auto">
      {tasks?.length > 0 ? (
        <div className="space-y-4">
          {displayedTasks?.map((task) => (
            <div
              key={task.taskId}
              className="p-4 rounded-xl border border-app-grey-30 shadow-sm min-h-[100px] cursor-pointer"
              onClick={() => {
                setSelectedTaskId(task.taskId ?? null)
                setShowTaskDetails(true)
              }}
            >
              <AppTaskCard
                task={task}
                userId={task.userId ?? ''}
                userAvatar={userAvatar ?? ''}
                userFirstName={userFirstName ?? ''}
                userLastName={userLastName ?? ''}
                onTrashClick={() => {}}
                refetch={refetch}
                advisorsData={advisorsData}
                assigneeOptions={assigneeOptions}
              />
            </div>
          ))}
          {hasMoreTasks && !showAll && (
            <button
              onClick={() => setShowAll(true)}
              className="py-2 px-4 flex items-center gap-2 border border-app-grey-30 rounded-lg"
            >
              <span className="text-app-black-100 font-normal text-sm">Show More</span>{' '}
              <ChevronDownIcon className="w-4 h-4 text-app-black-100" />
            </button>
          )}
        </div>
      ) : (
        <div>No results</div>
      )}

      {/* Task Details Modal */}
      {showTaskDetails && selectedTask && (
        <AppModalBox
          Icon={AppCrossIcon}
          height="max-h-[835px] h-full"
          width="max-w-2xl"
          showBorder={false}
          title={`${selectedTask.category ? `${categoryType[selectedTask.category]} /` : ' '} ${
            selectedTask.name
          } `}
          titleSize="sm"
          onClose={() => {
            setShowTaskDetails(false)
            setSelectedTaskId(null)
          }}
          onBack={() => {
            setShowTaskDetails(false)
            setSelectedTaskId(null)
          }}
        >
          <AppTask
            task={selectedTask}
            logo={logo ?? ''}
            tenantId={selectedTask.tenantId ?? ''}
            source={UserTaskSource.Goal}
            setShowTaskModal={setShowTaskDetails}
            refetch={refetch}
            userId={selectedTask.userId ?? ''}
            userName={userName}
            advisorsData={advisorsData}
            plan={{
              name: selectedTask.planName,
              id: selectedTask.planId,
              planExpectedEndDate: selectedTask.planExpectedEndDate,
            }}
            goalEndDate={''}
            userAvatar={userAvatar ?? ''}
            userFirstName={userFirstName ?? ''}
            userLastName={userLastName ?? ''}
            goalId={selectedTask.goalId ?? ''}
            assigneeOptions={assigneeOptions}
          />
        </AppModalBox>
      )}
    </div>
  )
}

export default AppDashboardTasksSummary
