import React, { useEffect, useState } from 'react'

import { categoryType } from 'appConfig/data'
import { getColorForGoalCategory } from 'appUtils'
import { AppBadge } from 'components/Common/AppBadge/AppBadge.component'
import { ISmartGoal } from 'components/AppOnboarding/AppChatbot/AppDynamicChatMessage/AppSmartGoalCard/types'
import {
  AppContainer,
  AppSection,
  AppGoalHeading,
  AppGoalText,
} from 'components/AppOnboarding/AppChatbot/AppDynamicChatMessage/AppSmartGoalCard/AppSmartGoalCard.style'
import { AppTextArea } from 'components/Common/AppFormFields/AppTextArea/AppTextArea.component'
import { useClickOutside } from 'hooks/AppuseClickOutside/useClickOutside.hook'
import PencilSquareIcon from 'assets/svgs/pencil-square.svg'
interface IAppSmartGoalCardProps {
  smartGoal: ISmartGoal
  onChange: (updatedGoal: ISmartGoal) => void
  disabled?: boolean
}

export const AppSmartGoalCard: React.FC<IAppSmartGoalCardProps> = ({
  smartGoal,
  onChange,
  disabled,
}) => {
  const [editGoalName, setEditGoalName] = useState<boolean>(false)
  const [goalNameValue, setGoalNameValue] = useState<string>(smartGoal.goalName)
  const divRef = useClickOutside(() => {
    if (!disabled) {
      setEditGoalName(false)
    }
  })

  useEffect(() => {
    if (!disabled) {
      onChange({ ...smartGoal, goalName: goalNameValue })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalNameValue])

  return (
    <AppContainer>
      <AppSection>
        <AppGoalHeading>
          <div className="flex items-center gap-2">
            {smartGoal.smartHeading && (
              <AppBadge
                label={`"${smartGoal.smartHeading}"`}
                size="md"
                color="Grey"
                type="Outline"
              />
            )}
            {smartGoal.category && (
              <AppBadge
                label={categoryType[smartGoal.category]}
                size="md"
                color={getColorForGoalCategory(smartGoal.category)}
                type="Outline"
              />
            )}
          </div>
          <div className="flex items-center gap-2">
            <div
              className={`flex items-center gap-2 ${
                disabled ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
              onClick={() => {
                if (!disabled) {
                  setEditGoalName(true)
                }
              }}
            >
              <div className="text-sm text-app-grey-55">Edit Goal</div>
              <img
                src={PencilSquareIcon}
                alt="pencil-square"
                className="w-6 h-6 fill-app-grey-55"
              />
            </div>
          </div>
        </AppGoalHeading>
        <div ref={divRef}>
          {editGoalName ? (
            <AppTextArea
              rows={3}
              value={goalNameValue}
              onChange={(e) => setGoalNameValue(e.target.value)}
            />
          ) : (
            <AppGoalText
              onClick={() => {
                if (!disabled) {
                  setEditGoalName(true)
                }
              }}
            >
              {goalNameValue}
            </AppGoalText>
          )}
        </div>
      </AppSection>
    </AppContainer>
  )
}
