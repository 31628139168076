import { CancelIcon, NotificationBellIcon } from 'components/Common/SvgIcons'
import styled from 'styled-components'
import tw from 'twin.macro'

interface CustomWrapperProps {
  isWelcomeScreen: boolean
}

export const NotificationWrapper = styled.div.attrs({
  className:
    'xs:fixed sm:absolute sm:pr-1 xs:pl-0 top-16 xs:top-0 right-0 z-[100] sm:w-[400px] shadow-lg border border-app-grey-25 bg-white xs:w-full xs:top-0 xs:right-0 z-[1025] xs:after:hidden xs:h-[100vh] sm:h-[95vh] lg:h-[90vh] sm:top-[70px] overflow-hidden rounded-lg',
})``
export const NotificationPanelWrapper = styled.div.attrs({
  className: 'bg-gradient-to-r from-app-primary-5 to-white border-b border-app-grey-25',
})``
export const NotificationBellIconWrapper = styled(NotificationBellIcon)`
  ${tw`xs:inline-block h-6 w-6 mr-3 inline-block fill-app-primary-75`}
`
export const NotificationDesktopClearCount = styled.div`
  ${tw`xs:hidden sm:inline-block font-primary font-bold leading-5 text-primary text-base cursor-pointer hover:text-primary-brand transition-colors duration-200`}
`
export const NotificationPanelCount = styled.div`
  ${tw`xs:inline-block font-primary font-light leading-8 text-primary-text text-base inline-flex items-center pt-5`}
`
export const NotificationPanelDesktopIcon = styled.div`
  ${tw`xs:hidden sm:block sm:max-w-fit cursor-pointer absolute top-6 right-6 hover:opacity-80 transition-opacity duration-200`}
`
export const NotificationPanelHeader = styled.div`
  ${tw`xs:flex flex-wrap justify-start gap-x-2 gap-y-5 items-center xs:px-6 sm:px-8 py-6`}
`
export const NotificationPanelHeaderTitle = styled.div`
  ${tw`inline-flex items-center w-full`}
`
export const NotificationPanelHeaderLoading = styled.div`
  ${tw`font-primary font-bold leading-8 text-black-light text-2xl`}
`

export const NotificationPanelMobileWrapper = styled.div.attrs({
  className: '',
})``
export const NotificationPanelMessage = styled.div`
  ${tw`text-xs font-primary`}
`

export const ListItem = styled.div.attrs({
  className:
    'flex flex-col overflow-auto gap-4 pr-3 xs:pl-5 sm:pl-6 xs:h-[calc(100%-230px)] sm:h-[calc(100%-190px)] lg:h-[calc(100%-190px)] pb-6 pt-4',
})`
  & {
    .li-body {
      ${tw`flex items-start justify-between p-4 shadow-md rounded-xl xs:gap-6 sm:gap-8 xs:p-4 sm:p-4 bg-white hover:bg-app-grey-5 transition-colors duration-200 border border-app-grey-25`}
    }
    .unread-icon-body {
      ${tw`relative flex xs:gap-4 sm:gap-5 gap-2 items-start w-full`}
    }
    .unread-icon {
      ${tw`absolute top-0 right-0 w-2 h-2 rounded-full border border-white bg-app-red-65`}
    }
    .clear-notification {
      ${tw`font-primary font-bold leading-5 text-primary text-sm cursor-pointer hover:text-primary-brand xs:pt-2 sm:pt-0 transition-colors duration-200`}
    }
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(165, 165, 165, 0.5);
    border-radius: 20px;
  }
`
export const NotificationPanelCancelIcon = styled(CancelIcon)`
  ${tw`cursor-pointer hover:opacity-80 transition-opacity duration-200 w-5 h-5 fill-app-grey-70`}
`
export const NotificationPanelMobileIcon = styled.div`
  ${tw`xs:flex sm:hidden bg-gradient-to-r from-app-primary-5 to-white cursor-pointer p-5 shadow-md sticky top-0 z-10 border-b border-app-grey-25`}
`
export const AnnouncementButtonWrapper = styled.p<{ showAnnouncementButton?: boolean }>(
  ({ showAnnouncementButton }) => {
    return [
      tw`flex gap-3 w-full cursor-pointer mt-2`,
      showAnnouncementButton ? tw`visible` : tw`hidden`,
    ]
  }
)
export const HorizontalBreak = styled.hr`
  ${tw`border border-primary-outline mx-8`}
`

export const ButtonsWrapper = styled.div`
  ${tw`flex justify-end gap-2 pr-8 py-7`}
`

export const ModalBoxHeaderWrapper = styled.div`
  ${tw`flex pl-8 pt-8 items-center`}
`

export const TextWrapper = styled.p`
  ${tw`leading-5 pr-2 mb-6`}
`
export const ReceiverButtonWrapper = styled.p`
  ${tw`flex gap-[10px] border border-primary-brand rounded-2xl py-1 px-2`}
`

export const MessageWrapper = styled.span`
  ${tw`font-primary text-primary-text text-sm font-normal`}
`

export const MessageTextWrapper = styled.span`
  ${tw`text-sm font-semibold text-black`}
`

export const NotificationTextWrapper = styled.p`
  ${tw`break-words max-w-[300px]`}
`

export const PlayCommentWrapper = styled.div`
  ${tw`rounded-xl `}
`

export const PlayCommentHeader = styled.div`
  ${tw`flex justify-between items-center`}
`

export const PlayCommentTitle = styled.div`
  ${tw`font-semibold text-sm`}
`

export const PlayCommentSummary = styled.div`
  ${tw`text-app-grey-60 text-sm`}
`

export const PlayCommentFooter = styled.div`
  ${tw`flex justify-start`}
`

export const SmsParentWrapper = styled.div<CustomWrapperProps>`
  ${tw`flex flex-col`}
  ${(props) => (props.isWelcomeScreen ? tw`px-0` : tw`px-8`)}
`
export const ButtonParentWrapper = styled.div<CustomWrapperProps>`
  ${tw`w-full flex !py-8 cursor-auto`}
  ${(props) =>
    props.isWelcomeScreen ? tw`!gap-5 !px-0 xs:w-full sm:justify-between` : tw`!justify-end`}
`
export const ButtonWrapper = styled.div<CustomWrapperProps>`
  ${tw`gap-8 flex`}
  ${(props) => props.isWelcomeScreen && tw`flex-col-reverse xs:w-full sm:w-[200px]`}
`
export const SmsHeadingWrapper = styled.div<CustomWrapperProps>`
  ${tw`xs:pt-6 sm:pt-16 text-black-light flex gap-2 items-center !text-primary-text !pt-0 !leading-7 font-secondary`}
  ${(props) => (props.isWelcomeScreen ? tw`text-4xl !leading-9` : tw`text-2xl !leading-7`)}
`
