import React, { useMemo } from 'react'
import { compact, join } from 'lodash'
import { getRoleFromPath, timeDuration, viewMeetingDetail } from 'utils/helper'
import { IUSER_NOTIFICATION_TYPES, ROLES } from 'components/NotificationPanel/types'
import Button from 'components/Common/Button'
import {
  MessageTextWrapper,
  NotificationTextWrapper,
  PlayCommentWrapper,
  PlayCommentHeader,
  PlayCommentTitle,
  PlayCommentSummary,
  PlayCommentFooter,
} from 'components/NotificationPanel/style'
import { sanitize } from 'dompurify'
import { useHistory } from 'react-router-dom'
import { AppAvatar } from 'components/Common/AppAvatar/AppAvatar.component'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import NotificationMessageFactory from './notification-messages'
import { getTenantId } from 'utils/getTenantId'

const Message = ({ notification, handleClose }) => {
  const userName = useMemo(() => {
    return join(compact([notification?.firstName, notification?.lastName]), ' ')
  }, [notification])
  const user = useSelector((state: RootState) => state.user.user)
  const isAdvisor = user?.roles.includes(ROLES.BSO_ADVISOR)
  // Extract initials for avatar
  const tenantId = getTenantId()
  const userInitials = useMemo(() => {
    const firstName = notification?.firstName || ''
    const lastName = notification?.lastName || ''
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
  }, [notification])
  const history = useHistory()

  // common notification content
  const renderNotificationContent = () => {
    if (
      [
        IUSER_NOTIFICATION_TYPES.PLAY_COMMENT_ADDED,
        IUSER_NOTIFICATION_TYPES.PLAY_COMMENT_REPLY,
        IUSER_NOTIFICATION_TYPES.PLAY_COMMENT_MENTION,
        IUSER_NOTIFICATION_TYPES.PLAY_COMMENT_REPLY_MENTION,
        IUSER_NOTIFICATION_TYPES.PLAY_COMMENT_THREAD,
      ].includes(notification.notificationType)
    ) {
      return (
        <PlayCommentWrapper>
          <PlayCommentHeader>
            <span className="font-medium text-app-primary-75">Plays</span>
            <span className="text-app-grey-60 text-xs">{timeDuration(notification.createdAt)}</span>
          </PlayCommentHeader>
          <div className="mt-2 break-words">
            {NotificationMessageFactory.getMessage(
              notification.notificationType,
              userName,
              notification.businessName
            )}
          </div>
          <PlayCommentTitle className="mt-2 break-words">
            {notification.meta.taskName || 'Play Name'}
          </PlayCommentTitle>
          {notification.meta.goalSummary && (
            <PlayCommentSummary className="mt-1.5 break-words">
              {notification.meta.goalSummary}
            </PlayCommentSummary>
          )}
          <PlayCommentFooter className="mt-3">
            <Button
              variant="text"
              label="View comment"
              className="text-app-primary-75 px-4 py-1.5 rounded-full text-sm hover:bg-app-primary-10 transition-colors duration-200"
              onClick={() => {
                if (notification.meta.taskId) {
                  if (isAdvisor) {
                    history.push(
                      `/${getRoleFromPath()}/${tenantId}/clients/${notification.meta.businessId}/${
                        notification.meta.goalId || 'other-tasks'
                      }/task?selectedTaskId=${notification.meta.taskId}`
                    )
                  } else {
                    history.push(
                      `/${getRoleFromPath()}/${tenantId}/${
                        notification.meta.goalId || 'other-tasks'
                      }/task?selectedTaskId=${notification.meta.taskId}`
                    )
                  }
                  handleClose()
                }
              }}
            />
          </PlayCommentFooter>
        </PlayCommentWrapper>
      )
    }

    return (
      <div className="py-1">
        {notification.notificationType === IUSER_NOTIFICATION_TYPES.CALENDAR_MEETING && (
          <>
            <MessageTextWrapper>
              {userName} {notification.businessName ? `of ${notification.businessName} ` : ''}
            </MessageTextWrapper>
            has scheduled a meeting
          </>
        )}
        {notification.notificationType ===
          IUSER_NOTIFICATION_TYPES.CALENDAR_MEETING_RESCHEDULED && (
          <>
            <MessageTextWrapper>
              {userName} {notification.businessName ? `of ${notification.businessName} ` : ''}
            </MessageTextWrapper>
            rescheduled a meeting
          </>
        )}
        {notification.notificationType === IUSER_NOTIFICATION_TYPES.CALENDAR_MEETING_CANCELLED && (
          <>
            <MessageTextWrapper>
              {userName} {notification.businessName ? `of ${notification.businessName} ` : ''}
            </MessageTextWrapper>
            cancelled a meeting
          </>
        )}
        {notification.notificationType === IUSER_NOTIFICATION_TYPES.USER_DISABLED && (
          <>
            <MessageTextWrapper>
              The account for {`${userName} `}
              {notification.businessName ? `of ${notification.businessName} ` : ''}
            </MessageTextWrapper>
            has been disabled.
          </>
        )}
        {notification.notificationType === IUSER_NOTIFICATION_TYPES.CHAT && (
          <>
            <MessageTextWrapper>
              {userName} {notification.businessName ? `of ${notification.businessName} ` : ''}
            </MessageTextWrapper>
            sent a chat
          </>
        )}
        {notification.notificationType === IUSER_NOTIFICATION_TYPES.CLIENT_USER_PROFILE_UPDATE && (
          <>
            {notification?.meta?.notificationGeneratedBy === ROLES.BUSINESS_OWNER ? (
              <>
                <MessageTextWrapper>
                  {userName} {notification.businessName ? `of ${notification.businessName} ` : ''}
                </MessageTextWrapper>
                has updated their user profile details
              </>
            ) : (
              <>
                Personal profile details for
                <MessageTextWrapper>
                  {` ${userName}`}
                  {notification.businessName ? ` of ${notification.businessName} ` : ''}
                </MessageTextWrapper>
                have been updated.
              </>
            )}
          </>
        )}
        {notification.notificationType ===
          IUSER_NOTIFICATION_TYPES.CLIENT_BUSINESS_PROFILE_UPDATE && (
          <>
            {notification?.meta?.notificationGeneratedBy === ROLES.BUSINESS_OWNER ? (
              <>
                <MessageTextWrapper>
                  {userName} {notification.businessName ? ` of ${notification.businessName} ` : ''}
                </MessageTextWrapper>
                has updated their business profile details.
              </>
            ) : (
              <>
                Business profile details for
                <MessageTextWrapper>
                  {` ${userName}`}
                  {notification.businessName ? ` of ${notification.businessName} ` : ''}
                </MessageTextWrapper>
                have been updated.
              </>
            )}
          </>
        )}
        {notification.notificationType === IUSER_NOTIFICATION_TYPES.NEW_CLIENT_ADDED && (
          <>
            <MessageTextWrapper> {`${userName} `} </MessageTextWrapper>
            has been assigned to you.
          </>
        )}
        {notification.notificationType === IUSER_NOTIFICATION_TYPES.CLIENT_REMOVED && (
          <>
            You have been unassigned
            <MessageTextWrapper>{` ${userName}`} </MessageTextWrapper>
          </>
        )}
        {notification.notificationType === IUSER_NOTIFICATION_TYPES.MONTHLY_ATTESTATION_UPDATE && (
          <>
            <MessageTextWrapper> {userName} </MessageTextWrapper> {notification.meta.text}
          </>
        )}
        {notification.notificationType === IUSER_NOTIFICATION_TYPES.ANNOUNCEMENT && (
          <>
            <MessageTextWrapper> {userName} </MessageTextWrapper>
            <span>sent an announcement: </span>
            <MessageTextWrapper>
              <NotificationTextWrapper>
                <div dangerouslySetInnerHTML={{ __html: sanitize(notification.meta.text) }} />
              </NotificationTextWrapper>
              {notification.meta.fileUrl && (
                <a
                  href={notification.meta.fileUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="text-app-primary-75 hover:underline mt-2 inline-block"
                >
                  View Attachment
                </a>
              )}
            </MessageTextWrapper>
          </>
        )}
        {notification.notificationType === IUSER_NOTIFICATION_TYPES.MONTHLY_ATTESTATION_TIME && (
          <>
            <MessageTextWrapper>{userName} </MessageTextWrapper>
            {notification.meta.text}
          </>
        )}
        {notification.notificationType === IUSER_NOTIFICATION_TYPES.PLAN_START_DATE && (
          <>{notification.meta.text}</>
        )}
        {notification.notificationType === IUSER_NOTIFICATION_TYPES.SUGGESTED_GOAL_MESSAGE && (
          <>{notification.meta.text}</>
        )}
        {notification.notificationType === IUSER_NOTIFICATION_TYPES.ACCEPTED_GOAL_MESSAGE && (
          <>{notification.meta.text.split('@@@')[0]}</>
        )}
        {notification.notificationType === IUSER_NOTIFICATION_TYPES.DECLINED_GOAL_MESSAGE && (
          <>{notification.meta.text.split('@@@')[0]}</>
        )}
        {notification.notificationType === IUSER_NOTIFICATION_TYPES.INTERNAL_ANNOUNCEMENT && (
          <>
            <MessageTextWrapper>{userName} </MessageTextWrapper>
            <div dangerouslySetInnerHTML={{ __html: sanitize(notification.meta.html) }} />
          </>
        )}
        {notification.notificationType === IUSER_NOTIFICATION_TYPES.CALENDAR_MEETING_UPDATED && (
          <>
            <MessageTextWrapper>
              {userName} {notification.businessName ? `of ${notification.businessName} ` : ''}
            </MessageTextWrapper>
            edited a meeting
            <Button
              variant="text"
              label="View details"
              className="text-app-primary-75 px-3 py-1 rounded-full text-sm hover:bg-app-primary-10 transition-colors duration-200 mt-2"
              onClick={() => {
                viewMeetingDetail(notification, handleClose)
              }}
            />
          </>
        )}
      </div>
    )
  }

  return (
    <div className="flex items-start gap-4 w-full">
      {/* Avatar component */}
      <div className="flex-shrink-0 mt-4">
        <AppAvatar
          size="md"
          type="mixed"
          shape="Rounded"
          text={userInitials}
          src={notification?.profilePicture}
        />
      </div>

      <div className="flex flex-col flex-grow w-[80%]">
        {renderNotificationContent()}
        {notification.createdAt &&
          ![
            IUSER_NOTIFICATION_TYPES.PLAY_COMMENT_ADDED,
            IUSER_NOTIFICATION_TYPES.PLAY_COMMENT_REPLY,
            IUSER_NOTIFICATION_TYPES.PLAY_COMMENT_MENTION,
            IUSER_NOTIFICATION_TYPES.PLAY_COMMENT_REPLY_MENTION,
            IUSER_NOTIFICATION_TYPES.PLAY_COMMENT_THREAD,
          ].includes(notification.notificationType) && (
            <div className="text-xs text-app-grey-60 mt-2">
              {timeDuration(notification.createdAt)}
            </div>
          )}
      </div>
    </div>
  )
}

export default Message
