import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

import {
  UserFile,
  useUpdateUserTaskMutation,
  UserTask,
  UserTaskSource,
} from '__generated__/api-types-and-hooks'
import {
  TaskDetailsWrapper,
  DescriptionWrapper,
  DescriptionHeader,
  DescriptionTitle,
} from 'components/AppKanbanBoard/AppTask/AppTask.style'
import { AppButton } from 'components/Common/AppButton/AppButton.component'
import { AppTaskFields } from 'components/AppKanbanBoard/AppTask/AppTaskFields/AppTaskFields.component'

import { AppPlayTutorialIcon } from 'components/Common/AppSvgIcons/AppPlayTutorialIcon'
import { AppTaskDescriptionField } from 'components/AppKanbanBoard/AppTask/AppTaskDescriptionField/AppTaskDescriptionField.component'
import { AppTaskPopups } from './AppTaskPopups/AppTaskPopups.component'
import { useUserTask } from './AppTaskHook/AppTaskHook.hook'
import { TaskFormFields } from '../config'
import { AppGenerateTutorial } from './AppTaskFields/AppGenerateTutorial/AppGenerateTutorial.component'
import { AppTaskUserInputFields } from './AppTaskFields/AppTaskUserInputFields/AppTaskUserInputFields.component'
import { useParams } from 'react-router-dom'
import { AppFileUploader } from 'components/AppFileUploader/AppFileUploader.component'
import { AppCloudUploadIcon } from 'components/Common/AppSvgIcons/AppCloudUploadIcon'
import { AppTaskFileCard } from './AppTaskFileCard/AppTaskFileCard.component'
import { AppComment } from './AppComment/AppComment.component'
import { IAssigneeOptions } from 'appHooks/useGetBusinessProfile'

interface IAppTaskFormProps {
  task?: UserTask
  logo: string
  tenantId: string
  userId: string
  plan?: {
    name: string | null | undefined
    id: string | null | undefined
    planExpectedEndDate: string | null | undefined
  }
  source: UserTaskSource
  setShowTaskModal: React.Dispatch<React.SetStateAction<boolean>>
  setShowTaskSuccessModal?: React.Dispatch<React.SetStateAction<boolean>>
  setShowTaskErrorModal?: React.Dispatch<React.SetStateAction<boolean>>
  setCreatedUserTask?: React.Dispatch<React.SetStateAction<UserTask>>
  refetch: () => void
  userName?: string
  advisorsData: Array<{ label: any; value: any }>
  goalId?: string
  allTasks?: UserTask[]
  hoursSpentPerDay?: number
  noneEditableFields?: string[]
  userAvatar?: string
  userFirstName?: string
  userLastName?: string
  goalEndDate: string
  assigneeOptions?: IAssigneeOptions[]
  paramUserId?: string
}

export const AppTask: React.FC<IAppTaskFormProps> = ({
  task,
  logo,
  tenantId,
  userId,
  plan,
  source,
  userName,
  advisorsData,
  assigneeOptions,
  setShowTaskModal,
  setShowTaskSuccessModal,
  setShowTaskErrorModal,
  setCreatedUserTask,
  refetch,
  allTasks,
  goalId,
  hoursSpentPerDay = 8,
  noneEditableFields,
  userAvatar,
  userFirstName,
  userLastName,
  goalEndDate,
  paramUserId,
}) => {
  const {
    isViewMode,
    tutorialNotFound,
    generatedHowToDo,
    showWarningPopup,
    setShowWarningPopup,
    goalExpectedEndDate,
    setGoalExpectedEndDate,
    planStartDate,
    methods,
    clickedFields,
    isCreateTaskLoading,
    handleClick,
    updateTaskData,
    handleGoalEndDateUpdate,
    handleTaskSubmission,
    handlCreateTask,
    searchHowToDoTask,
    isSearchHowToDoTaskLoading,
    isStartDateBeforePlanStartDate,
  } = useUserTask({
    task,
    plan,
    hoursSpentPerDay,
    tenantId,
    userId,
    allTasks,
    goalId,
    advisorsData,
    source,
    setShowTaskModal,
    setShowTaskSuccessModal,
    setShowTaskErrorModal,
    setCreatedUserTask,
    refetch,
    goalEndDate,
  })
  const { clientId } = useParams()
  const [taskFiles, setTaskFiles] = useState<UserFile[]>(
    [...(task?.files ?? [])].sort(
      (a, b) => new Date(b.createdAt ?? '').getTime() - new Date(a.createdAt ?? '').getTime()
    )
  )
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = methods
  const handleUserInputFieldsSubmit = (formData: Record<string, any>) => {
    if (!task) return

    const updatedUserInputFields =
      task.userInputFields?.map((field) => ({
        ...field,
        value: formData[field.name],
      })) ?? []

    setValue('userInputFields', updatedUserInputFields)
    updateTaskData()
    refetch()
    setShowTaskModal && setShowTaskModal(false)
  }

  const {
    mutate: saveTaskFiles,
    isLoading: isFilesSaving,
    isSuccess: isFilesSaved,
  } = useUpdateUserTaskMutation()
  const handleSaveUploadedFiles = async (uploadedFiles: UserFile[] | null) => {
    if (!uploadedFiles) throw new Error('No files uploaded')

    try {
      // Save files to user task
      saveTaskFiles({
        input: {
          tenantId,
          userId: task?.userId ?? '',
          taskId: task?.taskId ?? '',
          files: [...(task?.files ?? []), ...uploadedFiles],
        },
      })
    } catch (error) {
      console.error('Upload failed:', error)
      setShowTaskErrorModal && setShowTaskErrorModal(true)
    }
  }

  useEffect(() => {
    if (isFilesSaved) {
      setShowTaskSuccessModal && setShowTaskSuccessModal(true)
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilesSaved])

  useEffect(() => {
    setTaskFiles(
      [...(task?.files ?? [])].sort(
        (a, b) => new Date(b.createdAt ?? '').getTime() - new Date(a.createdAt ?? '').getTime()
      )
    )
  }, [task])

  return (
    <TaskDetailsWrapper>
      <form onSubmit={handleSubmit(handleTaskSubmission)}>
        <AppTaskFields
          userId={userId}
          methods={methods}
          task={task}
          logo={logo}
          clickedFields={clickedFields}
          handleClick={handleClick}
          isViewMode={isViewMode}
          advisorsData={advisorsData}
          userName={userName}
          noneEditableFields={noneEditableFields}
          setValue={setValue}
          setGoalExpectedEndDate={setGoalExpectedEndDate}
          planStartDate={planStartDate}
          goalId={goalId}
          userFirstName={userFirstName}
          userLastName={userLastName}
          userAvatar={userAvatar}
          isStartDateBeforePlanStartDate={isStartDateBeforePlanStartDate}
          assigneeOptions={assigneeOptions}
          paramUserId={paramUserId}
        />
        <hr className="w-full my-10 border-background-hover" />

        {isViewMode && (
          <div className="space-y-10">
            <div className="inline-flex items-center gap-2 text-app-black-100 border border-app-grey-40 rounded-lg py-1.5 px-3 font-inter text-sm font-medium">
              <AppCloudUploadIcon />
              <span>Upload files</span>
            </div>
            <AppFileUploader
              onUploadCompleted={handleSaveUploadedFiles}
              isFilesSaving={isFilesSaving}
              allowedFormats={[]}
            />
            <div className="flex flex-col gap-4">
              {taskFiles.map((file) => (
                <AppTaskFileCard key={file.id} file={file} />
              ))}
            </div>
          </div>
        )}

        {isViewMode && <hr className="w-full my-10 border-background-hover" />}

        <DescriptionWrapper>
          <DescriptionHeader>
            <DescriptionTitle>Description</DescriptionTitle>
            {isViewMode && (
              <AppButton
                onClick={() => {
                  searchHowToDoTask(
                    { input: { taskName: task?.taskId!, userId: clientId ?? userId } },
                    {
                      onSuccess: (data) => {
                        setValue('howToLinks', data?.searchHowToDoTask.videoLinks)
                        setValue('howToDo', data?.searchHowToDoTask.text)
                        updateTaskData()
                        refetch()
                      },
                    }
                  )
                }}
                disabled={isSearchHowToDoTaskLoading}
                size="sm"
                LeftIcon={AppPlayTutorialIcon}
                label="Generate Tutorial"
                variant="primary"
              />
            )}
          </DescriptionHeader>

          <AppTaskDescriptionField
            methods={methods}
            clickedFields={clickedFields}
            handleClick={
              !noneEditableFields?.includes(TaskFormFields.instructionForTask)
                ? handleClick
                : () => handleClick(TaskFormFields.instructionForTask, false)
            }
          />
        </DescriptionWrapper>
        {task && (
          <AppGenerateTutorial
            task={task}
            control={control}
            planName={plan?.name || ''}
            isViewMode={isViewMode}
            tutorialNotFound={tutorialNotFound}
            generatedHowToDo={generatedHowToDo}
            isLoading={isSearchHowToDoTaskLoading}
          />
        )}

        {task?.userInputFields && task?.userInputFields.length > 0 && (
          <div className="mt-5">
            <DescriptionHeader>
              <DescriptionTitle>Complete Task</DescriptionTitle>
            </DescriptionHeader>
            <AppTaskUserInputFields
              task={task}
              isViewMode={isViewMode}
              onSubmit={handleUserInputFieldsSubmit}
              scrollToBottom={() => {
                window.scrollTo(0, document.body.scrollHeight)
              }}
            />
          </div>
        )}

        {!isViewMode && (
          <div className="flex justify-end mt-4">
            <AppButton
              label={'Create Play'}
              variant="secondary"
              size="md"
              type="submit"
              disabled={isCreateTaskLoading || !isEmpty(errors) || isStartDateBeforePlanStartDate}
            />
          </div>
        )}
      </form>
      <AppTaskPopups
        showWarningPopup={showWarningPopup}
        setGoalExpectedEndDate={setGoalExpectedEndDate}
        isViewMode={isViewMode}
        task={task}
        setShowWarningPopup={setShowWarningPopup}
        updateTaskData={updateTaskData}
        setValue={setValue}
        handlCreateTask={handlCreateTask}
        handleGoalEndDateUpdate={handleGoalEndDateUpdate}
        goalEndDate={goalExpectedEndDate}
      />

      {task && (
        <AppComment currentUserId={userId} refetchTask={refetch} taskId={task.taskId ?? ''} />
      )}
    </TaskDetailsWrapper>
  )
}
