import React, { FC, useEffect, useState } from 'react'
import { ConnectedProps } from 'react-redux'
import { isString } from 'lodash'

import history from 'utils/history'
import { getReturnToPath } from 'utils/helper'
import { AppToolsConnector } from 'components/AppTools/AppTools.connector'
import ToolsPanel from 'components/Tools/ToolsPanel/ToolsPanel.component'
import { useGetAllToolCategoriesQuery } from '__generated__/api-types-and-hooks'
import { useAuthentication } from 'utils/authentication'
import GradientCircleNotchIcon from 'components/Common/SvgIcons/GradientCircleNotchIcon'
import { useGetCategory } from 'components/AppTools/AppToolsHooks/useGetCategory.hooks'
import { AppToolCategoryPanel } from 'components/AppTools/AppToolCategoryPanel/AppToolCategoryPanel.component'
import { useGetAppToolsPathPrefix } from './AppToolsHooks/useGetAppToolsPathPrefix.hooks'

interface IAppToolsProps extends ConnectedProps<typeof AppToolsConnector> {
  readonly?: boolean
}
export const AppTools = AppToolsConnector<FC<IAppToolsProps>>(
  ({
    tenantId,
    toolsCategoryData,
    getToolCategoriesAction,
    oAuthUrl,
    resetOAuthUrlAction,
    readonly,
  }) => {
    useAuthentication(oAuthUrl, () => {}, resetOAuthUrlAction)

    const queryParam = getReturnToPath()
    let query = isString(queryParam.query) ? queryParam.query ?? '' : ''

    const [search, setSearch] = useState(query ?? '')
    const category = useGetCategory()

    const pathPrefix = useGetAppToolsPathPrefix()

    useEffect(() => {
      setSearch(query ?? '')
    }, [query])

    const { data, isLoading } = useGetAllToolCategoriesQuery({}, { staleTime: Infinity })

    useEffect(() => {
      if (data?.getAllToolCategories) {
        getToolCategoriesAction(data?.getAllToolCategories)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
      if (data?.getAllToolCategories && toolsCategoryData.length > 3) {
        history.push(
          `${pathPrefix}/${
            !category.value ? 'featured' : `${category?.value}${query ? `?query=${query}` : ''}`
          }`
        )
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, toolsCategoryData])

    useEffect(() => {
      if (window.location.href.includes('search') && !query) {
        history.push(`${pathPrefix}/featured`)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    return (
      <div className="w-full bg-white rounded-lg border border-primary-disabled mdl:mt-10">
        <div className={`grid grid-cols-12 ${isLoading ? 'h-screen' : ''}`}>
          {isLoading ? (
            <div className="col-span-12">
              <div className="flex justify-center items-center">
                <GradientCircleNotchIcon width="100" height="100" />
              </div>
            </div>
          ) : (
            <>
              <AppToolCategoryPanel setSearch={setSearch} />
              <ToolsPanel setSearch={setSearch} search={search} query={query} readonly={readonly} />
            </>
          )}
        </div>
      </div>
    )
  }
)
