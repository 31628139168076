import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getToolCategories } from 'store/selectors/clients'

export const useGetCategory = () => {
  const toolsCategoryData = useSelector(getToolCategories)
  const { toolSubTab } = useParams()
  let category = useMemo(
    () => toolsCategoryData.find((tool) => tool.value === toolSubTab) || { label: '', value: '' },
    [toolSubTab, toolsCategoryData]
  )
  if (toolSubTab === 'search') {
    category.value = 'search'
  }
  return category
}
