import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import { UserProfile } from '../../../__generated__/api-types-and-hooks'
import { NotificationSettingsWrapper } from './style'
import Button from 'components/Common/Button'
import { Controller, useForm } from 'react-hook-form'
import CheckBoxField from '../CheckBoxField'
import ErrorMessage from '../ErrorMessage'
import ValidationSchemaBuilder from 'utils/ValidationSchemaBuilder'
import { yupResolver } from '@hookform/resolvers/yup'
import { AppCountryPhoneInput } from '../AppFormFields/AppCountryPhoneInput/AppCountryPhoneInput.component'
import { isEmpty } from 'lodash'
import { formatMobileNumber } from 'appUtils'
import Label from '../Label'
export interface INotificationSettingsProps {
  user: UserProfile
  updateUserProfileAction: (payload: Partial<UserProfile>) => {
    type: 'UPDATE_USER_PROFILE_STARTED'
    payload: Partial<UserProfile>
  }
}

const schemaBuilder = new ValidationSchemaBuilder()
const phoneNumberSchema = schemaBuilder.mobileContactNumber().getSchema()

const NotificationSettings = ({ updateUserProfileAction, user }: INotificationSettingsProps) => {
  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(phoneNumberSchema),
    defaultValues: {
      mobileContactNumber: user?.mobileContactNumber || '',
    },
  })
  const {
    control,
    getValues,
    formState: { errors },
  } = methods

  const [showEditPhoneNumber, setShowEditPhoneNumber] = useState(true)
  const [isSmsEnabled, setIsSmsEnabled] = useState(user?.isSmsEnabled)
  const [subscribedWithoutOptIn, setSubscribedWithoutOptIn] = useState(false)

  const handleEditPhoneNumber = () => {
    const mobileContactNumber = getValues('mobileContactNumber')
    updateUserProfileAction({
      mobileContactNumber: mobileContactNumber,
    })
    setShowEditPhoneNumber(true)
  }

  const handleChangedSmsSubscriptionStatus = () => {
    // If currently enabled, getting here means you want to toggle it off
    if (user?.isSmsEnabled) {
      updateUserProfileAction({
        isSmsEnabled: false,
      })
      setIsSmsEnabled(false)
    } else {
      // If currently disabled, getting here means you want to toggle it on
      if (isSmsEnabled) {
        updateUserProfileAction({
          isSmsEnabled: true,
        })
        setIsSmsEnabled(true)
      } else {
        setSubscribedWithoutOptIn(true)
      }
    }
  }

  const history = useHistory()

  return (
    <div className="grid-flow-row auto-rows-max pt-6 w-full">
      <div className="xs:ml-0 md:ml-12 mb-12">
        <div className="flex items-center cursor-pointer" onClick={() => history.goBack()}>
          <div className="flex-initial">
            <ChevronLeftIcon className={`h-7 w-7 inline mt-1 mr-2 text-primary`} />
          </div>
          <div className="flex-initial pl-2">
            <p className="text-lg mt-1 text-primary font-primary">ACCOUNT SETTINGS</p>
          </div>
        </div>
      </div>

      <NotificationSettingsWrapper>
        <h1 className="text-2xl smd:text-3xl md:text-4xl font-light mb-6">Notification Settings</h1>
        <p className="font-semibold text-primary-text mb-6">
          Get priority notifications straight to your phone! Stay informed on meeting requests,
          chats, due dates, and GoTackle updates.
        </p>
        <div className="w-1/2">
          {!showEditPhoneNumber ? (
            <Controller
              control={control}
              name={'mobileContactNumber'}
              render={({ field }) => (
                <AppCountryPhoneInput
                  error={errors.mobileContactNumber?.message as string}
                  value={field.value}
                  label={'Mobile Phone Number'}
                  onChange={(formattedValue) => {
                    field.onChange(formattedValue)
                  }}
                  name="mobileContactNumber"
                  placeholder="ex: (380) 221-7275"
                  size="md"
                />
              )}
            />
          ) : (
            <div>
              <Label>{'Mobile Phone Number'}</Label>
              <div className="mt-2">{formatMobileNumber(user?.mobileContactNumber as string)}</div>
              <Button
                variant="text"
                label="Change Number"
                className="mt-2"
                onClick={() => {
                  setShowEditPhoneNumber(false)
                }}
              />
            </div>
          )}

          {!showEditPhoneNumber && (
            <Button
              variant="text"
              label="Save"
              className="mt-2"
              onClick={handleEditPhoneNumber}
              disabled={!isEmpty(errors.mobileContactNumber)}
            />
          )}
        </div>
        <div className="mt-6">
          {!user?.isSmsEnabled ? (
            <div>
              <div className="flex">
                <CheckBoxField
                  value={isSmsEnabled}
                  label={'I agree to receive SMS'}
                  id={'isSmsEnabled'}
                  className="!mr-0"
                  onChange={(e) => {
                    setIsSmsEnabled(e.target.checked)
                    setSubscribedWithoutOptIn(false)
                  }}
                />
              </div>
              <p className="mt-2 text-sm">
                Your number remains completely private. By subscribing, you agree to receive
                messages at the phone number provided. Reply STOP to cancel. Messaging rates may
                apply.
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="mt-4">
          <Button
            variant={user?.isSmsEnabled ? 'secondary' : 'primary'}
            label={user?.isSmsEnabled ? 'Unsubscribe' : 'Subscribe'}
            onClick={handleChangedSmsSubscriptionStatus}
          />
        </div>
        {!isSmsEnabled && subscribedWithoutOptIn ? (
          <ErrorMessage id={'isSmsOptedIn'}>{'Agreement is required to subscribe.'}</ErrorMessage>
        ) : (
          <></>
        )}
      </NotificationSettingsWrapper>
    </div>
  )
}

export default NotificationSettings
