import styled from 'styled-components'
import tw from 'twin.macro'

export const AppContainer = styled.div`
  ${tw`border border-app-grey-40 rounded-xl shadow-xl`}
`

export const AppSection = styled.div`
  ${tw`p-6`}
`

export const AppGoalHeading = styled.div`
  ${tw`flex items-center mb-2 justify-between`}
`

export const AppGoalText = styled.div`
  ${tw`font-inter font-normal text-sm text-app-grey-70 mb-4`}
`
