import React, { FC } from 'react'

import { Tool, ToolSupportLevel } from '__generated__/api-types-and-hooks'
import { onLaunchTool } from 'utils/helper'
import { ExtendedTools } from 'types'
import { IGetOAuthUrlAction } from 'types'
import { AppButton } from 'components/Common/AppButton/AppButton.component'

interface IAppToolsButtonProps {
  isConnected: boolean
  tool: ExtendedTools
  setActiveConnectingTool?: (param: Tool) => void
  getOAuthUrlAction: (payload: IGetOAuthUrlAction) => void
  isNewTools?: boolean
  targetOrigin?: string
}

export const AppToolsButton: FC<IAppToolsButtonProps> = ({
  isConnected,

  tool,
  setActiveConnectingTool,
  getOAuthUrlAction,
  isNewTools = false,
  targetOrigin,
}) => {
  const isToolConnectable = tool.supportLevel && tool.supportLevel !== ToolSupportLevel.Open
  const connectTools = () => {
    if (!tool.options?.connectionForm) {
      getOAuthUrlAction({
        calendar: tool.toolName ?? '',
        targetOrigin: targetOrigin ?? window.location.href,
        connectorName: tool.toolName ?? '',
        authType: tool.authType,
      })
    }
    setActiveConnectingTool && setActiveConnectingTool(tool)
  }

  return (
    <>
      <div className="flex items-center justify-between gap-2 mt-auto flex-row relative">
        {isConnected && isToolConnectable ? (
          <AppButton
            label={'Launch'}
            onClick={() => onLaunchTool(tool)}
            size="sm"
            variant={'secondary'}
          />
        ) : (
          <AppButton
            label={`${isToolConnectable ? 'Connect' : isNewTools ? 'Launch' : 'Open'} `}
            size="sm"
            variant={'secondary'}
            onClick={() => {
              isToolConnectable ? connectTools() : onLaunchTool(tool)
            }}
          />
        )}

        {tool.pricingPageUrl && (
          <div className="relative">
            <AppButton
              size="sm"
              variant={'clear'}
              label="Learn More"
              onClick={() => tool.pricingPageUrl && window.open(tool.pricingPageUrl, '_blank')}
            />
          </div>
        )}
      </div>
    </>
  )
}
