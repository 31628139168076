import { useEffect, useMemo, useState } from 'react'
import {
  useGetBusinessGoalQuery,
  // useGetBusinessProfileQuery,
  // useGetMyProfileQuery,
  UserTask,
  UserTaskItemStatus,
  useGetUserTasksQuery,
  UserTaskQuickFilter,
  // BusinessUserType,
  BusinessGoalDateFilter,
  useGetAdvisorAssignedTasksQuery,
  useGetUserTaskQuery,
} from '__generated__/api-types-and-hooks'
import { getTenantId } from 'utils/getTenantId'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
// import Avatar from 'appAssets/appImages/Avatar.png'
import { otherGoalIds, myOtherTasksGoal } from 'appConfig/data'
import { useFeatureFlag } from 'hooks/userFeatureFlag'
import { useGetBusinessProfile } from 'appHooks/useGetBusinessProfile'
import { getReturnToPath, isAdvisorRole } from 'utils/helper'
import { isString } from 'lodash'

interface IUseKanbanBoardDataProps {
  filter: Record<string, (string | number)[] | (string | number)>
  paramUserId?: string
}

export const useKanbanBoardData = ({ filter, paramUserId }: IUseKanbanBoardDataProps) => {
  const tenantId = getTenantId()
  const { goalId, clientId } = useParams()
  const isAllTasks = otherGoalIds.includes(goalId)
  const [tasks, setTasks] = useState<UserTask[]>([])
  const [allGoaltasks, setAllGoalTasks] = useState<UserTask[]>([])
  const [showTaskDetails, setShowTaskDetails] = useState(false)
  const userId = useSelector((state: RootState) => state.user.user.id)
  const isFieldViewForMiscPlaysDisabled = useFeatureFlag(
    paramUserId ?? clientId ?? userId,
    'release-field-view-misc-plays'
  )

  // Fetch business profile
  const {
    advisors,
    assigneeOptions,
    userName,
    userFirstName,
    userLastName,
    advisorsData,
    userAvatar,
    logo,
    hoursSpentPerDay,
  } = useGetBusinessProfile({ paramUserId })

  // Fetch business goal
  const {
    data: businessGoalData,
    isLoading,
    refetch,
  } = useGetBusinessGoalQuery(
    {
      input: { goalId, userId: paramUserId ?? clientId ?? userId, tenantId, filter },
    },
    { refetchOnWindowFocus: false, enabled: !!filter, cacheTime: 0 }
  )

  // Fetch business goal without filter. This is used for progress bar
  const { data: businessGoal, refetch: refetchBusinessGoal } = useGetBusinessGoalQuery(
    {
      input: {
        goalId,
        userId: paramUserId ?? clientId ?? userId,
        tenantId,
        filter: { dateRange: BusinessGoalDateFilter.EntireGamePlan },
      },
    },
    { refetchOnWindowFocus: false, cacheTime: 0, enabled: !isAllTasks }
  )

  //Fetch All tasks if goalID matches. This is used for progress bar
  const { data: otherBusinessGoalTasks, refetch: refetchOtherBusinessGoalTasks } =
    useGetUserTasksQuery(
      {
        input: {
          userId: paramUserId ?? clientId ?? userId,
          tenantId,
          filter: {
            quickFilter:
              goalId === 'all-plays'
                ? UserTaskQuickFilter.AllGoalsTasks
                : UserTaskQuickFilter.NoProjectAssigned,
          },
        },
      },
      {
        enabled: isAllTasks,
        refetchOnWindowFocus: false,
        cacheTime: 0,
      }
    )

  //Fetch All tasks if goalID matches
  const { data: otherGoalTasks, refetch: refetchTasks } = useGetUserTasksQuery(
    {
      input: {
        userId: paramUserId ?? clientId ?? userId,
        tenantId,
        filter: {
          ...filter,
          quickFilter:
            goalId === 'all-plays'
              ? isAdvisorRole() && !clientId
                ? UserTaskQuickFilter.NoProjectAssigned
                : UserTaskQuickFilter.AllGoalsTasks
              : UserTaskQuickFilter.NoProjectAssigned,
        },
      },
    },
    {
      enabled: isAllTasks,
    }
  )

  const { data: advisorTasks } = useGetAdvisorAssignedTasksQuery(
    {
      input: {
        userId,
        tenantId,
        filter: {
          ...filter,
          quickFilter:
            goalId === 'all-plays'
              ? UserTaskQuickFilter.AllGoalsTasks
              : UserTaskQuickFilter.NoProjectAssigned,
        },
      },
    },
    {
      enabled: isAllTasks && !clientId && isAdvisorRole(),
    }
  )

  const isViewFieldButton: boolean = useMemo(
    () => !(isAllTasks && isFieldViewForMiscPlaysDisabled),
    [isFieldViewForMiscPlaysDisabled, isAllTasks]
  )

  // Get plan from businessGoalData
  const plan = useMemo(() => businessGoalData?.getBusinessGoal?.plan, [businessGoalData])

  // Get goal  name form businessGoalData
  const goalName = useMemo(
    () =>
      isAllTasks
        ? myOtherTasksGoal.find((goal) => goal.goalId === goalId)?.name || ''
        : businessGoalData?.getBusinessGoal?.name ?? '',
    // eslint-disable-next-line
    [businessGoalData, myOtherTasksGoal, goalId]
  )

  // Get task plan from plan
  const taskPlan = useMemo(
    () =>
      plan
        ? {
            name: plan.name,
            id: plan.planId,
            planExpectedEndDate: plan.expectedEndDate,
            startDate: plan.startDate,
          }
        : undefined,
    [plan]
  )

  // Get milestones from the plan
  const taskMilestones = useMemo(
    () =>
      plan
        ? plan.milestones?.map((milestone) => ({ name: milestone.name, id: milestone.milestoneId }))
        : undefined,
    [plan]
  )

  // Function to get tasks by status
  const getTasksByStatus = (status: UserTaskItemStatus) =>
    tasks.filter((task: UserTask) => task.status === status)

  // useEffect to set tasks when plan is updated
  useEffect(() => {
    const allOtherTasks = otherGoalTasks?.getUserTasks || []
    const allAdvisorTasks = advisorTasks?.getAdvisorAssignedTasks || []
    const allTasks = [...allOtherTasks, ...allAdvisorTasks]
    filter && plan?.tasks && plan?.tasks?.length > 0
      ? setTasks(filterTasksAfterReOrder(plan.tasks))
      : !isAllTasks
      ? setTasks([])
      : setTasks(filterTasksAfterReOrder(allTasks))
    // eslint-disable-next-line
  }, [
    filter,
    plan,
    isAllTasks,
    otherGoalTasks?.getUserTasks,
    advisorTasks?.getAdvisorAssignedTasks,
  ])

  // set all tasks for progress bar
  useEffect(() => {
    const allOtherTasks = [
      ...(otherBusinessGoalTasks?.getUserTasks || []),
      ...(advisorTasks?.getAdvisorAssignedTasks || []),
    ]
    const allTasks = isAllTasks ? allOtherTasks : businessGoal?.getBusinessGoal?.plan?.tasks
    setAllGoalTasks((allTasks ?? []) as UserTask[])
  }, [businessGoal, isAllTasks, otherBusinessGoalTasks, advisorTasks?.getAdvisorAssignedTasks])

  const queryParam = getReturnToPath()
  const selectedTaskId =
    queryParam.selectedTaskId && isString(queryParam.selectedTaskId)
      ? queryParam.selectedTaskId ?? ''
      : ''
  const { data: selectedTaskResponse } = useGetUserTaskQuery(
    {
      input: {
        taskId: selectedTaskId,
        tenantId,
        userId: paramUserId ?? clientId ?? userId,
      },
    },
    { enabled: !!selectedTaskId }
  )
  useEffect(() => {
    if (selectedTaskId) {
      setShowTaskDetails(true)
    }
  }, [selectedTaskId])

  const handleRefetch = () => {
    if (isAllTasks) {
      refetchTasks()
      refetchOtherBusinessGoalTasks()
    } else {
      refetch()
      refetchBusinessGoal()
    }
  }

  return {
    userName,
    userFirstName,
    userLastName,
    advisorsData,
    logo,
    userAvatar,
    plan,
    taskMilestones,
    isLoading,
    userId: clientId ?? userId,
    tenantId,
    tasks,
    allGoaltasks,
    taskPlan,
    goalName,
    goalId,
    hoursSpentPerDay,
    setTasks,
    goalEndDate: businessGoalData?.getBusinessGoal?.expectedEndDate,
    getTasksByStatus,
    refetch: handleRefetch,
    boAdvisors: advisors,
    isViewFieldButton,
    assigneeOptions,
    showTaskDetails,
    setShowTaskDetails,
    selectedTask: selectedTaskResponse?.getUserTask,
  }
}

// Sort tasks by 'order' in ascending order
const filterTasksAfterReOrder = (tasks) => {
  const allTasks = tasks.sort((a: UserTask, b: UserTask) => {
    if (a.order && b.order) return a.order - b.order
    return 0
  })
  return allTasks
}
