import ModalBox from 'components/Common/ModalBox'
import React from 'react'
import SmsSubscriptionNotification from './SmsSubscriptionNotification'
import { useTenantId } from 'utils/useTenantId'
import { launchUsetifulIfNeeded } from 'utils/helper'

const SmsSubscriptionModal = ({
  user,
  isUpdateProfileLoading,
  showSubscriptionPrompt,
  setShowSubscriptionPromptAction,
  updateUserProfileAction,
}) => {
  const tenantId = useTenantId()
  return (
    <div>
      {showSubscriptionPrompt && (
        <ModalBox
          padding={'px-4 py-5'}
          width="sm:!w-[560px]"
          height="h-auto"
          bgColor="bg-[rgba(0,0,0,0.65)]"
          onClose={async () => {
            if (!user?.isSmsEnabled) {
              updateUserProfileAction({
                isSmsEnabled: false,
                tenantId,
              })
            }
            setShowSubscriptionPromptAction(false)
            await launchUsetifulIfNeeded()
          }}
        >
          <SmsSubscriptionNotification
            user={user}
            isLoading={isUpdateProfileLoading}
            onSubmit={updateUserProfileAction}
            isWelcomeScreen={false}
          />
        </ModalBox>
      )}
    </div>
  )
}
export default SmsSubscriptionModal
