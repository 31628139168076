import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tool, useBookmarkToolMutation } from '__generated__/api-types-and-hooks'
import { updateSearchToolConnectionStatus } from 'store/actions/clients'
import { ampli } from 'ampli'
import { RootState } from 'App'

export const useBookmark = (tool: Tool) => {
  const user = useSelector((state: RootState) => state.user.user)
  const businessName = useSelector((state: RootState) => state.user.businessProfile?.businessName)
  const dispatch = useDispatch()
  const {
    mutate: bookmarkTook,
    isLoading: isBookmarkingInProgress,
    data: bookmarkTookResponse,
    isError,
  } = useBookmarkToolMutation()

  const handleClick = (tool: Tool, callback?: (data?) => void) => {
    bookmarkTook(
      {
        input: {
          isBookmarked: !tool?.isBookmarked,
          toolId: tool.id!,
        },
      },
      {
        onSuccess: (data) => {
          if (callback) {
            callback(data)
          }
        },
      }
    )
  }

  useEffect(() => {
    if (bookmarkTookResponse?.bookmarkTool && !bookmarkTookResponse?.bookmarkTool?.error) {
      ampli.toolBookmarked({
        toolName: tool.toolName || '',
        userId: user.id,
        userName: `${user.firstName} ${user.lastName}`,
        businessName,
        isBookmarked: !tool?.isBookmarked,
      })
      dispatch(
        updateSearchToolConnectionStatus({
          toolId: tool.id!,
          isBookmarked: !tool?.isBookmarked,
          isConnected: !!tool.isConnected,
          isSuccess: !!bookmarkTookResponse?.bookmarkTool.success,
        })
      )
    }
    // eslint-disable-next-line
  }, [bookmarkTookResponse])

  return { isBookmarkingInProgress, handleClick, isError }
}
