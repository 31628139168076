import React, { useState } from 'react'

import appColors from 'appColors'
import { AppBadge } from 'components/Common/AppBadge/AppBadge.component'
import { AppChevronDownIcon } from 'components/Common/AppSvgIcons/AppChevronDownIcon'
import { AppChevronRightIcon } from 'components/Common/AppSvgIcons/AppChevronRightIcon'
import { Template } from 'components/AppOnboarding/AppChatbot/AppDynamicChatMessage/AppSmartGoalTemplate/types'
import {
  AppContainer,
  AppHeader,
  AppTitle,
  AppDescription,
  AppSection,
  AppCategoryContainer,
  AppCategoryHeader,
  AppCategoryName,
  AppCategoryText,
  AppViewContainer,
  AppViewBadge,
  AppChevronIcon,
  AppGoalsList,
  AppGoalItem,
  AppGoalWrapper,
  AppGoalHeading,
  AppGoalText,
  AppGoalChevron,
  AppNoTemplateText,
} from 'components/AppOnboarding/AppChatbot/AppDynamicChatMessage/AppSmartGoalTemplate/AppSmartGoalTemplate.style'
import { LoadingIndicator } from 'stream-chat-react'
import { categoryType } from 'appConfig/data'

interface AppSmartGoalTemplateProps {
  templates: Template[]
  onSelectGoal: (goal: any) => void
  smartGoalTitle: string
  smartGoalDesciption: string
  isLoading: boolean
  isDisable: boolean
}

export const AppSmartGoalTemplate: React.FC<AppSmartGoalTemplateProps> = ({
  templates,
  onSelectGoal,
  smartGoalTitle,
  smartGoalDesciption,
  isLoading,
  isDisable,
}) => {
  const [expandedSection, setExpandedSection] = useState<string | null>(null)

  const toggleSection = (section: string) => {
    setExpandedSection(expandedSection === section ? null : section)
  }

  return (
    <AppContainer>
      <AppHeader>
        <AppTitle>{smartGoalTitle}</AppTitle>
        <AppDescription>{smartGoalDesciption}</AppDescription>
      </AppHeader>
      <AppSection>
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <LoadingIndicator size={40} />
          </div>
        ) : (
          templates.map(({ category, isNew, goals }, index) => (
            <AppCategoryContainer key={index}>
              <AppCategoryHeader onClick={() => toggleSection(category)}>
                <AppCategoryName>
                  <AppCategoryText>{categoryType[category]}</AppCategoryText>
                  {isNew && (
                    <AppBadge label={'New!'} size={'xs'} color={'Primary'} type={'Outline'} />
                  )}
                </AppCategoryName>
                <AppViewContainer>
                  <AppViewBadge
                    size={'md'}
                    color={'White'}
                    label={expandedSection === category ? 'Hide' : 'View'}
                    type={'Clear'}
                  />
                  <AppChevronIcon rotated={expandedSection === category}>
                    <AppChevronDownIcon color={appColors['app-grey']['50']} />
                  </AppChevronIcon>
                </AppViewContainer>
              </AppCategoryHeader>
              {expandedSection === category && goals.length > 0 && (
                <AppGoalsList>
                  {goals.map((goal, idx) => (
                    <AppGoalItem
                      key={idx}
                      onClick={() => {
                        if (!isDisable) {
                          onSelectGoal(goal)
                        }
                      }}
                    >
                      <AppGoalWrapper>
                        {goal.smartHeading && (
                          <AppGoalHeading>
                            <AppBadge
                              label={`"${goal.smartHeading}"`}
                              size={'md'}
                              color={'Grey'}
                              type={'Outline'}
                            />
                          </AppGoalHeading>
                        )}
                        <AppGoalText>{goal.goalName}</AppGoalText>
                      </AppGoalWrapper>
                      <AppGoalChevron>
                        <AppChevronRightIcon color={appColors['app-grey']['50']} />
                      </AppGoalChevron>
                    </AppGoalItem>
                  ))}
                </AppGoalsList>
              )}
              {expandedSection === category && goals.length === 0 && (
                <AppNoTemplateText>No templates available.</AppNoTemplateText>
              )}
            </AppCategoryContainer>
          ))
        )}
      </AppSection>
    </AppContainer>
  )
}
