import { AppAvatar } from 'components/Common/AppAvatar/AppAvatar.component'
import { TeamMember } from 'hooks/useGetTeammember'
import React from 'react'
import { MentionsInput, Mention, MentionsInputStyle } from 'react-mentions'

interface MentionTextAreaProps {
  value: string
  placeholder: string
  onChange: (e: string, newPlainTextValue: string, mentions: any[]) => void
  teamMembers: TeamMember[]
  textAreaRef?: React.RefObject<HTMLTextAreaElement>
}

const defaultStyle: MentionsInputStyle = {
  control: {
    fontSize: 16,
    fontWeight: 'normal',
    border: 0,
  },
  input: {
    overflow: 'auto',
    height: 70,
    border: '1px solid transparent',
    outline: 0,
  },
  highlighter: {
    boxSizing: 'border-box',
    overflow: 'hidden',
    height: 70,
  },
  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
      borderRadius: '4px',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#F3F3FF',
        color: 'white',
      },
    },
  },
}

const mentionStyle = {
  backgroundColor: '#EAE9FE',
  borderRadius: '4px',
}

const MentionTextArea: React.FC<MentionTextAreaProps> = ({
  value,
  placeholder,
  onChange,
  teamMembers,
  textAreaRef,
}) => {
  return (
    <div className="w-full min-h-[75px] relative">
      <MentionsInput
        value={value}
        onChange={(e, newValue, newPlainTextValue, mentions) => {
          onChange(newValue, newPlainTextValue, mentions)
        }}
        placeholder={placeholder}
        style={defaultStyle}
        inputRef={textAreaRef}
        a11ySuggestionsListLabel={'Suggested mentions'}
      >
        <Mention
          trigger="@"
          data={teamMembers.map((member) => ({
            id: member.id,
            display: `${member.firstName} ${member.lastName}`.trim(),
          }))}
          renderSuggestion={(suggestion, search, highlightedDisplay) => {
            const initial =
              suggestion.display
                ?.split(' ')
                .map((name) => name[0])
                .join('.') + '.'
            return (
              <div className="user py-2 px-4 hover:bg-app-primary-5 hover:text-white cursor-pointer">
                <div className="flex items-center gap-2">
                  <AppAvatar
                    size="sm"
                    color="Primary"
                    type={'text'}
                    shape="Rounded"
                    src={''}
                    text={initial}
                  />
                  <div className="text-app-grey-65 font-inter text-base">{highlightedDisplay}</div>
                </div>
              </div>
            )
          }}
          markup='<mention id="__id__">__display__</mention>'
          style={mentionStyle}
          displayTransform={(id, display) => `@${display}`}
        />
      </MentionsInput>
    </div>
  )
}

export default MentionTextArea
