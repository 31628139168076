import { USER_ROLES } from 'config'

export const getUserName = (user) => {
  return user?.firstName ? `${user.firstName || ''} ${user.lastName || ''}` : user?.name
}

export const getChannelName = (members) => {
  try {
    if (!members || !members.length) {
      return 'No members'
    }

    if (members.length === 1) {
      return getUserName(members[0]?.user)
    }

    // For multiple members, format as "Group: Member1, Member2, ..."
    const memberNames = members.map((member) => getUserName(member?.user))

    // If there are more than 3 members, show first 2 and count of remaining
    if (memberNames.length > 3) {
      return `Group: ${memberNames.slice(0, 2).join(', ')} +${memberNames.length - 2} more`
    }

    return `Group: ${memberNames.join(', ')}`
  } catch (err) {
    console.error('Error in getChannelName:', err)
    return 'Chat'
  }
}

export const getBusinessName = (members) => {
  try {
    if (members && members.length === 1) {
      return members[0]?.user?.businessName
    } else {
      return ''
    }
  } catch (err) {
    return ''
  }
}

export const isChannelUserAdvisor = (members) => {
  try {
    if (
      members[1]?.user.glRoles.includes(USER_ROLES.BSO_ADMIN) ||
      members[1]?.user.glRoles.includes(USER_ROLES.BSO_ADVISOR)
    ) {
      return true
    }

    return false
  } catch (err) {
    console.log('***********************')
    return false
  }
}

export const getInitials = (input: { firstName: string; lastName: string }) => {
  try {
    const { firstName, lastName } = input
    const initials = `${firstName.charAt(0)}.${lastName.charAt(0)}.`.toUpperCase()
    return initials
  } catch (err) {
    console.log('***********************')
    return ''
  }
}

export const getInitialsFromName = (name: string) => {
  const initials = name.split(' ').map((n) => n[0]).join('.').toUpperCase() + '.'
  return initials
}
