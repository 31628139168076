import React, { useState, useEffect } from 'react'
import {
  ReactionAction,
  TaskComment,
  useAddTaskCommentMutation,
  useDeleteTaskCommentMutation,
  useGetTaskCommentsQuery,
  useUpdateTaskCommentMutation,
  useUpdateTaskReactionMutation,
} from '__generated__/api-types-and-hooks'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import { useGetTeammember } from 'hooks/useGetTeammember'
import { AppCommentConnector } from './AppComment.connector'
import { CommentContent } from './CommentContent.component'
import { AppCommentInput } from './AppCommentInput.component'
import { useComment } from './useComment'
import { LoadingIndicator } from 'stream-chat-react'
import { useParams } from 'react-router-dom'
interface FileAttachmentProps {
  fileName: string
  fileUrl: string | null
  onClick?: () => void
  textColor?: string
}

export const FileAttachmentComment: React.FC<FileAttachmentProps> = ({
  fileName,
  fileUrl,
  onClick,
  textColor = 'text-primary',
}) => {
  return (
    <span
      className={`inline-flex items-center px-2 py-1 mx-1 bg-gray-100 rounded cursor-pointer hover:bg-gray-200 ${textColor}`}
      onClick={() => {
        if (onClick) {
          onClick()
        } else if (fileUrl) {
          window.open(fileUrl, '_blank')
        }
      }}
    >
      {fileName}
    </span>
  )
}

export interface AppCommentProps {
  taskId: string
  currentUserId: string
  uploadFileAction: (options: { files: FileList; isMultiple: boolean; saveFile: boolean }) => void
  uploadedFileId: string
  uploadedFileName: string
  uploadedFileUrl: string | null
  uploadFileLoading: boolean
  refetchTask: () => void
}

interface LocalReaction {
  type: string
  id: string
  userId: string
}

const extractMentionedUserIds = (content: string): string[] => {
  const regex = /@\[(.*?)\]\(user:(.*?)\)/g
  const matches: string[] = []
  let match

  while ((match = regex.exec(content)) !== null) {
    if (match[2]) {
      matches.push(match[2])
    }
  }

  return matches
}

export const AppCommentBase: React.FC<AppCommentProps> = ({
  taskId,
  uploadFileAction,
  uploadedFileId,
  uploadedFileName,
  uploadedFileUrl,
  uploadFileLoading,
  refetchTask,
}) => {
  const [replyToId, setReplyToId] = useState<string | null>(null)
  const [localComments, setLocalComments] = useState<Record<string, LocalReaction[]> | null>(null)
  const [expandedComments, setExpandedComments] = useState<Set<string>>(new Set())
  const [editingCommentId, setEditingCommentId] = useState<string | null>(null)

  const user = useSelector((state: RootState) => state.user.user)
  const teamMembers = useGetTeammember()
  const { clientId } = useParams()

  const { mutate: addTaskCommentMutate, isLoading: isAddingComment } = useAddTaskCommentMutation()
  const {
    data: commentsResult,
    refetch,
    isLoading: isLoadingComments,
  } = useGetTaskCommentsQuery({ input: { taskId } }, { refetchOnWindowFocus: false })
  const { mutate: updateTaskReactionMutate, isLoading: isUpdatingReaction } =
    useUpdateTaskReactionMutation()
  const { mutate: deleteTaskCommentMutate, isLoading: isDeletingComment } =
    useDeleteTaskCommentMutation()
  const { mutate: updateTaskCommentMutate, isLoading: isUpdatingComment } =
    useUpdateTaskCommentMutation()

  const comments = commentsResult?.getTaskComments ?? []

  // Main comment hook
  const {
    comment: newComment,
    setComment: setNewComment,
    attachments,
    handleFileUpload,
    handleFileUploadSuccess,
    handleSubmit: handleMainSubmit,
  } = useComment({
    onSubmit: (content, fileIds) => {
      const mentionedUserIds = extractMentionedUserIds(content)
      addTaskCommentMutate(
        {
          input: { taskId, content, fileIds, tags: mentionedUserIds, userId: clientId ?? user.id },
        },
        {
          onSuccess: () => {
            refetchTask()
            refetch()
          },
        }
      )
    },
    uploadFileAction,
    uploadedFileId,
    uploadedFileName,
    uploadedFileUrl,
    uploadFileLoading,
  })

  // Reply comment hook
  const {
    comment: replyText,
    setComment: setReplyText,
    attachments: replyAttachments,
    handleFileUpload: handleReplyFileUpload,
    handleFileUploadSuccess: handleReplyFileUploadSuccess,
    handleSubmit: handleReplySubmit,
  } = useComment({
    onSubmit: (content) => {
      if (!replyToId) return
      const mentionedUserIds = extractMentionedUserIds(content)
      addTaskCommentMutate(
        {
          input: {
            taskId,
            content,
            parentId: replyToId,
            tags: mentionedUserIds,
            userId: clientId ?? user.id,
          },
        },
        {
          onSuccess: () => {
            setReplyToId(null)
            refetch()
          },
        }
      )
    },
    uploadFileAction,
    uploadedFileId,
    uploadedFileName,
    uploadedFileUrl,
    uploadFileLoading,
  })

  useEffect(() => {
    if (commentsResult?.getTaskComments) {
      const newLocalComments: Record<string, LocalReaction[]> = {}
      commentsResult.getTaskComments.forEach((comment) => {
        if (comment.reactions) {
          newLocalComments[comment.id] = comment.reactions.map((reaction) => ({
            type: reaction.type,
            id: reaction.id,
            userId: reaction.userId,
          }))
        }
        if (comment.replies) {
          comment.replies.forEach((reply) => {
            if (reply.reactions) {
              newLocalComments[reply.id] = reply.reactions.map((reaction) => ({
                type: reaction.type,
                id: reaction.id,
                userId: reaction.userId,
              }))
            }
          })
        }
      })
      setLocalComments(newLocalComments)
    }
  }, [commentsResult?.getTaskComments])

  useEffect(() => {
    handleFileUploadSuccess()
  }, [handleFileUploadSuccess, uploadFileLoading, uploadedFileId])

  useEffect(() => {
    handleReplyFileUploadSuccess()
  }, [handleReplyFileUploadSuccess, uploadFileLoading, uploadedFileId])

  const handleReaction = (commentId: string, emoji: string) => {
    if (isUpdatingReaction) return

    const reactions = getReactionsForComment({ id: commentId } as TaskComment)
    const hasUserReacted = reactions.some((r) => r.type === emoji && r.userId === user.id)

    setLocalComments((prev) => {
      if (!prev) return prev
      const currentReactions = prev[commentId] || []
      const existingReactionIndex = currentReactions.findIndex(
        (r) => r.type === emoji && r.userId === user.id
      )

      if (existingReactionIndex > -1) {
        return {
          ...prev,
          [commentId]: currentReactions.filter((_, index) => index !== existingReactionIndex),
        }
      } else {
        return {
          ...prev,
          [commentId]: [...currentReactions, { type: emoji, id: `${Date.now()}`, userId: user.id }],
        }
      }
    })

    updateTaskReactionMutate({
      input: {
        commentId,
        taskId,
        reactionType: emoji,
        action: hasUserReacted ? ReactionAction.Remove : ReactionAction.Add,
      },
    })
  }
  const getReactionsForComment = (comment: TaskComment): LocalReaction[] => {
    const localReactionsForComment = localComments?.[comment.id] || []
    return localReactionsForComment
  }

  const toggleReplies = (commentId: string) => {
    setExpandedComments((prev) => {
      const newSet = new Set(prev)
      if (newSet.has(commentId)) {
        newSet.delete(commentId)
      } else {
        newSet.add(commentId)
      }
      return newSet
    })
  }

  const handleEditComment = (commentId: string, content: string) => {
    if (isUpdatingComment) return

    let parsedContent
    try {
      parsedContent = JSON.parse(content)
    } catch (e) {
      parsedContent = { text: content, attachments: [] }
    }

    updateTaskCommentMutate(
      {
        input: {
          taskId,
          commentId,
          content: JSON.stringify({
            text: parsedContent.text,
            attachments: parsedContent.attachments || [],
          }),
        },
      },
      {
        onSuccess: () => {
          setEditingCommentId(null)
          refetch()
        },
      }
    )
  }

  const handleDeleteComment = (commentId: string) => {
    if (isDeletingComment) return
    deleteTaskCommentMutate({ input: { taskId, commentId } }, { onSuccess: () => refetch() })
  }
  if (isLoadingComments) {
    return (
      <div className="flex justify-center items-center">
        <LoadingIndicator size={40} />
      </div>
    )
  }
  return (
    <div className="mt-6 bg-gray-50 p-4">
      <div className="text-lg font-medium font-inter mb-4">Activity</div>
      <div className="space-y-6">
        {comments.map((comment) => (
          <div key={comment.id} className="space-y-6">
            <CommentContent
              uploadFileLoading={uploadFileLoading}
              comment={comment as TaskComment}
              user={user}
              replyToId={replyToId}
              expandedComments={expandedComments}
              handleReaction={handleReaction}
              setReplyToId={setReplyToId}
              toggleReplies={toggleReplies}
              getReactionsForComment={getReactionsForComment}
              replyText={replyText}
              setReplyText={setReplyText}
              isAddingComment={isAddingComment}
              teamMembers={teamMembers}
              replyAttachments={replyAttachments}
              handleReplyFileUpload={handleReplyFileUpload}
              onEditComment={handleEditComment}
              isEditingComment={isUpdatingComment && editingCommentId === comment.id}
              onDeleteComment={handleDeleteComment}
              isDeletingComment={isDeletingComment}
              handleSubmitReply={handleReplySubmit}
            />
          </div>
        ))}
      </div>

      <div className="mt-4">
        <AppCommentInput
          value={newComment}
          onChange={setNewComment}
          onSubmit={handleMainSubmit}
          isLoading={isAddingComment}
          avatarText={(user?.firstName?.[0] ?? '') + (user?.lastName?.[0] ?? '')}
          attachments={attachments}
          teamMembers={teamMembers}
          uploadFileLoading={uploadFileLoading}
          onFileUpload={handleFileUpload}
        />
      </div>
    </div>
  )
}

export const AppComment = AppCommentConnector(AppCommentBase)
