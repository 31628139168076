import { IUSER_NOTIFICATION_TYPES } from './types'

interface NotificationMessage {
  getMessage(userName: string, businessName?: string): string
}

class PlayCommentAddedMessage implements NotificationMessage {
  getMessage(userName: string, businessName?: string): string {
    return `${userName}${businessName ? ` of ${businessName}` : ''} has added a comment to one of your assigned plays`
  }
}

class PlayCommentReplyMessage implements NotificationMessage {
  getMessage(userName: string, businessName?: string): string {
    return `${userName}${businessName ? ` of ${businessName}` : ''} has replied to your comment`
  }
}

class PlayCommentMentionMessage implements NotificationMessage {
  getMessage(userName: string, businessName?: string): string {
    return `${userName}${businessName ? ` of ${businessName}` : ''} has tagged you in a comment`
  }
}

class PlayCommentReplyMentionMessage implements NotificationMessage {
  getMessage(userName: string, businessName?: string): string {
    return `${userName}${businessName ? ` of ${businessName}` : ''} has replied to a comment you are tagged in`
  }
}

class PlayCommentThreadMessage implements NotificationMessage {
  getMessage(userName: string, businessName?: string): string {
    return `${userName}${businessName ? ` of ${businessName}` : ''} replied to a thread you are tagged in`
  }
}

class NotificationMessageFactory {
  private static messageMap: Map<IUSER_NOTIFICATION_TYPES, NotificationMessage> = new Map([
    [IUSER_NOTIFICATION_TYPES.PLAY_COMMENT_ADDED, new PlayCommentAddedMessage()],
    [IUSER_NOTIFICATION_TYPES.PLAY_COMMENT_REPLY, new PlayCommentReplyMessage()],
    [IUSER_NOTIFICATION_TYPES.PLAY_COMMENT_MENTION, new PlayCommentMentionMessage()],
    [IUSER_NOTIFICATION_TYPES.PLAY_COMMENT_REPLY_MENTION, new PlayCommentReplyMentionMessage()],
    [IUSER_NOTIFICATION_TYPES.PLAY_COMMENT_THREAD, new PlayCommentThreadMessage()],
  ])

  static getMessage(notificationType: IUSER_NOTIFICATION_TYPES, userName: string, businessName?: string): string {
    const messageHandler = this.messageMap.get(notificationType)
    if (!messageHandler) {
      return `${userName}${businessName ? ` of ${businessName}` : ''} has added a comment to one of your assigned plays`
    }
    return messageHandler.getMessage(userName, businessName)
  }
}

export default NotificationMessageFactory 