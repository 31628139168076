import React, { FC, useEffect, useMemo, useState } from 'react'
import {
  AppCardWrapper,
  AppCardFooter,
  FooterContentWrapper,
  TaskTextWrapper,
  MessageIcon,
  TaskInstruction,
  TaskName,
  CardHeader,
  BadgesContainer,
} from 'components/AppKanbanBoard/AppCard/AppCard.style'
import { AppBadge } from 'components/Common/AppBadge/AppBadge.component'
import { AppAvatar } from 'components/Common/AppAvatar/AppAvatar.component'
import { getColorForGoalCategory, getColorForGoalPriority } from 'appUtils'
import appMessage from 'appAssets/appImages/appMessage.png'
import { categoryType, taskPriorityStatus } from 'appConfig/data'
import UnarchiveIcon from 'assets/svgs/unarchive.svg'
import { AppBinIcon } from 'components/Common/AppSvgIcons/AppBinIcon'
import {
  UpdateUserTaskInput,
  UserTask,
  UserTaskItemStatus,
  UserTaskSuggestedItemStatus,
  useUpdateUserTaskMutation,
} from '__generated__/api-types-and-hooks'
import { AppFileIconDouble } from 'components/Common/AppSvgIcons/AppFileIconDouble'
import { AppSuggestedByBox } from 'components/Common/AppSuggestedByBox/AppSuggestedByBox.component'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import CircularLoading from 'components/Common/CircularLoading'
import { IAssigneeOptions } from 'appHooks/useGetBusinessProfile'

interface IAppTaskCard {
  task: UserTask
  userId: string
  userAvatar: string
  userFirstName: string
  userLastName: string
  onTrashClick?: () => void
  refetch?: () => void
  isArchiveView?: boolean
  onUnarchive?: (taskId: string) => void
  advisorsData?: Array<{ label: any; value: any }>
  assigneeOptions?: IAssigneeOptions[]
}

export const AppTaskCard: FC<IAppTaskCard> = ({
  task,
  userId,
  userAvatar,
  userFirstName,
  userLastName,
  onTrashClick,
  refetch,
  isArchiveView,
  onUnarchive,
  advisorsData,
  assigneeOptions,
}) => {
  const currentUserId = useSelector((state: RootState) => state.user.user.id)
  const [isUnarchiving, setIsUnarchiving] = useState<boolean>(false)
  const isSuggestedTask = useMemo(
    () => task?.createdBy !== 'GoTackle' && task?.createdById !== task?.assigneeId,
    [task]
  )
  const user = useSelector((state: RootState) => state.user.user)
  const isAdvisor = user?.roles.includes('BSO_ADVISOR')
  const advisor = advisorsData?.find((advisor) => advisor.value === user?.id)
  const shownUserName = isAdvisor ? advisor?.label : `${userFirstName} ${userLastName}`
  const assignee =
    assigneeOptions?.find((assignee) => assignee.value === task?.assigneeId)?.label || shownUserName
  const assigneeInitials =
    assignee
      ?.split(' ')
      .map((name) => name[0])
      .join('.') + '.'
  const {
    mutate: updateUserTask,
    isLoading: isTaskUpdating,
    isSuccess: isTaskUpdateSuccess,
  } = useUpdateUserTaskMutation()

  const handleUpdateSuggestedTask = (suggestedStatus: UserTaskSuggestedItemStatus) => {
    const input: UpdateUserTaskInput = {
      taskId: task.taskId!,
      tenantId: task.tenantId!,
      userId: task.createdById ?? task.userId,
      suggestedStatus,
      status:
        suggestedStatus === UserTaskSuggestedItemStatus.Rejected
          ? UserTaskItemStatus.Archived
          : UserTaskItemStatus.Todo,
    }
    updateUserTask({ input })
  }

  const handleUnarchive = (taskId: string) => {
    setIsUnarchiving(true)
    onUnarchive && onUnarchive(taskId)
  }

  useEffect(() => {
    if (isTaskUpdateSuccess) {
      setIsUnarchiving(false)
      refetch && refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTaskUpdateSuccess])

  return (
    <AppCardWrapper>
      {isSuggestedTask && (
        <div className="w-full">
          <AppSuggestedByBox
            onAccept={() => handleUpdateSuggestedTask(UserTaskSuggestedItemStatus.Accepted)}
            onReject={() => handleUpdateSuggestedTask(UserTaskSuggestedItemStatus.Rejected)}
            status={task.suggestedStatus ?? UserTaskSuggestedItemStatus.Pending}
            name={{
              firstName: task.createdBy?.split(' ')[0] ?? '',
              lastName: task.createdBy?.split(' ')[1] ?? '',
            }}
            isDisabled={task.assigneeId !== currentUserId || isTaskUpdating}
          />
        </div>
      )}
      <CardHeader>
        <TaskTextWrapper className={`${task.files && task.files.length > 0 ? 'w-[70%]' : ''}`}>
          <TaskName>{task.name}</TaskName>
          <TaskInstruction>{task.instructionForTask}</TaskInstruction>
        </TaskTextWrapper>
        <div className={`flex items-center gap-1 justify-end`}>
          {!isArchiveView && (
            <div>
              <MessageIcon src={appMessage} alt="messagebox" />
            </div>
          )}
          {task.files && task.files.length > 0 && !isArchiveView && (
            <div>
              <AppFileIconDouble />
            </div>
          )}
          {onTrashClick && !isArchiveView && (
            <div
              onClick={(event) => {
                // event.stopPropagation()
                onTrashClick()
              }}
            >
              <AppBinIcon />
            </div>
          )}
          {isArchiveView && onUnarchive && (
            <div
              onClick={(event) => {
                event.stopPropagation()
                handleUnarchive(task.taskId!)
              }}
              className="cursor-pointer w-6 h-6 flex items-center justify-center"
            >
              {isUnarchiving ? (
                <CircularLoading className="w-5 h-5 app-bg-primary" />
              ) : (
                <img src={UnarchiveIcon} alt="unarchive" />
              )}
            </div>
          )}
        </div>
      </CardHeader>

      <AppCardFooter>
        <FooterContentWrapper>
          <BadgesContainer>
            {task.category && (
              <AppBadge
                size="xs"
                type="Filled"
                color={getColorForGoalCategory(task.category)}
                label={categoryType[task.category]}
              />
            )}
            {task.taskPriority && (
              <AppBadge
                size="xs"
                type="Filled"
                color={getColorForGoalPriority(task.taskPriority)}
                label={taskPriorityStatus[task.taskPriority]}
              />
            )}
            {task.isOverDue && <AppBadge size="xs" type="Filled" color={'Red'} label={'Overdue'} />}
          </BadgesContainer>
          <AppAvatar size="sm" type="mixed" shape="Rounded" text={assigneeInitials} />
        </FooterContentWrapper>
      </AppCardFooter>
    </AppCardWrapper>
  )
}
