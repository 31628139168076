import {
  AdditionalHelpBoxFlexWrapper,
  AdditionalHelpBoxGradientWrapper,
} from 'components/MonthlyAttestation/style'
import React, { ElementType, FC } from 'react'

interface IAppHelpCardProps {
  Icon: ElementType
  label: string
  handleOnClick: () => void
}

export const AppHelpCard: FC<IAppHelpCardProps> = ({ Icon, label, handleOnClick }) => {
  return (
    <AdditionalHelpBoxGradientWrapper
      className="max-w-[236px] cursor-pointer"
      onClick={handleOnClick}
    >
      <AdditionalHelpBoxFlexWrapper>
        <div className="flex gap-3">
          <Icon />
          <span>{label}</span>
        </div>
      </AdditionalHelpBoxFlexWrapper>
    </AdditionalHelpBoxGradientWrapper>
  )
}
