import React from 'react'

export const AppFileMediaFormatIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="12" fill="url(#paint0_linear_10264_22646)" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.75 18.7105C17.75 17.522 19.0242 16.7687 20.0655 17.3414L29.6827 22.6308C30.762 23.2245 30.762 24.7754 29.6826 25.369L20.0655 30.6584C19.0242 31.2312 17.75 30.4778 17.75 29.2893V18.7105Z"
        fill="#7600ED"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10264_22646"
          x1="24"
          y1="0"
          x2="24"
          y2="48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F5F5F5" />
          <stop offset="1" stop-color="#ECECEC" />
        </linearGradient>
      </defs>
    </svg>
  )
}
