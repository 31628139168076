import React, { useRef, useState } from 'react'
import { EmojiHappyIcon } from '@heroicons/react/outline'
import { AppPaperClipIcon } from 'components/Common/AppSvgIcons/AppPaperClipIcon'
import { AppButton } from 'components/Common/AppButton/AppButton.component'
import { AppAvatar } from 'components/Common/AppAvatar/AppAvatar.component'
import { AppModalBox } from 'components/Common/AppModalBox/AppModalBox.component'
import { AppFileUploadField } from 'components/Common/AppFileUploadField/AppFileUploadField.component'
import { AppSendMessageIcon } from 'components/Common/AppSvgIcons/AppSendMessageIcon'
import CircleNotchIcon from 'components/Common/SvgIcons/CircleNotchIcon'
import MentionTextArea from './MentionTextArea.component'
import { FileAttachmentComment } from './AppComment.component'
import { AppEmojiPicker } from '../../../AppShared/AppEmojiPicker/AppEmojiPicker.component'
import { TeamMember } from 'hooks/useGetTeammember'

interface FileAttachment {
  id: string
  fileName: string
  fileUrl: string | null
}

interface AppCommentInputProps {
  value: string
  onChange: (value: string) => void
  onSubmit: (e: React.FormEvent) => void
  isLoading: boolean
  showAvatar?: boolean
  avatarText?: string
  attachments: FileAttachment[]
  onFileUpload: (file: File) => void
  teamMembers: TeamMember[]
  placeholder?: string
  uploadFileLoading: boolean
  isEditing?: boolean
}

export const AppCommentInput: React.FC<AppCommentInputProps> = ({
  value,
  onChange,
  onSubmit,
  isLoading,
  showAvatar = true,
  avatarText = '',
  attachments,
  onFileUpload,
  teamMembers,
  placeholder = 'Your comment...',
  uploadFileLoading,
  isEditing = true,
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [uploadedFile, setUploadedFile] = useState<File>()
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const emojiPickerRef = useRef<HTMLDivElement>(null)

  // Add click outside handler for emoji picker
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target as Node)) {
        setShowEmojiPicker(false)
      }
    }

    if (showEmojiPicker) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showEmojiPicker])

  const handleTextChange = (newValue: string, newPlainTextValue: string, mentions: any[]) => {
    onChange(newValue)
  }

  const onEmojiClick = (emojiData: any) => {
    // Insert emoji at cursor position or at the end if no cursor
    const textarea = textAreaRef.current
    if (textarea) {
      const start = textarea.selectionStart
      const mentionRegex = /<mention id="[^"]*">[^<]*<\/mention>/g
      const matches = value.match(mentionRegex)
      const matchedBetween = matches?.filter((match, index) => {
        const startIndex = value.indexOf(match)

        const buffer = index === 0 ? 0 : 19
        return startIndex + buffer < start
      })
      const totalLength = matchedBetween?.reduce((acc, match) => acc + match.length, 0) ?? 0
      let totalLengthWithDifferent = totalLength
      if (matchedBetween?.length && matchedBetween?.length >= 1) {
        totalLengthWithDifferent -= matchedBetween?.length * 10
      }
      const newValue =
        value.slice(0, start + totalLengthWithDifferent) +
        emojiData.emoji +
        value.slice(start + totalLengthWithDifferent)
      onChange(newValue)

      // Reset cursor position after emoji insertion
      setTimeout(() => {
        textarea.focus()
        const newPosition = start + emojiData.emoji.length
        textarea.setSelectionRange(newPosition, newPosition)
      }, 0)
    } else {
      // If no cursor position, append to end
      onChange(value + emojiData.emoji)
    }
    setShowEmojiPicker(false)
  }

  return (
    <form onSubmit={onSubmit} className="flex items-start gap-3">
      {showAvatar && (
        <AppAvatar size="sm" color="Primary" type="text" shape="Rounded" text={avatarText} />
      )}
      <div
        className={`flex flex-col w-full rounded-lg px-4 py-3 border border-primary-appDarkBorder relative ${
          isEditing ? 'border-app-primary-5 bg-white' : ''
        }`}
      >
        <div className="w-full min-h-[75px] font-inter text-base text-black-appDark leading-5 bg-transparent">
          <MentionTextArea
            value={value}
            placeholder={placeholder}
            onChange={handleTextChange}
            teamMembers={teamMembers}
            textAreaRef={textAreaRef}
          />
          {attachments.length > 0 && (
            <div className="mt-2 flex flex-wrap gap-2">
              {attachments.map((attachment) => (
                <FileAttachmentComment
                  key={attachment.id}
                  fileName={attachment.fileName}
                  fileUrl={attachment.fileUrl}
                />
              ))}
            </div>
          )}
        </div>
        <div className="flex justify-between relative w-full">
          <div className="flex gap-2 items-center">
            <AppButton
              size="sm"
              variant="clear"
              onClick={() => setShowUploadModal(true)}
              LeftIcon={AppPaperClipIcon}
            />
            <div className="relative">
              <AppButton
                size="sm"
                variant="clear"
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                LeftIcon={() => <EmojiHappyIcon className="w-6 h-6" />}
              />
              {showEmojiPicker && (
                <div className="absolute bottom-full left-0 z-10" ref={emojiPickerRef}>
                  <AppEmojiPicker
                    onEmojiClick={onEmojiClick}
                    isOpen={true}
                    onToggle={() => setShowEmojiPicker(false)}
                    showEmojiIcon={false}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="right-2">
            {isLoading || uploadFileLoading ? (
              <CircleNotchIcon className={'fill-primary h-[30px]'} />
            ) : (
              <AppButton
                variant="primary"
                size="sm"
                type="submit"
                RightIcon={AppSendMessageIcon}
                disabled={!value.trim() && attachments.length === 0}
              />
            )}
          </div>
        </div>
      </div>

      {showUploadModal && (
        <AppModalBox title={'Upload File'} onClose={() => setShowUploadModal(false)}>
          <div className="m-10">
            <AppFileUploadField
              name="imageData"
              className="flex-1"
              labelFontSize="base"
              onChange={(e) => {
                if (e[0]) {
                  setUploadedFile(e[0])
                }
              }}
            />
            <div className="mt-20 w-[30%]">
              <AppButton
                variant="primary"
                size="sm"
                label="Upload"
                disabled={!uploadedFile}
                onClick={() => {
                  if (uploadedFile) {
                    onFileUpload(uploadedFile)
                    setShowUploadModal(false)
                  }
                }}
              />
            </div>
          </div>
        </AppModalBox>
      )}
    </form>
  )
}
