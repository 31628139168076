import React from 'react'

import { AppFC } from 'types'
import useDateLabeler from 'hooks/useDateLabeler'
import { AppActionCard } from 'components/Common/AppActionCard/AppActionCard.component'
import history from 'utils/history'
import { useTenantId } from 'utils/useTenantId'
import { getRoleFromPath } from 'utils/helper'
import { AppCalendarPinIcon } from 'components/Common/AppSvgIcons/AppCalendarPinIcon'

export interface IEventCardProps {
  startDate: string
  endDate: string
  description: string
  eventId: string
  className?: string
  eventType?: string
}

export const AppEventCard: AppFC<IEventCardProps> = ({
  startDate,
  endDate,
  description,
  eventId,
  className = '',
  eventType,
}) => {
  const startDateLabeler = useDateLabeler(startDate)
  const endDateLabeler = useDateLabeler(endDate)
  const tenantId = useTenantId()
  return (
    <AppActionCard
      className={`hover:shadow-sm ${className}`}
      onClick={() => history.push(`/${getRoleFromPath()}/${tenantId}/calendar?eventId=${eventId}`)}
    >
      <div className="flex items-center gap-8">
        <div className="relative rounded-xl p-[1.55px] bg-gradient-to-b from-white to-app-primary-115">
          <div className="rounded-xl bg-white h-full">
            <div className="flex justify-center h-4 bg-gradient-to-r from-app-primary-45 to-app-primary-60 rounded-t-xl">
              <AppCalendarPinIcon />
            </div>
            <div className="flex flex-col justify-center items-center bg-white rounded-b-xl px-5 py-2">
              <p className="text-app-primary-60 leading-5 font-bold font-inter text-[13px]">
                {startDateLabeler.abbreviatedDayOfWeek}
              </p>
              <p className="bg-gradient-to-b from-app-primary-60 to-app-primary-45 bg-clip-text text-transparent leading-4 font-bold text-[21px] font-inter">
                {startDateLabeler.day}
              </p>
            </div>
          </div>
        </div>
        <div className="space-y-1.5" data-testid="upcomingEvent">
          <h4 className="font-inter font-medium text-sm text-app-black-100">
            {eventType === 'INTERNAL' ? 'Call With Client' : 'External Meeting'}
          </h4>
          <p className="font-inter font-medium text-sm text-app-grey-80">
            {startDateLabeler.timeOfDay} - {endDateLabeler.timeOfDay}
          </p>
          <p className="font-inter font-normal text-sm text-app-grey-80">{description}</p>
        </div>
      </div>
    </AppActionCard>
  )
}
