import React, { useRef, useEffect } from 'react'
import EmojiPicker from 'emoji-picker-react'
import { EmojiHappyIcon } from '@heroicons/react/outline'

interface AppEmojiPickerProps {
  onEmojiClick: (emojiData: any) => void
  isOpen: boolean
  onToggle: () => void
  buttonClassName?: string
  showEmojiIcon?: boolean
}

export const AppEmojiPicker: React.FC<AppEmojiPickerProps> = ({
  onEmojiClick,
  isOpen,
  onToggle,
  buttonClassName = 'text-gray-400 hover:text-gray-500',
  showEmojiIcon = true,
}) => {
  const emojiPickerRef = useRef<HTMLDivElement>(null)
  const emojiButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target as Node) &&
        !emojiButtonRef.current?.contains(event.target as Node)
      ) {
        onToggle()
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, onToggle])

  return (
    <div className="relative">
      <button ref={emojiButtonRef} type="button" className={buttonClassName} onClick={onToggle}>
        {showEmojiIcon && <EmojiHappyIcon className="h-5 w-5" />}
      </button>
      {isOpen && (
        <div ref={emojiPickerRef} className="absolute bottom-full mb-2 left-0 z-50">
          <div className="relative bg-white rounded-lg shadow-lg">
            <div className="emoji-picker-container">
              <EmojiPicker onEmojiClick={onEmojiClick} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
