import moment from 'moment'
import { get } from 'lodash'
import { CYCLR_CALENDAR } from 'config'
import { getUpcomingEvents } from 'store/selectors/calendar'

export const getCalendarEvents = (events, totalUpComingMeeting = 0) => {
  const calendarEvents: any = []
  let upComingEvents
  let futureMeetings
  try {
    for (let i = 0; i < events?.length; i++) {
      let start: Date
      let end: Date
      if (events[i]?.start?.dateTime) {
        start = moment.tz(events[i]?.start?.dateTime, events[i]?.start?.timeZone).toDate()
        end = moment.tz(events[i]?.end?.dateTime, events[i]?.start?.timeZone).toDate()
      } else {
        start = moment(events[i]?.start?.date)?.toDate()
        end = moment(events[i]?.end?.date)?.toDate()
      }
      if (events[i]?.meta && typeof events[i].meta === 'string') {
        events[i].meta = JSON.parse(events[i]?.meta)
      }
      if (typeof events[i].attendees === 'string') {
        events[i].attendees = JSON.parse(events[i]?.attendees)
      }
      if (events[i].attendees) {
        let attendeesEmail = events[i].attendees.map((attendee) => {
          return attendee?.email
        })
        events[i].attendeesEmail = attendeesEmail
      }

      if (events[i]?.profile?.length > 0) {
        events[i].profile = JSON.parse(events[i].profile)
      }
      if (events[i].description) {
        var html = events[i].description
        var div = document.createElement('div')
        div.innerHTML = html
        events[i].text = div.innerText
        events[i].text = events[i].text.replace(/\n/g, ' ')
      }

      calendarEvents.push({
        calendar: CYCLR_CALENDAR.GOOGLE_CALENDAR,
        title: events[i]?.summary,
        start,
        end,
        attendeesEmail: events[i].attendeesEmail,
        text: events[i]?.text,
        description: events[i].description,
        location: events[i].location,
        textToShow: events[i].textToShow,
        timezone: events[i]?.start?.timeZone,
        attendees: events[i].attendees,
        eventType: events[i]?.eventType,
        organizer: events[i]?.meta?.organizer,
        inviteeId: events[i]?.inviteeId,
        eventId: events[i]?.eventId || '',
        advisorId: events[i]?.advisorId,
        advisor: events[i]?.profile
          ? get(events[i], 'profile.firstName', '') + ' ' + get(events[i], 'profile.lastName', '')
          : '',
        id: events[i]?.id,
        tackleId: events[i].meta?.tackleId || '',
        availability: events[i]?.availability,
        startDateTime: start.toISOString(),
        endDateTime: end.toISOString(),
        videoLinkDescription: events[i]?.videoLinkDescription,
        participantName: events[i]?.participantName,
        participantDetail: {
          firstName: events[i]?.participantFirstName || '',
          lastName: events[i]?.participantLastName || '',
        },
        organizerDetail: {
          firstName: events[i]?.organizerFirstName || '',
          lastName: events[i]?.organizerLastName || '',
        },
        organizerName: events[i]?.organizerName,
        externalReferenceIds: events[i]?.externalReferenceIds,
        ownerId: events[i]?.ownerId,
        teamMemberList: events[i]?.teamMemberList,
      })
    }

    futureMeetings = calendarEvents.filter((event) => new Date(event.start) >= new Date())
    //futureMeetings = sortBy(copy(futureMeetings), 'start')
    upComingEvents = getUpcomingEvents(futureMeetings, totalUpComingMeeting)
  } catch (err) {
    console.log('error: ', err)
  }
  return { upComingEvents, calendarEvents, futureMeetings }
}
