import React, { FC, memo, useEffect, useMemo, useState } from 'react'
import InputField from 'components/Common/InputField'
import ToolList from './ToolList.component'
import { useGetCategory } from '../hooks/useGetCategory.hooks'
import { isEmpty } from 'lodash'
import SearchIcon from 'assets/svgs/magnify.svg'
import GradientCircleNotchIcon from 'components/Common/SvgIcons/GradientCircleNotchIcon'
import history from 'utils/history'
import { InputWrapper } from '../style'
import { ConnectedProps } from 'react-redux'
import ToolsBubbleMobile from './ToolsBubbleMobile.component'
import ToolMobileCategory from './ToolMobileCategoy/ToolMobileCategory.component'
import { ToolsPanelConnector } from '../Tools.connector'
import { toolsCategory } from '../data'
import { useGetInput } from '../hooks/useGetInput.hooks'
import ErrorMessage from 'components/Common/ErrorMessage'
import { useGetAppToolsPathPrefix } from 'components/AppTools/AppToolsHooks/useGetAppToolsPathPrefix.hooks'
interface IToolsPanelProps {
  search: string
  query: string
  setSearch: (param: string) => void
  readonly?: boolean
}

interface IToolsPanelProps extends ConnectedProps<typeof ToolsPanelConnector> {}

const ToolsPanel = ToolsPanelConnector<FC<IToolsPanelProps>>(
  ({
    query,
    setSearch,
    search,
    getSearchToolsAction,
    isFetching,
    toolsList,
    errorMessage,
    readonly,
  }) => {
    const toolCategory = useGetCategory()

    const [open, setOpen] = useState(false)
    const pathPrefix = useGetAppToolsPathPrefix()

    const hasSubCategory = useMemo(
      () =>
        toolsCategory.Featured.label === toolCategory.label ||
        toolsCategory.toolKit.label === toolCategory.label ||
        !isEmpty(query),

      [toolCategory.label, query]
    )

    const input = useGetInput(search, toolCategory.label)
    useEffect(() => {
      if (toolCategory.label) {
        if (input.freeFormSearchQuery) {
          input.freeFormSearchQuery = ''
          input.featured = true
        }
        getSearchToolsAction({ input, hasSubCategory })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toolCategory?.label])

    const handleClick = () => {
      const searchQuery = search.trim()
      if (searchQuery) {
        getSearchToolsAction({ input, hasSubCategory })
        history.push(`${pathPrefix}/search?query=${searchQuery}`)
      }
    }

    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleClick()
      }
    }
    useEffect(() => {
      if (window.location.href.includes('search') && query) {
        setSearch(query)
        getSearchToolsAction({ input, hasSubCategory })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <div className="col-span-12 mdl:col-span-9  p-6 mdl:p-12">
        <div className="grid grid-cols-12 mdl:grid-cols-6">
          <div className="col-span-12 mdl:col-span-2 mdl:col-start-5">
            <InputWrapper>
              <InputField
                type="search"
                placeholder="Search all tools..."
                className="w-full !py-5 !mt-0"
                name="search"
                rightIcon={SearchIcon}
                onClickIcon={handleClick}
                onKeyPress={handleKeyPress}
                value={search}
                onChange={(e) => {
                  if (!e.target.value && window.location.pathname.includes('search')) {
                    getSearchToolsAction({
                      input: { featured: true },
                      hasSubCategory: false,
                    })
                    history.push(`${pathPrefix}/featured`)
                  }
                  setSearch(e.target.value)
                }}
              />
            </InputWrapper>
          </div>
        </div>
        <ToolMobileCategory setOpen={setOpen} toolCategoryValue={toolCategory.label} />
        {errorMessage && <ErrorMessage id="errorMessage">{errorMessage}</ErrorMessage>}
        {isFetching ? (
          <div className="flex justify-center items-center">
            <GradientCircleNotchIcon width="100" height="100" />
          </div>
        ) : (
          toolsList &&
          !errorMessage && (
            <ToolList
              toolCategoryLabel={toolCategory?.label || ''}
              toolDescription={
                readonly
                  ? toolCategory?.clientDescription || toolCategory?.description || '' // show clientDescription in readonly mode
                  : toolCategory?.description || ''
              }
              hasSubCategory={hasSubCategory}
              query={query}
              readonly={readonly}
            />
          )
        )}
        <ToolsBubbleMobile setSearch={setSearch} setOpen={setOpen} open={open} />
      </div>
    )
  }
)

export default memo(ToolsPanel)
