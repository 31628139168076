// TODO: Find a way to move this back into the common service, without ejecting this webapp (which needs all files to be be from /src)
export type FeatureFlagConfig = {
  [flagName in FeatureFlag]: {
    level: TrafficType
  }
}

export enum TrafficType {
  USER = 'user', // User ID level flag
  ORG = 'org', // Tenant/BSO level flag
}

/** Add/revise feature flags here, and then fill out the configuration below. */
export type FeatureFlag =
  | 'release-notifications'
  | 'release-announcement'
  | 'release-data-charts'
  | 'release-external-calendar-sync'
  | 'release-helpdesk-rollout'
  | 'release-attestations-rollout'
  | 'release-meeting-record-rollout'
  | 'release-advisor-schedule-meeting-rollout'
  | 'release-tool-flyout-menu'
  | 'release-industry-report'
  | 'release-internal-oauth-flow'
  | 'release-task-recommendations'
  | 'release-auto-gen-meeting-links'
  | 'release-v2-org'
  | 'release-v2-user'
  | 'release-regenerate-plan'
  | 'release-field-view-misc-plays'
  | 'release-data-tab'
  | 'release-goal-card-menu'
  | 'operational-llm-backup-testing'
  | 'release-advisor-v2-dashboard'
  | 'operational-allow-past-task-dates'
  | 'operational-show-navigation-tabs'
  | 'release-advisor-v2-dashboard-client-activity'
  | 'release-advisor-add-goal'

export const FEATURE_FLAG_CONFIG: FeatureFlagConfig = {
  'release-task-recommendations': {
    level: TrafficType.ORG,
  },
  'release-notifications': {
    level: TrafficType.USER,
  },
  'release-announcement': {
    level: TrafficType.USER,
  },
  'release-data-charts': {
    level: TrafficType.USER,
  },
  'release-external-calendar-sync': {
    level: TrafficType.USER,
  },
  'release-helpdesk-rollout': {
    level: TrafficType.ORG,
  },
  'release-attestations-rollout': {
    level: TrafficType.ORG,
  },
  'release-meeting-record-rollout': {
    level: TrafficType.ORG,
  },
  'release-advisor-schedule-meeting-rollout': {
    level: TrafficType.ORG,
  },
  'release-tool-flyout-menu': {
    level: TrafficType.USER,
  },
  'release-industry-report': {
    level: TrafficType.ORG,
  },
  'release-auto-gen-meeting-links': {
    level: TrafficType.ORG,
  },
  'release-internal-oauth-flow': {
    level: TrafficType.ORG,
  },
  'release-v2-org': {
    level: TrafficType.ORG,
  },
  'release-v2-user': {
    level: TrafficType.USER,
  },
  'release-regenerate-plan': {
    level: TrafficType.USER,
  },
  'release-field-view-misc-plays': {
    level: TrafficType.USER,
  },
  'release-data-tab': {
    level: TrafficType.USER,
  },
  'release-goal-card-menu': {
    level: TrafficType.USER,
  },
  'operational-llm-backup-testing': {
    level: TrafficType.USER,
  },
  'release-advisor-v2-dashboard': {
    level: TrafficType.USER,
  },
  'operational-allow-past-task-dates': {
    level: TrafficType.USER,
  },
  'operational-show-navigation-tabs': {
    level: TrafficType.USER,
  },
  'release-advisor-v2-dashboard-client-activity': {
    level: TrafficType.USER,
  },
  'release-advisor-add-goal': {
    level: TrafficType.USER,
  },
}
