import React, { FC, useEffect, useState } from 'react'
import { ampli } from 'ampli'

import GradientCircleNotchIcon from 'components/Common/SvgIcons/GradientCircleNotchIcon'
import { AppCardBlock } from 'components/AppTools/AppTools.style'

import {
  Tool,
  ToolSupportLevel,
  useDeauthenticateConnectorMutation,
} from '__generated__/api-types-and-hooks'
import useTenantId from 'hooks/useTenantId'
import { AppToolCardHeader } from 'components/AppTools/AppToolsPanel/AppToolCardHeader.component'
import { AppToolsLabel } from 'components/AppTools/AppToolsPanel/AppToolsLabel.component'
import { AppToolsButton } from 'components/AppOnboarding/AppChatbot/AppDynamicChatMessage/AppToolBlock/AppToolsButton.component'
import { AppPillBoxAndSetting } from 'components/AppTools/AppToolsPanel/AppToolBlock/AppPillBoxAndSetting/AppPillBoxAndSetting.component'

export interface IAppBlockProps {
  tool: Tool
  getOAuthUrlAction: (props: any) => void
  setActiveConnectingTool?: (tool: Tool) => void
  allowDisconnect?: boolean
  onDisconnect?: () => void
  targetOrigin?: string
  bookmarkCallback?: () => void
}

export const AppToolBlock: FC<IAppBlockProps> = ({
  tool,
  getOAuthUrlAction,
  setActiveConnectingTool,
  onDisconnect,
  targetOrigin,
  bookmarkCallback,
}) => {
  const [isConnected, setIsConnected] = useState(tool.isConnected)
  const tenantId = useTenantId()
  const {
    mutate,
    isLoading: isToolDisconnecting,
    data: toolDisconnectedResponse,
  } = useDeauthenticateConnectorMutation()

  useEffect(() => {
    if (toolDisconnectedResponse && !toolDisconnectedResponse?.deauthenticateConnector?.error) {
      setIsConnected(false)
      ampli.toolDisconnected({ name: tool.toolName ?? '' })
      if (onDisconnect) {
        onDisconnect()
      }
    }
    // eslint-disable-next-line
  }, [toolDisconnectedResponse])

  useEffect(() => {
    setIsConnected(tool.isConnected)
  }, [tool])

  const handleDisconnect = () => {
    mutate({ input: { connectorName: tool.toolName ?? '', tenantId: tenantId ?? '' } })
  }
  const isToolConnectable = tool.supportLevel && tool.supportLevel !== ToolSupportLevel.Open

  return (
    <>
      <AppCardBlock className="w-full  h-[315px] xs:max-w-[100%] sm:max-w-[330px] flex flex-col border border-primary-outline rounded-[20px] bg-white pt-6">
        <AppToolCardHeader key={tool.id} tool={tool} bookmarkCallback={bookmarkCallback} />

        <div className="flex flex-col justify-between px-6 h-full rounded-b-[20px] pb-6">
          <ToolDisconnection isToolDisconnecting={isToolDisconnecting} />
          <AppToolsLabel tool={tool} isToolDisconnecting={isToolDisconnecting} />
          {isToolConnectable && (
            <AppPillBoxAndSetting
              isToolDisconnecting={isToolDisconnecting}
              isConnected={isConnected || false}
              handleDisconnect={handleDisconnect}
            />
          )}
          <AppToolsButton
            isConnected={isConnected || false}
            tool={tool}
            setActiveConnectingTool={setActiveConnectingTool}
            getOAuthUrlAction={getOAuthUrlAction}
            isNewTools={true}
            targetOrigin={targetOrigin}
          />
        </div>
      </AppCardBlock>
    </>
  )
}

const ToolDisconnection = ({ isToolDisconnecting }) => {
  return (
    isToolDisconnecting && (
      <div className="flex flex-row">
        <GradientCircleNotchIcon width="30" height="30" />
        <div className="ml-2 my-auto font-semibold">Disconnecting</div>
      </div>
    )
  )
}
