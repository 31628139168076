import React, { FC, ElementType } from 'react'
import Button from 'components/Common/Button'
import { CardBlock } from 'components/Owner/Tools/style'
import { sanitize } from 'dompurify'

interface IPlaceholderBlock {
  Icon: ElementType
  Paragraph: string
  buttonLabel?: string
  handleClick?: () => void
}
const PlaceholderBlock: FC<IPlaceholderBlock> = ({ Icon, Paragraph, buttonLabel, handleClick }) => {
  return (
    <CardBlock className="w-full xs:max-w-[100%] sm:max-w-[330px] h-[315px] flex flex-col border border-primary-outline rounded-[10px] bg-white p-12 items-center justify-center text-center">
      <Icon />
      <div dangerouslySetInnerHTML={{ __html: sanitize(Paragraph) }} />
      {buttonLabel && <Button label={buttonLabel} variant="text" onClick={handleClick} />}
    </CardBlock>
  )
}

export default PlaceholderBlock
