import React, { useEffect, useState } from 'react'
import AppDashboardTasksSummary from './AppDashboardTasksSummary'
import AppDashboardTrendsSummary from './AppDashboardTrendsSummary'
import AppDashboardAchievmentsSummary from './AppDashboardAchievmentsSummary'
import AppFutureEvents from './AppFutureEvents/AppFutureEvents.component'

import AppQuote from 'components/AppQuote/AppQuote.component'

import moment from 'moment'
import { useGetQuoteQuery, useUpdateMyProfileMutation } from '__generated__/api-types-and-hooks'
import { getTenantId } from 'utils/getTenantId'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'

import useGetUserProfile from 'components/AppDashboard/AppDashboard.hook'
import { RootState } from 'App'
import { AppDashboardHeader } from 'components/AppDashboard/AppDashboardHeader/AppDashboardHeader.component'

const AppDashboard = () => {
  const tenantId = getTenantId()
  const clientId = useSelector((state: RootState) => state.clients.client.profile.id)
  const [showQuoteModal, setShowQuoteModal] = useState(true)

  const { data } = useGetQuoteQuery()
  const getUserProfile = useGetUserProfile()
  const currentUser = useSelector((state: RootState) => state.user.user)
  const { mutate: updateUserProfile, isSuccess } = useUpdateMyProfileMutation()

  const currentDate = moment.tz(currentUser.timezone || 'America/Chicago').format('DD-MM-YYYY')
  const isSameDate = currentDate === currentUser.quoteViewDate || ''

  useEffect(() => {
    if (isSuccess) {
      getUserProfile()
    }
    // eslint-disable-next-line
  }, [isSuccess])

  return (
    <div className="md:w-full">
      {showQuoteModal && !isSameDate && !isEmpty(data?.getQuote) && (
        <AppQuote
          onClose={() => {
            updateUserProfile({
              input: {
                userId: currentUser.id,
                tenantId,
                quoteViewDate: currentDate.toString(),
              },
            })
            setShowQuoteModal(false)
          }}
          quote={data?.getQuote?.quote || ''}
          author={data?.getQuote?.author || ''}
          currentDay={currentDate.split('-')[0]}
        />
      )}
      {!clientId && (
        <AppDashboardHeader
          userName={`${currentUser.firstName} ${currentUser.lastName}`}
          activatedAt={currentUser.activatedAt}
        />
      )}
      <div className="mt-8 space-y-3 smd:grid smd:grid-cols-2 smd:gap-3 smd:space-y-0 px-2 smd:px-0">
        <AppDashboardTasksSummary />
        <AppDashboardTrendsSummary />
        <AppFutureEvents
          calendarEventViewPreference={currentUser?.calendarEventViewPreference ?? ''}
          role={(currentUser?.roles ?? []) as string[]}
        />
        <AppDashboardAchievmentsSummary user={currentUser} />
      </div>
    </div>
  )
}

export default AppDashboard
