import tw from 'twin.macro'
import styled from 'styled-components'

interface ContainerProps {
  isDragging: boolean
}

export const Container = styled.div<ContainerProps>`
  ${tw`
    rounded-lg
    shadow-md
    p-4
    text-black
    min-h-[100px]
    bg-white
    cursor-pointer
    flex
    justify-between
    flex-col
  `}
`

export const AppCardWrapper = styled.div`
  ${tw`flex flex-col gap-3 items-start`}
`

export const CardHeader = styled.div`
  ${tw`flex justify-between w-full items-start`}
`

export const TaskTextWrapper = styled.span`
  ${tw`flex flex-col`}
`

export const TaskName = styled.h3`
  ${tw`text-app-black-100 truncate max-w-[200px] text-sm font-inter`}
`

export const TaskInstruction = styled.p`
  ${tw`text-sm text-app-grey-70 truncate max-w-[160px] font-inter`}
`
export const MessageIcon = styled.img`
  ${tw`w-[20px] h-[20px] self-start`}
`
export const AppCardFooter = styled.div`
  ${tw`w-full`}
`
export const FooterContentWrapper = styled.div`
  ${tw`flex justify-between w-full items-center gap-2`}

  // Ensures AvatarImage stays to the right
  & > :last-child {
    ${tw`ml-auto flex-shrink-0`}
  }
`

export const BadgesContainer = styled.div`
  ${tw`flex flex-wrap items-center gap-1 max-w-[calc(100% - 40px)]`}
  & > * {
    ${tw`flex-shrink-0`}
  }
`
