import React, { FC } from 'react'

import { Tool } from '__generated__/api-types-and-hooks'
import { IconWrapper } from 'components/Owner/Tools/style'
import { getImageUrl } from 'utils/helper'
import BookmarkTool from './BookmarkTool.component'

interface ICardHeadProps {
  tool: Tool
  readonly?: boolean
}
const ToolCardHeader: FC<ICardHeadProps> = ({ tool, readonly }) => {
  return (
    <div className="flex flex-row justify-between items-start px-6">
      <IconWrapper
        className="!max-w-[150px]"
        src={getImageUrl(tool.imageUrl)}
        alt={`${tool?.toolName}`}
      />
      <BookmarkTool tool={tool} readonly={readonly} />
    </div>
  )
}

export default ToolCardHeader
