import React, { FC, memo, useState } from 'react'
import VerticalDotted from 'components/Common/SvgIcons/VerticalDotted'
import Button from 'components/Common/Button'
import { Popover } from 'react-tiny-popover'

export interface ISettingsProps {
  handleDisconnect: () => void
  disabled?: boolean
}

const Settings: FC<ISettingsProps> = ({ handleDisconnect, disabled }) => {
  const [isOpen, setOpen] = useState(false)

  const handleClickDisconnect = () => {
    handleDisconnect()
    setOpen(false)
  }

  return (
    <div
      onClick={!disabled ? () => setOpen(!isOpen) : undefined}
      className="inline-flex items-center text-base font-primary mr-auto cursor-pointer relative"
    >
      <VerticalDotted className={disabled ? 'fill-primary-outline' : 'fill-black-lighter'} />

      <Popover
        containerClassName="!z-[50]"
        isOpen={isOpen}
        align="center"
        positions={['bottom']}
        reposition={false}
        onClickOutside={() => setOpen(false)}
        content={
          <div className="flex relative bg-white shadow-md rounded-lg p-3 mt-3.5">
            <Button label="Disconnect" variant="text" onClick={handleClickDisconnect} />
          </div>
        }
      >
        <div className="absolute"></div>
      </Popover>
    </div>
  )
}

export default memo(Settings)
