import React from 'react'

function SuggestionsIcon({ className = 'shrink-0' }: { className?: string }) {
  return (
    <svg
      className={className}
      width="33"
      height="58"
      viewBox="0 0 33 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M0 22.5C0 16.9772 4.47715 12.5 10 12.5H23C28.5228 12.5 33 16.9772 33 22.5V35.5C33 41.0228 28.5228 45.5 23 45.5H10C4.47715 45.5 0 41.0228 0 35.5V22.5Z"
        fill="#3B82F6"
      />
      <circle cx="16.5" cy="29" r="9.5" fill="#3B82F6" />
      <path
        d="M16.4996 23.15C14.3457 23.15 12.5996 24.8961 12.5996 27.05C12.5996 28.2624 13.1534 29.3458 14.02 30.0604C14.704 30.6243 15.1996 31.2378 15.1996 31.8963V32.3152C15.1996 32.5399 15.3532 32.7355 15.5714 32.7888C15.8694 32.8616 16.1803 32.9 16.4996 32.9C16.8189 32.9 17.1298 32.8616 17.4278 32.7888C17.646 32.7355 17.7996 32.5399 17.7996 32.3152V31.8963C17.7996 31.2378 18.2952 30.6243 18.9792 30.0604C19.8458 29.3458 20.3996 28.2624 20.3996 27.05C20.3996 24.8961 18.6535 23.15 16.4996 23.15Z"
        fill="#D4E2F9"
      />
      <path
        d="M15.7604 33.8194C15.4943 33.779 15.2457 33.9619 15.2052 34.2281C15.1648 34.4943 15.3477 34.7429 15.6139 34.7833C15.903 34.8273 16.1989 34.85 16.4997 34.85C16.8005 34.85 17.0963 34.8273 17.3854 34.7833C17.6516 34.7429 17.8346 34.4943 17.7941 34.2281C17.7537 33.9619 17.5051 33.779 17.2389 33.8194C16.9981 33.856 16.7513 33.875 16.4997 33.875C16.2481 33.875 16.0012 33.856 15.7604 33.8194Z"
        fill="#D4E2F9"
      />
    </svg>
  )
}

export default SuggestionsIcon
