import React, { FC, ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { ConnectedProps } from 'react-redux'

import history from 'utils/history'
import { ToolsBubbleWrapperConnector } from 'components/AppTools/AppTools.connector'
import { AppToolBubble } from 'components/AppTools/AppToolCategoryPanel/AppToolBubble/AppToolBubble.component'
import { useGetAppToolsPathPrefix } from '../AppToolsHooks/useGetAppToolsPathPrefix.hooks'

interface IAppToolsBubbleWrapperProps {
  setSearch: (param: string) => void
  setOpen?: (param: boolean) => void
  children?: ReactNode
}

interface IAppToolsBubbleWrapperProps extends ConnectedProps<typeof ToolsBubbleWrapperConnector> {}

export const AppToolsBubbleWrapper = ToolsBubbleWrapperConnector<FC<IAppToolsBubbleWrapperProps>>(
  ({ tenantId, toolsCategoryData, setSearch, setOpen, children, resetGetSearchToolsAction }) => {
    const { toolSubTab } = useParams()
    const pathPrefix = useGetAppToolsPathPrefix()

    return (
      <div className="flex flex-col h-full gap-40">
        <div className="flex flex-wrap gap-6 mdl:gap-3">
          {toolsCategoryData.map((category, index) => (
            <AppToolBubble
              key={index}
              category={category}
              index={index}
              handleBubbleSelection={(category) => {
                if (category !== toolSubTab) {
                  resetGetSearchToolsAction()
                  setSearch('')
                  setTimeout(() => {
                    history.push(`${pathPrefix}/${category}`)
                  }, 0)
                  setOpen && setOpen(false)
                }
              }}
              selectedBubble={toolSubTab}
            />
          ))}
        </div>
        {children && children}
      </div>
    )
  }
)
