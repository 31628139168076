import React from 'react'

function StarIcon({ className = '' }) {
  return (
    <svg
      className={className}
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M8.18182 0.5L7.60909 1.75L6.36364 2.31818L7.60909 2.89091L8.18182 4.13636L8.75 2.89091L10 2.31818L8.75 1.75M3.63636 1.86364L2.5 4.36364L0 5.5L2.5 6.63636L3.63636 9.13636L4.77273 6.63636L7.27273 5.5L4.77273 4.36364M8.18182 6.86364L7.60909 8.10909L6.36364 8.68182L7.60909 9.25L8.18182 10.5L8.75 9.25L10 8.68182L8.75 8.10909"
        fill="white"
      />
    </svg>
  )
}

StarIcon.defaultProps = {
  className: '',
}
export default StarIcon
