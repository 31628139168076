import React from 'react'

import { AppButton } from 'components/Common/AppButton/AppButton.component'
import { AppModalBox } from 'components/Common/AppModalBox/AppModalBox.component'
import { AppCalendarPinIcon } from 'components/Common/AppSvgIcons/AppCalendarPinIcon'

interface AppButtonProps {
  quote: string
  author: string
  onClose: () => void
  currentDay: string
}

const AppQuote: React.FC<AppButtonProps> = ({ onClose, quote, author, currentDay }) => {
  return (
    <AppModalBox classes="p-6" height="h-auto" width="w-[320px]">
      <div className=" flex flex-col gap-8">
        <div className="bg-gradient-to-b from-app-primary-35 to-app-primary-15 h-[115px] rounded-[10px] overflow-hidden">
          <div className="mx-auto mt-6 relative rounded-xl p-[2.5px] bg-gradient-to-b from-white to-app-primary-115 w-[95.5px] h-[95.5px] -rotate-12">
            <div className="rounded-xl bg-white h-full">
              <div className="flex justify-center h-6 bg-gradient-to-r from-app-primary-45 to-app-primary-60 rounded-t-xl">
                <AppCalendarPinIcon />
              </div>
              <div className="flex justify-center items-center rounded-b-xl bg-white h-[70%]">
                <h3 className="bg-gradient-to-b from-app-primary-60 to-app-primary-45 bg-clip-text text-transparent font-extrabold text-[45px] font-inter">
                  {currentDay}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <h2 className="text-center font-semibold text-base">Quote of The Day</h2>
        <p className="text-center text-sm">{quote}</p>
        <p className="text-center font-semibold text-base">{author}</p>

        <div className=" w-full flex justify-center items-center gap-2 p-2 px-4 align-self-stretch">
          <AppButton
            size={'lg'}
            variant={'secondary'}
            label="Close"
            onClick={onClose}
            isFullWidth={true}
          />
        </div>
      </div>
    </AppModalBox>
  )
}

export default AppQuote
