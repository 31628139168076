import React, { FC } from 'react'
import ChevronBottomIcon from 'components/Common/SvgIcons/ChevronBottomIcon'
import { isEmpty } from 'lodash'

interface IToolMobileCategoryProps {
  setOpen: (param: boolean) => void
  toolCategoryValue: string
}
const ToolMobileCategory: FC<IToolMobileCategoryProps> = ({ setOpen, toolCategoryValue }) => {
  return (
    <div className="pt-1 pb-14 mdl:hidden">
      <div
        className="rounded-[30px] border-2 border-silver h-fit w-fit"
        onClick={() => setOpen(true)}
      >
        <div className="flex items-center px-5 py-[10px]">
          <span className="text-silver font-[650] text-lg">
            {isEmpty(toolCategoryValue) ? 'Filter by Category' : toolCategoryValue}
          </span>
          <ChevronBottomIcon />
        </div>
      </div>
    </div>
  )
}

export default ToolMobileCategory
