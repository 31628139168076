import { Tool, ToolAuthType } from '../../../__generated__/api-types-and-hooks'
import ModalBox from 'components/Common/ModalBox'
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import InputField from 'components/Common/InputField'
import { IconWrapper } from './style'
import { getImageUrl } from 'utils/helper'
import Button from 'components/Common/Button'
import ErrorMessage from 'components/Common/ErrorMessage'
import { IGetOAuthUrlAction, ToolConnectionStep } from 'types'
import { ReduxAction } from 'store/types'
import ExternalLinkLaunch from 'assets/svgs/external-link.svg'

export interface IToolConnectionInputFormModalProps {
  tool: Tool
  onClose: () => void
  getOAuthUrlAction: ReduxAction<IGetOAuthUrlAction>
  oAuthUrl?: string
}

export const ToolConnectionInputFormModal = ({
  tool,
  onClose,
  getOAuthUrlAction,
  oAuthUrl,
}: IToolConnectionInputFormModalProps) => {
  useEffect(() => {}, [oAuthUrl])
  const [toolConnectionStep, setToolConnectionStep] = useState(ToolConnectionStep.CONNECTION_FORM)

  const formFields = tool.options?.connectionForm?.inputProperties
  const buildYupSchema = () => {
    const yupObject = {}
    formFields?.forEach((field) => {
      if (field.validationRules) {
        let validator: yup.AnySchema = yup[field.type.toLowerCase()]()
        const fieldYupSchema = field.validationRules.reduce((yupField, rule) => {
          if (rule === 'required') {
            yupField = yupField.required()
          }
          if (rule.startsWith('regex:')) {
            const regexPattern = rule.substring('regex:'.length)
            yupField = (yupField as yup.StringSchema).matches(new RegExp(regexPattern), {
              message: 'Invalid value',
            })
          }
          return yupField
        }, validator)
        yupObject[field.name] = fieldYupSchema
      }
    })

    return yup.object().shape(yupObject)
  }

  const schema = buildYupSchema()

  const {
    handleSubmit,
    control,
    formState: { isValid },
    getValues,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  })

  const handleFormData = (data) => {
    const oAuthData = Object.entries(data).map(([name, value]) => {
      return {
        name,
        value: String(value),
      }
    })
    getOAuthUrlAction({
      connectorName: tool.toolName ?? '',
      oAuthConnectionProperties: oAuthData,
      targetOrigin: window.location.href,
      authType: tool.authType,
    })
  }

  const onSubmit = (data) => {
    handleFormData(data)
    if (tool.authType === ToolAuthType.Oauth) {
      setToolConnectionStep(ToolConnectionStep.CONNECTING)
    } else {
      onClose()
    }
  }

  const onRetry = () => {
    const data = getValues()
    handleFormData(data)
  }

  return (
    <ModalBox
      title={'Connect your account'}
      onClose={onClose}
      isBorderWidthFull={true}
      height="h-full sm:h-auto"
    >
      <div className="flex flex-col">
        <div className="flex ml-8 my-8">
          <IconWrapper
            src={getImageUrl(tool.connectionFormImageUrl ?? tool.imageUrl)}
            alt={`${tool?.toolName}`}
          />
        </div>
        {toolConnectionStep === ToolConnectionStep.CONNECTION_FORM && (
          <>
            <p className="mx-8">{tool.options?.connectionForm?.description}</p>
            <form onSubmit={handleSubmit(onSubmit)} className="my-8 mx-8">
              {formFields?.map((toolFormField) => (
                <div key={toolFormField.name}>
                  <label htmlFor={toolFormField.name}>{toolFormField.displayName}</label>
                  <Controller
                    name={toolFormField.name}
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                      <>
                        {toolFormField.type === 'String' && (
                          <InputField
                            placeholder={toolFormField?.placeholder ?? ''}
                            id={`${toolFormField.name}-id`}
                            {...field}
                          />
                        )}
                        {fieldState?.error && (
                          <ErrorMessage id="error">{fieldState.error.message}</ErrorMessage>
                        )}
                      </>
                    )}
                  />
                </div>
              ))}
              <div className="my-8 w-full flex justify-end">
                <Button
                  type="submit"
                  label="Sign in"
                  disabled={!isValid}
                  icon={ExternalLinkLaunch}
                />
              </div>
            </form>
          </>
        )}
        {toolConnectionStep === ToolConnectionStep.CONNECTING && (
          <div className="flex flex-col ml-8">
            <p className="my-4">
              We’ve opened the {tool.toolName} sign in screen in a new window.{' '}
              <span onClick={onRetry} className="text-primary hover:cursor-pointer">
                You can also open it here.
              </span>
            </p>
            <p className="my-4">
              If you had problems connecting to {tool.toolDisplayName}, please try connecting again
              from the tools page.
            </p>
            <div className="my-8 ml-auto mr-8">
              <Button
                label="Back"
                onClick={() => setToolConnectionStep(ToolConnectionStep.CONNECTION_FORM)}
              />
            </div>
          </div>
        )}
      </div>
    </ModalBox>
  )
}
