import styled from 'styled-components'
import tw from 'twin.macro'

interface AdditionalHelpBoxProps {
  className?: string
}

export const QuestionWrapper = styled.div.attrs({
  className: 'xs:col-span-12 xs:pt-6',
})``

export const RadioButtonWrapper = styled.div`
  ${tw`xs:col-span-4 sm:col-span-4 gap-y-5 inline-grid justify-start flex-row pt-3`}
`

export const MonthlyAttestationFormWrapper = styled.div`
  ${tw`grid grid-cols-12 w-full py-7 xs:px-0 sm:px-0 bg-white`}
`

export const FormBody = styled.div`
  ${tw`grid grid-cols-12 w-full xs:px-5 sm:px-0 pb-12`}
`

export const FormFooter = styled.div`
  ${tw`xs:px-5 sm:px-0`}
`

export const FormFooterLabel = styled.div`
  ${tw`font-primary text-base	text-black pb-12`}
`

export const MonthlyAttestationBody = styled.div`
  ${tw`xs:col-span-12 sm:col-start-3 sm:col-end-12`}
`

export const CurrencyInputWrapper = styled.div.attrs({
  className: 'xs:col-span-8 sm:col-span-8',
})``
export const RevenueFieldWrapper = styled.div.attrs({
  className: 'flex justify-between items-center gap-3',
})``
export const FormTitleWrapper = styled.div.attrs({
  className:
    'xs:mb-3 sm:mb-12 xs:flex xs:justify-center xs:flex-col xs:items-start xs:px-5 sm:px-0',
})``
export const FormTitle = styled.label`
  ${tw`text-4xl font-secondary leading-10`}
`

export const DateTimeWrapper = styled.label`
  ${tw`text-base font-primary font-bold`}
`

export const LabelWrapper = styled.div`
  ${tw`py-4`}
`
export const BusinessLabel = styled.label`
  ${tw`text-base font-primary font-bold block`}
`

export const OrganizationNameWrapper = styled.label`
  ${tw`text-sm font-primary text-black`}
`

export const CurrencyWrapper = styled.div`
  ${tw`grid gap-x-4 items-start xs:pt-3`}
`

export const FormHeader = styled.div`
  ${tw`border-b border-primary-outline`}
`

export const TitleWrapper = styled.div`
  ${tw`font-primary xs:text-2xl xs:font-light xs:text-black-light pt-12`}
`

export const FormInfoWrapper = styled.div`
  ${tw`text-base pt-12`}
`
export const FormInfoParagraph = styled.p`
  ${tw`font-medium mb-6`}
`
export const FormInfoParagraphItalic = styled.p`
  ${tw`italic font-normal`}
`
export const AdditionalTextWrapper = styled.p`
  ${tw`text-sm font-primary mt-3`}
`

export const FinancialHeadingWrapper = styled.div`
  ${tw`xs:text-base sm:text-xl font-medium text-primary-text text-black pt-6`}
`

export const ButtonWrapper = styled.div`
  ${tw`px-5 pb-5`}
`

export const ModalBoxWrapper = styled.div`
  ${tw`flex flex-col gap-7 px-5 pt-9 text-[20px]`}
`

export const TextWrapper = styled.p<{ isReminder?: boolean }>(({ isReminder }) => {
  return [tw`font-medium `, isReminder ? tw` pb-12 ` : tw``]
})

export const MonthlyAttestationFromWrapper = styled.div`
  ${tw`grid grid-cols-12`}
`

export const AdditionalHelpBoxWrapper = styled.div`
  ${tw`xs:col-span-12 sm:col-span-8 mdl:col-span-12 xs:block lg:hidden mt-16`}
`
export const PersonalDetailsWrapper = styled.div`
  ${tw`xs:col-span-12 sm:col-span-8 mdl:col-span-12 lg:col-span-6`}
`
export const AdditionalHelpBoxDesktopWrapper = styled.div`
  ${tw`col-span-6 ml-20 mt-20 xs:hidden lg:block pt-16`}
`
export const BusinessStructureWrapper = styled.div`
  ${tw`xs:col-span-12 sm:col-span-8 mdl:col-span-12 lg:col-span-6`}
`
export const FormWrapper = styled.form`
  ${tw`xs:col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-8`}
`
export const AdditionalHelpBoxGradientWrapper = styled.div<AdditionalHelpBoxProps>`
  ${tw`bg-gradient-to-r from-purple-lighter1x to-primary-brandBlueLight p-[1px] rounded-[22px]`}
  ${(props) => (props.className ? tw`${props.className}` : tw``)}
`
// export const AdditionalHelpBoxFlexWrapper = styled.div`
//   ${tw`flex flex-col w-full gap-4 bg-white rounded-[21px] `}
// `
export const AdditionalHelpBoxImageWrapper = styled.div`
  ${tw`flex items-center gap-4`}
`
export const AdditionalHelpBoxLabelWrapper = styled.div`
  ${tw`font-primary text-base font-bold`}
`

export const AdditionalHelpBoxFlexWrapper = styled.div<AdditionalHelpBoxProps>`
  ${tw`flex flex-col w-full gap-4 bg-white rounded-[21px]`}
  ${(props) => (props.className ? tw`${props.className}` : tw`p-4`)}
`
