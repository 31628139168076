import { Tool } from '__generated__/api-types-and-hooks'
import React, { FC } from 'react'
import Tags, { TagsText } from './Tags/Tags.component'
import StarIcon from 'components/Common/SvgIcons/StartIcon'

interface IToolsLabelProps {
  tool: Tool
  isToolDisconnecting: boolean
}
const ToolsLabel: FC<IToolsLabelProps> = ({ tool, isToolDisconnecting }) => {
  return (
    <div className={`${isToolDisconnecting ? 'pt-0' : 'pt-7'}`}>
      <div className="flex items-center">
        <p className="text-lg text-primary-text leading-6 font-bold inline-flex pr-3">
          {tool.toolDisplayName ?? tool.toolName}
        </p>
        <div className="inline-flex flex-row gap-x-2">
          {tool.tags &&
            tool.tags.map((tag) => (
              <Tags key={tag} tagText={tag} Icon={tag === TagsText.AI ? StarIcon : undefined}>
                {tag}
              </Tags>
            ))}
        </div>
      </div>

      <p className="text-sm text-primary-text font-medium mt-2 mb-3 line-clamp-5">
        {tool.description || ''}
      </p>
    </div>
  )
}

export default ToolsLabel
