import React from 'react'
import { FC } from 'react'

import PillTextBox, { PillColorTheme } from 'components/Common/PillTextBox'
import { AppSettings } from 'components/AppTools/AppToolsPanel/AppToolBlock/AppSettings/AppSettings.component'

interface IAppPillBoxAndSetting {
  isToolDisconnecting: boolean
  isConnected?: boolean
  handleDisconnect: () => void
}

export const AppPillBoxAndSetting: FC<IAppPillBoxAndSetting> = ({
  isToolDisconnecting,
  isConnected,
  handleDisconnect,
}) => {
  return (
    <div className="flex flex-row gap-4">
      {!isToolDisconnecting && (
        <PillTextBox
          colorTheme={isConnected ? PillColorTheme.SUCCESS : PillColorTheme.ERROR}
          size="small"
        >
          {!isConnected && 'Not '}Connected
        </PillTextBox>
      )}
      {isConnected && (
        <div>
          <AppSettings handleDisconnect={handleDisconnect} disabled={!isConnected} />
        </div>
      )}
    </div>
  )
}
