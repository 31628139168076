import { Tool, ToolSupportLevel } from '__generated__/api-types-and-hooks'
import Button, { ButtonVariantType } from 'components/Common/Button'
import React, { FC } from 'react'
import { onLaunchTool } from 'utils/helper'
import { ExtendedTools } from 'types'
import { IGetOAuthUrlAction } from 'types'

interface IToolsButtonProps {
  isConnected: boolean
  ExternalLinkLaunch: string
  tool: ExtendedTools
  setActiveConnectingTool?: (param: Tool) => void
  getOAuthUrlAction: (payload: IGetOAuthUrlAction) => void
  buttonType?: ButtonVariantType
  isNewTools?: boolean
  targetOrigin?: string
  readonly?: boolean
}

const ToolsButton: FC<IToolsButtonProps> = ({
  isConnected,
  ExternalLinkLaunch,
  tool,
  setActiveConnectingTool,
  getOAuthUrlAction,
  buttonType = 'secondary',
  isNewTools = false,
  targetOrigin,
  readonly,
}) => {
  const isToolConnectable = tool.supportLevel && tool.supportLevel !== ToolSupportLevel.Open
  const connectTools = () => {
    if (!tool.options?.connectionForm) {
      getOAuthUrlAction({
        calendar: tool.toolName ?? '',
        targetOrigin: targetOrigin ?? window.location.href,
        connectorName: tool.toolName ?? '',
        authType: tool.authType,
      })
    }
    setActiveConnectingTool && setActiveConnectingTool(tool)
  }

  return (
    <>
      <div className="flex items-center justify-between gap-2 mt-auto flex-row relative">
        {isConnected && isToolConnectable ? (
          <Button
            icon={ExternalLinkLaunch}
            label={'Launch'}
            variant={buttonType}
            onClick={() => onLaunchTool(tool)}
            size="small"
            disabled={readonly}
          />
        ) : (
          <Button
            icon={ExternalLinkLaunch}
            label={`${isToolConnectable ? 'Connect' : isNewTools ? 'Launch' : 'Open'} `}
            variant={buttonType}
            size="small"
            disabled={readonly}
            onClick={() => {
              isToolConnectable ? connectTools() : onLaunchTool(tool)
            }}
          />
        )}

        <div className="relative">
          <Button
            variant="text"
            label="Learn More"
            className="!border-0 !text-black-light"
            onClick={() => tool.pricingPageUrl && window.open(tool.pricingPageUrl, '_blank')}
          />
        </div>
      </div>
    </>
  )
}

export default ToolsButton
