import styled from 'styled-components'
import tw from 'twin.macro'
interface AppButtonWrapperProps {
  isFullWidth: boolean
}

export const AppButtonWrapper = styled.div.attrs<AppButtonWrapperProps>(({ isFullWidth }) => ({
  className: `inline !bg-transparent ${isFullWidth ? 'w-full' : ''}`,
}))<AppButtonWrapperProps>`
  & {
    .button {
      ${tw`inline-flex w-full items-center justify-center gap-2 overflow-hidden relative`}
    }

    .disabled {
      ${tw`cursor-not-allowed`}
    }

    // Primary states
    .button.primary.default {
      ${tw`bg-app-primary-60 `}
    }
    .button.primary.default: hover {
      ${tw`bg-app-primary-70 `}
    }
    .button.primary.default: focus {
      ${tw`bg-app-primary-60 appShadow-focus`}
    }

    // Alert states
    .button.alert.default {
      ${tw`bg-app-red-60`}
    }
    .button.alert.default: hover {
      ${tw`bg-app-red-50`}
    }
    .button.alert.default: focus {
      ${tw`bg-app-red-50 appShadow-focusRed`}
    }

    // Success states
    .button.success.default {
      ${tw`bg-app-green-30`}
    }
    .button.success.default: hover {
      ${tw`bg-app-green-40 `}
    }
    .button.success.default: focus {
      ${tw`bg-app-green-30 appShadow-focusGreen`}
    }

    // Tertiary States
    .button.tertiary.default {
      ${tw`bg-app-grey-30`}
    }
    .button.tertiary.default: hover {
      ${tw`bg-app-grey-40`}
    }
    .button.tertiary.default: focus {
      ${tw`bg-app-grey-30  appShadow-focus`}
    }

    // Secondary states
    .button.secondary {
      ${tw`border border-app-grey-40 appShadow-s`}
    }
    .button.secondary.default {
      ${tw`bg-app-white-100`}
    }
    .button.secondary.default: hover {
      ${tw`bg-app-grey-30`}
    }
    .button.secondary.default: focus {
      ${tw`bg-app-white-100  appShadow-focus`}
    }

    //Clear states
    .button.clear.default {
      ${tw`bg-app-white-100 border-transparent`}
    }
    .button.clear.default: hover {
      ${tw`bg-app-grey-30`}
    }
    .button.clear.default: focus {
      ${tw`bg-app-white-100 appShadow-focus`}
    }

    .button.lg,
    .button.sm,
    .button.md {
      ${tw`rounded-lg`}
    }

    .button.xs {
      ${tw`rounded-md px-2 py-0.5`}
    }

    .button.sm.on {
      ${tw`px-3 py-1.5`}
    }

    .button.sm.off {
      ${tw`p-2`}
    }

    .button.lg.on {
      ${tw`px-5 py-3.5`}
    }
    .button.lg.off {
      ${tw`p-4`}
    }
    .button.md.on {
      ${tw`px-4 py-2.5`}
    }
    .button.md.off {
      ${tw`p-3`}
    }

    .button.primary.disabled {
      ${tw`bg-app-primary-30`}
    }
    ,
    .button.success.disabled {
      ${tw`bg-app-green-30`}
    }
    ,
    .button.alert.disabled {
      ${tw`bg-app-red-30`}
    }

    .button.secondary.disabled {
      ${tw`bg-app-white-100 border-app-grey-40`}
    }
    .button.tertiary.disabled {
      ${tw`bg-app-grey-30`}
    }
    .button.clear.disabled {
      ${tw`bg-app-white-100`}
    }
  }
`

export const AppStyledButtonLabel = styled.div<{
  disabled?: boolean
  variant?: string
}>`
  ${tw`
    w-fit
    relative 
    mt-[-1px] 
    whitespace-nowrap
    text-app-body-medium-font-size 
    font-app-body-medium-font-family 
    font-app-body-medium-font-weight
    leading-app-body-medium-line-height
    tracking-app-body-medium-letter-spacing`}

  ${({ variant, disabled }) =>
    variant === 'secondary' || variant === 'clear' || variant === 'tertiary'
      ? !disabled
        ? tw`text-app-black-100`
        : tw`text-app-grey-50`
      : tw`text-app-white-100`}
`
