import {
  ChatBotMessageTypes,
  OnboardingFlowStepStatus,
  OnboardingFlowStepTypes,
  Tool,
  useGetAllToolsQuery,
  useSendMessageToBotMutation,
} from '__generated__/api-types-and-hooks'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import {
  AppDynamicChatMessage,
  TextComponent,
  TypingMessage,
  UIComponentProps,
} from 'components/AppOnboarding/AppChatbot/AppDynamicChatMessage/AppDynamicChatMessage.component'
import { AppTypingIndicator } from 'components/AppOnboarding/AppChatbot/AppTypingIndicator/AppTypingIndicator.component'
import { useInfiniteGetMessagesFromBotQuery } from '__generated__/infinite-api-types-and-hooks'
import { LoadingIndicator } from 'stream-chat-react'
import CircleNotchIcon from 'components/Common/SvgIcons/CircleNotchIcon'
import { useFlashParams } from '../../../../hooks/useFlashParams'
import { getTenantId } from 'utils/getTenantId'
import { AppPaperClipIcon } from 'components/Common/AppSvgIcons/AppPaperClipIcon'
import { AppButton } from 'components/Common/AppButton/AppButton.component'
import { AppSendMessageIcon } from 'components/Common/AppSvgIcons/AppSendMessageIcon'
import Avatar from 'appAssets/appImages/Avatar.png'
import { AppModalBox } from 'components/Common/AppModalBox/AppModalBox.component'
import { AppFileUploadField } from 'components/Common/AppFileUploadField/AppFileUploadField.component'
import moment from 'moment-timezone'
import { AppAvatar } from 'components/Common/AppAvatar/AppAvatar.component'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import { helpDesk } from 'utils/helper'
import { findLast } from 'lodash'

interface OnboardingStepMessageResponse {
  stepType?: OnboardingFlowStepTypes
  stepStatus?: OnboardingFlowStepStatus
  content?: UIComponentProps
  progress?: number
}

export interface AppAIChatPopupProps {
  isOpen: boolean
  onClose: () => void
  initialMessage?: string
  sessionId?: string
  uploadedFileId?: string
  uploadFileLoading?: boolean
  logo?: string
  userInitial?: string
  refetchBusinessGoal: () => void
  uploadFileAction?: (options: { files: FileList; isMultiple: boolean; saveFile: boolean }) => void
  title: string | React.ReactNode
  width?: string
}

export const AppAIChatPopup = ({
  isOpen,
  onClose,
  initialMessage,
  sessionId,
  uploadFileAction,
  uploadedFileId,
  uploadFileLoading,
  logo,
  userInitial,
  refetchBusinessGoal,
  title,
  width = 'w-[600px]',
}: AppAIChatPopupProps) => {
  const [messages, setMessages] = useState<
    {
      sender: string
      text?: string
      content?: UIComponentProps
      stepType?: OnboardingFlowStepTypes
      stepStatus?: OnboardingFlowStepStatus
      createdAt?: string
    }[]
  >([])
  const [queuedMessages, setQueuedMessages] = useState<OnboardingStepMessageResponse[]>([])
  const [inputValue, setInputValue] = useState(initialMessage)
  const [currentStep, setCurrentStep] = useState<{
    stepType?: OnboardingFlowStepTypes
    stepStatus?: OnboardingFlowStepStatus
  }>({})
  const [processing, setProcessing] = useState(false)
  const chatContainerRef = useRef<HTMLDivElement>(null)
  const [disableButton, setDisableButton] = useState(false)
  const [isUserScrolling, setIsUserScrolling] = useState(false)
  const [isUpload, setIsUpload] = useState(false)
  const [uploadedFile, setUploadedFile] = useState<File>()
  const [startUpload, setStartUpload] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [isRetrying, setIsRetrying] = useState(false)
  const { data: toolsData, refetch } = useGetAllToolsQuery(
    {
      tenantId: getTenantId(),
    },
    {
      refetchOnWindowFocus: false,
    }
  )
  const tools = (toolsData?.getAllTools?.data as Tool[]) || []
  
  // Fetch the messages with pagination support
  const {
    data: messageHistoryData,
    fetchNextPage,
    hasNextPage,
    isLoading: isMessagesLoading,
    isFetchingNextPage,
    isFetching,
    refetch: refetchMessages,
  } = useInfiniteGetMessagesFromBotQuery(
    {
      sessionId: sessionId ?? '',
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.getMessagesFromBot.nextCursor
          ? { cursor: lastPage.getMessagesFromBot.nextCursor }
          : undefined
      },
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
    }
  )
  const { removeFlashParams } = useFlashParams<{
    connectorName: string
    toolId: string
  }>()

  const convertMessage = (message: {
    __typename?: 'ChatBotMessage'
    role: string
    content: string
    createdAt: string
  }) => {
    if (message?.content?.startsWith('{')) {
      const content = JSON.parse(message.content)
      return { sender: message.role ?? '', content, createdAt: message.createdAt ?? '' }
    }
    return {
      sender: message.role ?? '',
      text: message.content ?? '',
      createdAt: message.createdAt ?? '',
    }
  }

  const isExistingMessage = (message) => {
    const isExisting = messages.find(
      (msg) =>
        msg.createdAt === message.createdAt &&
        (msg.text === message.text ||
          JSON.stringify(msg.content) === JSON.stringify(message.content))
    )
    return !isExisting
  }
  const prevSessionIdRef = useRef<string | undefined>()
  useEffect(() => {
    if (prevSessionIdRef.current !== sessionId) {
      // If the sessionId has changed, reset messages and refetch
      setMessages([]) // Clear previous messages
      prevSessionIdRef.current = sessionId
    }
  }, [sessionId])

  const addMessagesFromLatestPage = useCallback(
    (
      pageMessages: {
        __typename?: 'ChatBotMessage'
        role: string
        content: string
        createdAt: string
      }[]
    ) => {
      const newMessages = pageMessages
        .map((message) => convertMessage(message))
        .reverse()
        .filter(Boolean)
      // Avoid adding duplicate messages by checking their createdAt and content
      setMessages((prevMessagesArray) => {
        const existingMessages = new Set(
          prevMessagesArray.map(
            (msg) => `${msg.createdAt}-${msg.text ?? JSON.stringify(msg.content)}`
          )
        )
        const oldMessages =
          newMessages?.filter((msg) => {
            const key = `${msg?.createdAt}-${msg?.text ?? JSON.stringify(msg?.content)}`
            return !existingMessages.has(key)
          }) ?? []
        return [...oldMessages, ...prevMessagesArray]
      })
    },
    []
  )

  useEffect(() => {
    if (!messageHistoryData || isRetrying) return

    const pageMessages = messageHistoryData.pages ?? []
    const lastPageMessages =
      pageMessages[pageMessages.length - 1]?.getMessagesFromBot.messages ?? []

    if (!messages.length && pageMessages.length > 1) {
      pageMessages.forEach((page) => {
        addMessagesFromLatestPage(page.getMessagesFromBot.messages ?? [])
      })
      return
    }
    if (lastPageMessages.length) {
      addMessagesFromLatestPage(lastPageMessages)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageHistoryData, sessionId, addMessagesFromLatestPage, messages.length])
  const {
    mutate: sendMessageToBot,
    data: latestResponse,
    isLoading: isSendingMessageTobot,
  } = useSendMessageToBotMutation({
    onError: async (error) => {
      if (error?.message === 'Execution timed out.') {
        const newMessage = 'There was a delay in processing. Please wait a moment...'
        addMessage({ sender: 'assistant', text: newMessage })
        setProcessing(true)
        scrollToBottom(true)
        setIsRetrying(true)

        // Try to fetch new messages for up to 30 seconds
        const intervalId = setInterval(async () => {
          const result = await refetchMessages({
            refetchPage: (_, index) => index === 0,
          })

          if (!result.data) return

          const lastMessage = result.data.pages[0]?.getMessagesFromBot?.messages?.[0]
          const lastCurrentMessage = messages[messages.length - 1]
          // If we found a new message from the bot
          if (
            lastMessage?.role === 'assistant' &&
            lastMessage?.createdAt !== lastCurrentMessage?.createdAt
          ) {
            setProcessing(false)
            const lastPageMessages = result.data.pages[0]?.getMessagesFromBot?.messages ?? []
            const differentMessages = lastPageMessages.filter(
              (message) =>
                !messages.find((m) => m.createdAt === message.createdAt) &&
                message.role === 'assistant'
            )
            // Add the new messages using the existing function
            const convertedMessages = differentMessages
              .map((message) => convertMessage(message))
              .filter((message) => isExistingMessage(message))
              .reverse()
            setMessages((prevMessages) => [...prevMessages, ...convertedMessages])
            const lastMessage = convertedMessages[0]
            if (lastMessage?.content?.type !== OnboardingFlowStepTypes.Message) {
              clearInterval(intervalId)
              setIsRetrying(false)
            }
            scrollToBottom(true)
          }
        }, 5000)
      }
    },
  })

  const handleNewMessage = (message: OnboardingStepMessageResponse) => {
    setCurrentStep(message)
    if ((message?.content as TextComponent)?.text?.toLowerCase().includes('goal center')) {
      refetchBusinessGoal()
    }
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: 'assistant', content: message.content },
    ])
    setIsUserScrolling(false)
    setProcessing(false)
  }

  useEffect(() => {
    if (latestResponse?.sendMessageToBot.responses?.length && !isRetrying) {
      const firstResponse = JSON.parse(
        latestResponse?.sendMessageToBot.responses[0]
      ) as OnboardingStepMessageResponse
      handleNewMessage(firstResponse)
      setQueuedMessages((prevMessages) => {
        const newMessages = latestResponse?.sendMessageToBot
          .responses!.slice(1)
          .map((response) => JSON.parse(response) as OnboardingStepMessageResponse)
        return [...prevMessages, ...newMessages]
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestResponse?.sendMessageToBot.responses])

  useEffect(() => {
    const lastMessage = messages[messages.length - 1]
    const lastAssistantMessage = findLast(messages, (message) => message.sender === 'assistant')
    if (lastMessage?.sender === 'user') {
      scrollToBottom(true)
    }
    if (lastAssistantMessage?.content?.type === OnboardingFlowStepTypes.End) {
      onClose() // Close the popup when flow ends
    }
    if (lastAssistantMessage?.content?.type === OnboardingFlowStepTypes.ToolConnection) {
      const content = lastAssistantMessage.content as { tools: Tool[] }
      const toolConnections = content.tools
      const connectedTools = toolConnections.filter((tool) =>
        tools.find((t) => t.id === tool.id && t.isConnected)
      )
      if (toolConnections.length === connectedTools.length && !isSendingMessageTobot) {
        setProcessing(true)
        sendMessageToBot(
          {
            input: {
              userMessage: JSON.stringify({ toolIds: connectedTools.map((tool) => tool.id) }),
              userMessageType: ChatBotMessageTypes.ToolConnected,
              sessionId,
            },
          },
          {
            onError: (error) => {
              if (error.statusCode === 401) {
                window.location.reload()
              }
            },
          }
        )
        removeFlashParams(['toolId'])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, tools])

  const submitDataConfirmationForm = async (formData) => {
    const filesFields = ['businessPlanFile']
    const filesToUpload = filesFields.filter((field) => formData[field])

    if (filesToUpload.length > 0) {
      filesToUpload.forEach((field) => {
        uploadFileAction?.({
          files: [formData[field]] as unknown as FileList,
          isMultiple: false,
          saveFile: true,
        })
      })
      return
    }
    setProcessing(true)
    const { id, ...data } = formData
    sendMessageToBot(
      {
        input: {
          userMessage: JSON.stringify(data),
          userMessageType: ChatBotMessageTypes.FormResponse,
          ...(formData.skip ? { skip: true } : {}),
          sessionId,
        },
      },
      {
        onError: (error) => {
          if (error.statusCode === 401) {
            window.location.reload()
          }
        },
      }
    )
  }

  const handleSendMessage = useCallback(async () => {
    const pageMessages = messageHistoryData?.pages ?? []
    const page = pageMessages.length
      ? pageMessages[pageMessages.length - 1]
      : { getMessagesFromBot: { messages: [] } }
    const lastPageMessages = page.getMessagesFromBot.messages ?? []
    if (!inputValue && lastPageMessages.length) return
    setProcessing(true)
    const userMessage = { sender: 'user', text: inputValue }
    addMessage(userMessage)
    setInputValue('')
    sendMessageToBot(
      {
        input: {
          userMessage: inputValue ?? '',
          sessionId,
          userMessageType: ChatBotMessageTypes.Text,
        },
      },
      {
        onError: (error) => {
          if (error.statusCode === 401) {
            window.location.reload()
          }
        },
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, messages])

  useEffect(() => {
    if (isMessagesLoading || isFetchingNextPage || isFetching) {
      return
    }
    const pageMessages = messageHistoryData?.pages ?? []
    const lastPageMessages =
      pageMessages[pageMessages.length - 1]?.getMessagesFromBot.messages ?? []
    if (messages.length || lastPageMessages.length) {
      return
    }
    if (!messages.length && !lastPageMessages.length && isOpen) {
      handleSendMessage()
    }
  }, [
    handleSendMessage,
    isFetching,
    isFetchingNextPage,
    isMessagesLoading,
    messageHistoryData?.pages,
    messages.length,
    isOpen
  ])

  useEffect(() => {
    if (!uploadFileLoading && uploadedFileId) {
      submitDataConfirmationForm({ businessPlan: uploadedFileId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFileLoading, uploadedFileId])

  const scrollToBottom = (force?: boolean) => {
    if (!chatContainerRef.current) return
    if (!isUserScrolling || force) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }
  
  const scrollTopMessage = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollTop + 250
    }
  }

  const handleUserScroll = () => {
    if (chatContainerRef.current) {
      const isAtBottom =
        chatContainerRef.current.scrollHeight - chatContainerRef.current.scrollTop ===
        chatContainerRef.current.clientHeight
      setIsUserScrolling(!isAtBottom)

      // Trigger loading more messages when the user scrolls to the top
      if (chatContainerRef.current.scrollTop === 0 && hasNextPage) {
        fetchNextPage()
      }
    }
  }

  const handleTypingMessage = (text: string, displayText: string) => {
    if (text === displayText) {
      setDisableButton(false)
      if (queuedMessages.length && text) {
        const firstQueueMessage = queuedMessages.shift()
        handleNewMessage(firstQueueMessage!)
        setQueuedMessages(queuedMessages)
        return
      }
    } else {
      if (text?.length) {
        setDisableButton(true)
      }
    }
    if (displayText.length) {
      scrollToBottom()
    } else {
      scrollTopMessage()
    }
  }

  const addMessage = (newMessage) => {
    setMessages((prevMessages) => [...prevMessages, newMessage])
    setIsUserScrolling(false)
  }

  const lastMessageContent = messages[messages.length - 1]?.content
  const isFormStep =
    currentStep.stepType === OnboardingFlowStepTypes.Form ||
    (lastMessageContent?.type && lastMessageContent.type === OnboardingFlowStepTypes.Form)
  const isToolConnectionStep =
    currentStep.stepType === OnboardingFlowStepTypes.ToolConnection ||
    (lastMessageContent?.type && lastMessageContent.type === OnboardingFlowStepTypes.ToolConnection)

  if (!isOpen) return null

  return (
    <AppModalBox
      title={title}
      onClose={onClose}
      width={width}
      backgroundColor="bg-app-grey-5"
      height="h-auto"
      position="center"
      childClass="p-0"
      rounded="xl"
      showBorder={false}
    >
      <div className="flex flex-col gap-4 w-full rounded-lg bg-app-grey-5">
        <div className="p-2 sm:p-4 pb-0 bg-app-grey-5">
          <div className="w-full bg-app-grey-5 font-normal font-inter text-center text-sm text-grey-lighter6x">
            {`Today, ${moment().format('hh:mm A')}`}
          </div>
        </div>
        <div
          className="flex flex-col w-full p-2 sm:p-4 pt-0 overflow-y-scroll h-[400px] gap-8"
          ref={chatContainerRef}
          onScroll={handleUserScroll}
        >
          {isFetchingNextPage && (
            <div className="flex justify-center p-1">
              <LoadingIndicator size={20} />
            </div>
          )}
          {messages.map((message, index) => {
            const isLastMessage = index === messages.length - 1
            if (message.text) {
              return (
                <div
                  key={index}
                  className={`flex gap-2 ${
                    message.sender === 'user' ? 'justify-end' : 'justify-start'
                  }`}
                >
                  <div className={`${message.sender === 'user' ? 'order-2' : ''}`}>
                    <AppAvatar
                      size={'md'}
                      type={!logo && message.sender === 'user' ? 'text' : 'image'}
                      shape={'Rounded'}
                      text={userInitial}
                      color="Primary"
                      src={message.sender === 'user' ? logo : Avatar}
                    />
                  </div>
                  <div
                    className={`w-fit max-w-[90%] rounded-lg px-3 pt-2 pb-1.5 text-base text-black-appDark !font-inter ${
                      message.sender === 'assistant'
                        ? 'bg-background-appLight text-black'
                        : 'bg-primary-appBrand text-white'
                    }`}
                  >
                    {message.sender === 'assistant' && message.text && isLastMessage ? (
                      <TypingMessage text={message.text} onNewCharacter={handleTypingMessage} />
                    ) : message.sender === 'assistant' ? (
                      <div className="chatbot-markdown">
                        <Markdown
                          rehypePlugins={[rehypeRaw] as any}
                          remarkPlugins={[remarkGfm, remarkMath]}
                        >
                          {message.text}
                        </Markdown>
                      </div>
                    ) : (
                      message.text
                    )}
                  </div>
                </div>
              )
            }
            if (message.content) {
              return (
                <div
                  key={index}
                  className={`flex gap-2 ${
                    message.sender === 'user' ? 'justify-end' : 'justify-start'
                  }`}
                >
                  <div className={`${message.sender === 'user' ? 'order-2' : ''}`}>
                    <AppAvatar
                      size={'md'}
                      type={!logo && message.sender === 'user' ? 'text' : 'image'}
                      shape={'Rounded'}
                      text={userInitial}
                      color="Primary"
                      src={message.sender === 'user' ? logo : Avatar}
                    />
                  </div>
                  <AppDynamicChatMessage
                    key={index}
                    content={{ ...message.content }}
                    onSubmit={submitDataConfirmationForm}
                    typingAnimationCallback={handleTypingMessage}
                    useTypingAnimation={index === messages.length - 1}
                    isLastMessage={isLastMessage}
                    isSendingMessageTobot={isSendingMessageTobot}
                    tools={tools}
                    setIsUpload={setIsUpload}
                    showUploadModal={showUploadModal}
                    isUpload={isUpload}
                    uploadedFile={uploadedFile}
                    setUploadedFile={setUploadedFile}
                    startUpload={startUpload}
                    setStartUpload={setStartUpload}
                    scrollToBottom={scrollToBottom}
                    refetchBusinessGoal={refetchBusinessGoal}
                    refetchTool={refetch}
                    addMessage={addMessage}
                  />
                </div>
              )
            }
            return <></>
          })}
          <div className="text-center w-full bg-app-grey-5 font-normal font-inter text-xs text-primary-appBrand flex justify-center cursor-pointer">
            {!(processing || disableButton || !messages.length) && (
              <div className="chatbot-help py-1 px-2 hover:bg-blue" onClick={helpDesk}>
                Get Help?
              </div>
            )}
          </div>
          {processing ? <AppTypingIndicator /> : <></>}
        </div>
        <div className="p-4 sm:p-6 pt-0">
          <div className="flex flex-col rounded-lg px-4 py-3 border border-primary-appDarkBorder">
            <textarea
              disabled={processing || disableButton || isFormStep || isToolConnectionStep}
              rows={2}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Message Coach Logic..."
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSendMessage()
                }
              }}
              className="w-full resize-none font-inter text-base text-black-appDark leading-5 bg-transparent border-none focus-visible:ring-0 focus-visible:outline-none"
            />
            <div className="flex justify-between relative w-full">
              <div className="flex gap-2 items-center">
                <AppButton
                  variant="clear"
                  size="sm"
                  RightIcon={AppPaperClipIcon}
                  disabled={
                    !isUpload ||
                    processing ||
                    isSendingMessageTobot ||
                    startUpload ||
                    isToolConnectionStep
                  }
                  onClick={() => setShowUploadModal(!showUploadModal)}
                />
              </div>

              {processing ? (
                <CircleNotchIcon className={'fill-primary h-[30px]'} />
              ) : (
                <AppButton
                  variant="primary"
                  size="sm"
                  RightIcon={AppSendMessageIcon}
                  disabled={processing || disableButton || inputValue?.trim() === ''}
                  onClick={() => handleSendMessage()}
                />
              )}
            </div>
          </div>
        </div>
        {showUploadModal && (
          <AppModalBox title={'Upload File'} onClose={() => setShowUploadModal(false)}>
            <div className="m-10">
              <AppFileUploadField
                name="imageData"
                className="flex-1 "
                labelFontSize="base"
                onChange={(e) => {
                  setUploadedFile(e[0])
                }}
              />
              <div className="mt-20 w-[30%]">
                <AppButton
                  variant="primary"
                  size="sm"
                  label="Upload"
                  disabled={!uploadedFile}
                  onClick={() => {
                    setStartUpload(true)
                    setShowUploadModal(false)
                  }}
                />
              </div>
            </div>
          </AppModalBox>
        )}
      </div>
    </AppModalBox>
  )
} 