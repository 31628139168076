import React, { Dispatch, SetStateAction } from 'react'
import { FieldValues, UseFormReturn, UseFormSetValue } from 'react-hook-form'

import {
  InformationGrid,
  FieldLabel,
  UserName,
} from 'components/AppKanbanBoard/AppTask/AppTask.style'
import { AppTaskDateFields } from 'components/AppKanbanBoard/AppTask/AppTaskFields/AppTaskDateFields/AppTaskDateFields.component'
import { BusinessUserType, UserTask } from '__generated__/api-types-and-hooks'
import { AppHourEstimationField } from 'components/AppKanbanBoard/AppTask/AppTaskFields/AppHourEstimationField/AppHourEstimationField.component'
import { AppTaskNameField } from 'components/AppKanbanBoard/AppTask/AppTaskFields/AppTaskNameField/AppTaskNameField.component'
import { AppTaskStatusField } from 'components/AppKanbanBoard/AppTask/AppTaskFields/AppTaskStatusField/AppTaskStatusField.component'
import { AppTaskPriorityField } from 'components/AppKanbanBoard/AppTask/AppTaskFields/AppTaskPriorityField/AppTaskPriorityField.component'
import { AppTaskCategoryField } from './AppTaskCategoryField/AppTaskCategoryField.component'
import { AppTaskAssigneeField } from './AppTaskAssigneeField/AppTaskAssigneeField.component'
import { TaskFormFields } from 'components/AppKanbanBoard/config'
import { AppTaskGoalField } from './AppTaskGoalField/AppTaskGoalField.component'
import Avatar from 'appAssets/appImages/Avatar.png'
import { AppAvatar } from 'components/Common/AppAvatar/AppAvatar.component'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import { IAssigneeOptions } from 'appHooks/useGetBusinessProfile'

interface AppTaskFieldsProps {
  methods: UseFormReturn
  task?: UserTask
  userId: string
  logo?: string
  advisorsData: Array<{ label: any; value: any }>
  clickedFields: { [key: string]: boolean }
  handleClick: (field: string, isHovered: boolean) => void
  isViewMode: boolean
  userName?: string
  noneEditableFields?: string[]
  setValue: UseFormSetValue<FieldValues>
  setGoalExpectedEndDate: Dispatch<SetStateAction<string>>
  planStartDate?: React.MutableRefObject<string | undefined>
  goalId?: string
  userAvatar?: string
  userFirstName?: string
  userLastName?: string
  isStartDateBeforePlanStartDate?: boolean
  assigneeOptions?: IAssigneeOptions[]
  paramUserId?: string
}

const extractFullNameDetails = (name: string | null | undefined) => {
  if (!name)
    return {
      firstName: '',
      lastName: '',
    }
  const nameParts = name.split(' ')
  return {
    firstName: nameParts[0],
    lastName: nameParts[1],
  }
}

const createDefaultAssigneeOptions = (
  advisor: { label: any; value: any } | undefined,
  task: UserTask | undefined
) => {
  return [
    {
      label: advisor?.label,
      value: advisor?.value,
      type: BusinessUserType.Member,
      user: {
        id: advisor?.value,
        ...extractFullNameDetails(advisor?.label),
      },
    },
    {
      label: task?.createdBy,
      value: task?.createdById,
      type: BusinessUserType.Member,
      user: {
        id: task?.createdById,
        ...extractFullNameDetails(task?.createdBy),
      },
    },
  ].filter(
    (assignee, index, self) =>
      // Remove duplicates based on value (user ID)
      index === self.findIndex((a) => a.value === assignee.value)
  )
}

export const AppTaskFields: React.FC<AppTaskFieldsProps> = ({
  methods,
  task,
  logo,
  userId,
  clickedFields,
  advisorsData,
  handleClick,
  isViewMode,
  userName,
  noneEditableFields,
  setValue,
  setGoalExpectedEndDate,
  planStartDate,
  userAvatar,
  userFirstName,
  userLastName,
  isStartDateBeforePlanStartDate,
  assigneeOptions,
  paramUserId,
}) => {
  const user = useSelector((state: RootState) => state.user.user)
  const isAdvisor = user?.roles.includes('BSO_ADVISOR') || user?.roles.includes('BSO_ADMIN')

  const advisor = advisorsData.find((advisor) => advisor.value === user?.id)
  const shownUserName = isAdvisor ? advisor?.label : userName
  const creator =
    assigneeOptions?.find((creator) => creator.label === task?.createdBy)?.label ||
    task?.createdBy ||
    shownUserName
  const creatorInitials =
    creator
      ?.split(' ')
      .map((name) => name[0])
      .join('.') + '.'
  const currentAssignees = assigneeOptions || []
  const assignees =
    currentAssignees.length > 0 ? currentAssignees : createDefaultAssigneeOptions(advisor, task)
  return (
    <>
      <div className="flex flex-col gap-4">
        <AppTaskNameField
          methods={methods}
          clickedFields={clickedFields}
          handleClick={
            !noneEditableFields?.includes(TaskFormFields.name)
              ? handleClick
              : () => handleClick(TaskFormFields.name, false)
          }
        />
        <InformationGrid>
          <AppTaskGoalField
            setValue={setValue}
            methods={methods}
            clickedFields={clickedFields}
            handleClick={
              !noneEditableFields?.includes(TaskFormFields.goalId)
                ? handleClick
                : () => handleClick(TaskFormFields.goalId, false)
            }
            setGoalExpectedEndDate={setGoalExpectedEndDate}
            planStartDate={planStartDate}
            paramUserId={paramUserId}
          />
          <AppTaskStatusField
            methods={methods}
            clickedFields={clickedFields}
            handleClick={
              !noneEditableFields?.includes(TaskFormFields.status)
                ? handleClick
                : () => handleClick(TaskFormFields.status, false)
            }
          />
          <AppHourEstimationField
            methods={methods}
            clickedFields={clickedFields}
            handleClick={
              !noneEditableFields?.includes(TaskFormFields.estimatedEffortInMinutes)
                ? handleClick
                : () => handleClick(TaskFormFields.estimatedEffortInMinutes, false)
            }
          />
          <AppTaskPriorityField
            methods={methods}
            clickedFields={clickedFields}
            handleClick={
              !noneEditableFields?.includes(TaskFormFields.taskPriority)
                ? handleClick
                : () => handleClick(TaskFormFields.taskPriority, false)
            }
          />
          <AppTaskAssigneeField
            methods={methods}
            clickedFields={clickedFields}
            handleClick={
              !noneEditableFields?.includes(TaskFormFields.assigneeId)
                ? handleClick
                : () => handleClick(TaskFormFields.assigneeId, false)
            }
            userAvatar={userAvatar}
            // assigneeOptionsParam={assigneeOptions?.length ? assigneeOptions : defaultAssignee}
            assigneeOptionsParam={assignees}
          />
          <FieldLabel>Creator </FieldLabel>
          <div className="col-span-7 font-normal text-black-dark">
            <div className="flex justify-left gap-3 items-center">
              {!task?.createdBy ||
              task?.createdBy === 'Assistant' ||
              task?.createdBy === 'GoTackle' ? (
                <AppAvatar src={Avatar} size={'sm'} type={'image'} shape={'Rounded'} />
              ) : (
                <AppAvatar size={'sm'} type={'mixed'} shape={'Rounded'} text={creatorInitials} />
              )}
              <UserName>
                {isViewMode
                  ? !task?.createdBy || task?.createdBy === 'GoTackle'
                    ? 'Coach Logic'
                    : creator
                  : creator}
              </UserName>
            </div>
          </div>
          <AppTaskDateFields
            methods={methods}
            clickedFields={clickedFields}
            handleClickStartDate={
              !noneEditableFields?.includes(TaskFormFields.startDate)
                ? handleClick
                : () => handleClick(TaskFormFields.startDate, false)
            }
            handleClickExpectedEndDate={
              !noneEditableFields?.includes(TaskFormFields.expectedEndDate)
                ? handleClick
                : () => handleClick(TaskFormFields.expectedEndDate, false)
            }
            isStartDateBeforePlanStartDate={isStartDateBeforePlanStartDate}
          />

          <AppTaskCategoryField
            methods={methods}
            clickedFields={clickedFields}
            handleClick={
              !noneEditableFields?.includes(TaskFormFields.category)
                ? handleClick
                : () => handleClick(TaskFormFields.category, false)
            }
          />
        </InformationGrid>
      </div>
    </>
  )
}
