import React, { FC, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import moment from 'moment-timezone'
import { useParams } from 'react-router-dom'
import {
  OnboardingFlowStepStatus,
  OnboardingFlowStepTypes,
  OnboardingFlowType,
  OnboardingStatus,
  Tool,
  useCreateFlowChatSessionMutation,
  useGetBusinessProfileQuery,
  useGetOptionsQuery,
  useUpdateMyProfileMutation,
} from '__generated__/api-types-and-hooks'

import { RootState } from 'App'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { getOAuthUrlAction } from 'store/actions/calendar'
import { stringify } from 'querystring'
import ImageUploadField from 'components/Common/ImageUploadField'
import { AppCheckBoxSelect } from 'components/Common/AppFormFields/AppCheckBoxSelect/AppCheckBoxSelect.component'
import { AppToolBlock } from 'components/AppOnboarding/AppChatbot/AppDynamicChatMessage/AppToolBlock/AppToolBlock.component'
import { AppButton } from 'components/Common/AppButton/AppButton.component'
import Label from 'components/Common/Label'
import redirectTo from 'utils/redirectTo'
import { getTenantId } from 'utils/getTenantId'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { isEmpty, isObject, isUndefined } from 'lodash'
import { ChatBotFlowUrl } from '../AppChatBotFlows/constants'
import { AppSelectField } from 'components/Common/AppFormFields/AppSelectField/AppSelectField.component'
import { AppTextField } from 'components/Common/AppFormFields/AppTextField/AppTextField.component'
import { autoCompleteMarkdown, getRoleFromPath } from 'utils/helper'
import { AppPicker } from 'components/Common/AppFormFields/AppPicker/AppPicker.component'
import { AppButtonSelect } from 'components/Common/AppFormFields/AppButtonSelect/AppButtonSelect.component'
import { AppNumericField } from 'components/Common/AppFormFields/AppNumericField/AppNumericField.component'
import { getUpdatedMessageState } from 'appUtils'
import { AppSmartGoalTemplate } from './AppSmartGoalTemplate/AppSmartGoalTemplate.component'
import { AppSmartGoalCard } from './AppSmartGoalCard/AppSmartGoalCard.component'

// Types for each possible component type
export type TextComponent = {
  type: 'text'
  text: string
}

type ToolListComponent = {
  type: OnboardingFlowStepTypes.ToolConnection
  tools: Tool[]
}

type FormField = {
  label: string
  name: string
  type: 'text' | 'email' | 'password' | 'number'
  placeholder?: string
  value?: string
  action?: string
  multiple: boolean
  fieldType:
    | 'select'
    | 'input'
    | 'file'
    | 'upload'
    | 'checkbox'
    | 'button'
    | 'date'
    | 'buttonSelect'
    | 'goalTemplate'
  defaultValue?: string
  options?: { label: string; value: string; noneOfTheAbove?: boolean }[]
  conditionalField?: {
    dependsOn: string // Name of the field that it depends on
    values: string[] // Array of values that trigger this field's visibility
    excludeValues?: string[] // Array of values that hide this field
  }
  validation?: {
    required?: { value: boolean; message: string }
    pattern?: { value: string; message: string }
    type?: 'string' | 'number' | 'float' | 'date'
    min?: { value: number; message: string }
    max?: { value: number; message: string }
    dateMin?: { value: string; message: string; dependsOn?: string; dependsOnMessage?: string }
    dateMax?: { value: string; message: string; dependsOn?: string; dependsOnMessage?: string }
  }
}
type MessageValue = { label: string; value: string[] }

export type FormComponent = {
  id: string
  type:
    | OnboardingFlowStepTypes.Form
    | OnboardingFlowStepTypes.ToolConnection
    | OnboardingFlowStepTypes.GoalTemplate
  formFields: FormField[]
  title: string
  defaultValue?: string
  description?: string
  onSubmit: (formData: Record<string, string>) => void
  status?: OnboardingFlowStepStatus
}

export type EndComponent = {
  type: OnboardingFlowStepTypes.End
}

// Function to generate the yup validation schema based on form fields
export const generateValidationSchema = (
  formFields: FormField[],
  shouldDisplayField: (field: FormField) => boolean
) => {
  return yup.object().shape(
    formFields.reduce((schema, field) => {
      let fieldSchema
      if (!shouldDisplayField(field)) {
        schema[field.name] = yup.mixed().notRequired()
        return schema
      }
      // Determine the initial field type and base schema
      switch (field.validation?.type) {
        case 'number':
          fieldSchema = yup.number().typeError(`Please enter a whole number (e.g., 10)`)
          break
        case 'float':
          fieldSchema = yup.number().typeError(`Please enter a valid decimal number (e.g., 12.34)`)
          break
        case 'date':
          fieldSchema = yup.date().typeError(`Please enter a valid date (e.g., 2022-12-31)`)
          break
        default:
          fieldSchema =
            field.validation?.required?.value === false
              ? yup.string().notRequired()
              : yup.string().required('Please enter a value')
      }

      if (field.fieldType !== 'input' && field.fieldType !== 'date') {
        if (field.fieldType === 'checkbox' && field.validation?.required?.value !== false) {
          if (field.multiple) {
            fieldSchema = yup
              .array()
              .required('Please select an option')
              .min(1, 'Please select an option')
          } else {
            fieldSchema = yup.array().of(yup.string()).required('Please select an option')
          }
        } else {
          fieldSchema = yup.mixed().notRequired()
        }
      }

      // Define a mapping of validation rules to yup methods
      const validationRules: Record<
        string,
        (rule: {
          value: any
          message: string
          field?: string
          dependsOn?: string
          dependsOnMessage?: string
        }) => yup.SchemaOf<any>
      > = {
        required: ({ value, message }) => (value ? fieldSchema.required(message) : fieldSchema),
        min: ({ value, message }) => fieldSchema.min(value, message),
        max: ({ value, message }) => fieldSchema.max(value, message),
        pattern: ({ value, message }) => fieldSchema.matches(new RegExp(value), message),
        dateMin: ({ value, message, dependsOn, dependsOnMessage }) => {
          const minDate = moment(value).local().startOf('day')
          return yup
            .date()
            .min(minDate.toDate(), message)
            .test('checkDateDependedOnValue', dependsOnMessage ?? '', function (value) {
              const data = this.parent // Get the parent object containing all field values
              if (dependsOn && data[dependsOn]) {
                // Ensure we're working with the start of the day for both dates
                const comparedDate = moment(data[dependsOn]).startOf('day')
                if (moment(value).isBefore(comparedDate)) {
                  return false
                }
              }

              return true
            })
        },
        dateMax: ({ value, message }) => {
          const maxDate = moment(value).local().endOf('day')
          return yup.date().max(maxDate.toDate(), message)
        },
      }

      // Iterate over validation rules dynamically
      for (const [key, validator] of Object.entries(validationRules)) {
        const rule = field.validation?.[key]
        if (rule) {
          fieldSchema = validator(rule)
        }
      }

      schema[field.name] = fieldSchema
      return schema
    }, {} as Record<string, yup.AnySchema>)
  )
}

// Union type for the main UI component props
export type UIComponentProps = TextComponent | ToolListComponent | FormComponent | EndComponent

export const BUTTON_ACTIONS = {
  REDIRECT_TO_GOAL_CENTER: 'REDIRECT_TO_GOAL_CENTER',
  REDIRECT_TO_GOAL_SETTING: 'REDIRECT_TO_GOAL_SETTING',
  REDIRECT_GOAL_SETTING_TO_GOAL_CENTER: 'REDIRECT_GOAL_SETTING_TO_GOAL_CENTER',
  SKIP: 'SKIP',
}

export const BUTTON_DISPLAY_TEXT = {
  REDIRECT_TO_GOAL_CENTER:
    'Thank you! Your personalized execution plans are being generated and will be available for you to review from the goal center',
  REDIRECT_GOAL_SETTING_TO_GOAL_CENTER:
    'Thank you! Your personalized execution plan for your medium-term goal is being generated and will be available for you to review from your Goal Center. If you want to add more goals, you will be able to do so, from the goal center.',
  REDIRECT_TO_GOAL_SETTING:
    'Thank you, it was great getting to know you and your business and I look forward to helping you scale faster. Ready to start tackling your goals?',
}

const useTypingAnimation = (text: string, speed: number) => {
  const [displayedText, setDisplayedText] = useState('')
  const indexRef = useRef(0)

  useEffect(() => {
    if (!text || text.length === 0) {
      console.log('The Bot encountered an error: ', text)
      return
    }

    const interval = setInterval(() => {
      setDisplayedText((prev) => prev + text[indexRef.current])
      indexRef.current++
      if (indexRef.current === text.length) {
        clearInterval(interval)
      }
    }, speed)
    return () => clearInterval(interval)
  }, [text, speed])

  return autoCompleteMarkdown(displayedText)
}

export const TypingMessage = ({
  text,
  onNewCharacter,
}: {
  text: string
  onNewCharacter: (text: string, displayedText: string) => void
}) => {
  const displayedText = useTypingAnimation(text, 10)

  useEffect(() => {
    onNewCharacter(text, displayedText)
  }, [displayedText, onNewCharacter, text])
  // Check if there is HTML in the text
  return (
    <div className="chatbot-markdown text-base text-black-appDark !font-inter [&_a]:text-app-blue-600 [&_a]:underline hover:[&_a]:text-app-blue-800">
      <Markdown rehypePlugins={[rehypeRaw] as any} remarkPlugins={[remarkGfm, remarkMath]}>
        {displayedText.replace(/<\/?code>/g, '***')}
      </Markdown>
    </div>
  )
  // comment for now
  // const containsHtml = /<\/?[a-z][\s\S]*>/i.test(text)
  // return !containsHtml ? (
  //   <div className="rounded-lg px-3 py-1.5 bg-background-appLight text-base text-black-appDark font-inter">
  //     {displayedText.replace(/<\/?code>/g, '***')}
  //   </div>
  // ) : (
  //   <div className="chatbot-markdown text-base text-black-appDark !font-inter">
  //     <Markdown rehypePlugins={[rehypeRaw] as any} remarkPlugins={[remarkGfm, remarkMath]}>
  //       {displayedText.replace(/<\/?code>/g, '***')}
  //     </Markdown>
  //   </div>
  // )
}

const dynamicChatMessageProps = (state: RootState) => {
  const { oAuthUrl } = state.calendar
  return {
    oAuthUrl,
  }
}

function dynamicChatMessageDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getOAuthUrlAction: getOAuthUrlAction.STARTED,
    },
    dispatch
  )
}

export const AppDynamicChatMessageConnector = connect(
  dynamicChatMessageProps,
  dynamicChatMessageDispatch
)

interface IDynamicChatMessageProps extends ConnectedProps<typeof AppDynamicChatMessageConnector> {
  content: UIComponentProps
  onSubmit: (formData: Record<string, string>) => void
  typingAnimationCallback: (text: string, displayedText: string) => void
  useTypingAnimation: boolean
  isLastMessage: boolean
  isSendingMessageTobot: boolean
  tools?: Tool[]
  setIsUpload?: (p: boolean) => void
  showUploadModal?: boolean
  isUpload?: boolean
  uploadedFile?: File
  setUploadedFile?: (p: File) => void
  startUpload?: boolean
  setStartUpload?: (p: boolean) => void
  scrollToBottom?: () => void
  refetchBusinessGoal?: () => void
  refetchTool?: () => void
  addMessage?: (p) => void
}

const renderMarkdownContent = (text: string) => {
  if (!text) return ''
  const markdownRegex = /^[\s\S]*?(?:[*#\d.-]\s|[*_~`]|(?:\d+\.)|(?:-\s)|\n\n)/
  const processedText = text.replace(/<\/?code>/g, '***')

  return markdownRegex.test(text) ? (
    <div className="chatbot-markdown">
      <Markdown rehypePlugins={[rehypeRaw as any]} remarkPlugins={[remarkGfm, remarkMath]}>
        {processedText}
      </Markdown>
    </div>
  ) : (
    processedText
  )
}

export const AppDynamicChatMessage = AppDynamicChatMessageConnector<FC<IDynamicChatMessageProps>>(
  ({
    content,
    onSubmit,
    typingAnimationCallback,
    getOAuthUrlAction,
    useTypingAnimation,
    isLastMessage,
    isSendingMessageTobot,
    tools,
    setIsUpload,
    showUploadModal,
    uploadedFile,
    isUpload,
    setUploadedFile,
    startUpload,
    setStartUpload,
    scrollToBottom,
    refetchBusinessGoal,
    refetchTool,
    addMessage,
  }) => {
    useEffect(() => {
      typingAnimationCallback('', '')
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [formData, setFormData] = useState<Record<string, string>>({})
    const [messageValue, setMessageValue] = useState<MessageValue[]>([])
    const { mutate: updateUserProfileMutation } = useUpdateMyProfileMutation()
    const userId = useSelector((state: RootState) => state.user.user.id)
    const { clientId } = useParams()
    const { data: businessProfileResponse } = useGetBusinessProfileQuery(
      {
        id: clientId || userId,
      },
      {
        refetchOnWindowFocus: false,
      }
    )
    const businessProfile = businessProfileResponse?.getBusinessProfile
    const businessStage = businessProfile?.businessStage || 'Pre-Launch'
    const { data: goalTemplatesResponse, isLoading: isLoadingGoalTemplates } = useGetOptionsQuery(
      {
        optionTypes: ['goalTemplate'],
      },
      {
        refetchOnWindowFocus: false,
      }
    )
    const options = goalTemplatesResponse?.getOptions?.data ?? []
    const filteredOptions = options.filter((option) => option?.categoryType === businessStage)
    const groupedOptionByCategoryAndCategoryType: Record<string, any[]> = {}
    filteredOptions.forEach((option: any) => {
      const key = `${option?.category}-${option?.categoryType}`
      if (!groupedOptionByCategoryAndCategoryType[key]) {
        groupedOptionByCategoryAndCategoryType[key] = []
      }
      groupedOptionByCategoryAndCategoryType[key].push(option)
    })
    //sort by alphabetical order
    const goalTemplates = Object.entries(groupedOptionByCategoryAndCategoryType)
      .map(([key, group]) => {
        const [category, goalCategoryType] = key.split('-')
        const goals = group
          .map((option: any) => {
            return {
              smartHeading: '',
              goalName: option.name,
              isNew: option.isNew,
              category: category,
            }
          })
          .sort((a, b) => {
            return a.goalName.localeCompare(b.goalName)
          })
        return {
          category,
          goalCategoryType,
          goals,
          isNew: goals.some((goal) => goal.isNew),
        }
      })
      .sort((a, b) => a.category.localeCompare(b.category))
    const shouldDisplayField = (
      field: FormField,
      formDataObject: Record<string, string>
    ): boolean => {
      if (field.action === BUTTON_ACTIONS.SKIP) return false
      if ((field.fieldType === 'upload' || field.fieldType === 'file') && setIsUpload) {
        setIsUpload(true)
      } else if (setIsUpload) {
        setIsUpload(false)
      }

      if (!field.conditionalField) return true
      const formContent = content as FormComponent
      const conditionalField = formContent?.formFields?.find(
        (f) => f.name === field?.conditionalField?.dependsOn
      )
      if (!conditionalField) return true
      if (!shouldDisplayField(conditionalField!, formDataObject)) return false
      const { dependsOn, values, excludeValues } = field.conditionalField
      let dependentValue = formDataObject[dependsOn]
      if (isUndefined(dependentValue)) {
        dependentValue = ''
      }
      if (Array.isArray(dependentValue)) {
        return (
          dependentValue.some((val) => values.includes(val)) &&
          !dependentValue.some((val) => excludeValues?.includes(val))
        )
      } else {
        return values.includes(dependentValue) && !excludeValues?.includes(dependentValue)
      }
    }
    const validationSchema = generateValidationSchema(
      (content as FormComponent)?.formFields ?? [],
      (formField) => shouldDisplayField(formField, formData)
    )
    const {
      handleSubmit,
      control,
      setValue,
      watch,
      resetField,
      formState: { errors },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(validationSchema),
    })
    const { mutate: createFlowChatSessionMutate } = useCreateFlowChatSessionMutation()
    const formObserver = watch()

    useEffect(() => {
      // Filter out null values from the formObserver
      const filteredObserver =
        formObserver &&
        Object.fromEntries(Object.entries(formObserver).filter(([_, value]) => value !== null))
      // Update formData with the filtered object
      setFormData(filteredObserver)
      if (!(content as FormComponent)?.formFields?.length) return
      ;(content as FormComponent)?.formFields.forEach((field) => {
        if (!shouldDisplayField(field, filteredObserver)) {
          resetField(field.name, {
            defaultValue: field.defaultValue,
          })
        }
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(formObserver)])

    useEffect(() => {
      if (uploadedFile && startUpload) {
        setValue('businessPlanFile', uploadedFile)
        handleSubmit((formData) => onSubmit({ ...formData, skip: 'true' }))()
        setUploadedFile && setUploadedFile({} as File)
        setStartUpload && setStartUpload(false)
        resetField('businessPlanFile')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startUpload])
    const isDisable =
      (content as FormComponent)?.status === OnboardingFlowStepStatus.Done || !isLastMessage
    switch (content?.type) {
      case OnboardingFlowStepTypes.Form:
        const formContent = content as FormComponent
        if (!formContent?.formFields?.length) return <></>
        const hasButtonField = formContent.formFields.some(
          (field) => field.fieldType === 'button' && field.action !== BUTTON_ACTIONS.SKIP
        )
        const hasSkipButton = formContent.formFields.some(
          (field) => field.fieldType === 'button' && field.action === BUTTON_ACTIONS.SKIP
        )
        const hasButtonSelectField = formContent.formFields.some(
          (field) => field.fieldType === 'buttonSelect'
        )
        let formatDescription = formContent.description
        if (
          (content as FormComponent)?.formFields?.[0]?.action ===
            BUTTON_ACTIONS.REDIRECT_TO_GOAL_CENTER ||
          (content as FormComponent)?.formFields?.[0]?.action ===
            BUTTON_ACTIONS.REDIRECT_GOAL_SETTING_TO_GOAL_CENTER ||
          (content as FormComponent)?.formFields?.[0]?.name === 'createAnotherGoal' ||
          (content as FormComponent)?.formFields?.[0]?.name === 'loginFrequency'
        ) {
          refetchBusinessGoal && refetchBusinessGoal()
        }

        try {
          formatDescription = JSON.parse(formContent.description ?? '').message
        } catch (e) {}
        return (
          <div className="flex flex-col gap-2">
            <form
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  handleSubmit((formData) =>
                    onSubmit({
                      ...formData,
                      id: formContent?.id ?? formContent?.title?.toLowerCase(),
                    })
                  )()
                }
              }}
              className=" w-[90%]"
              onSubmit={handleSubmit((formData) => {
                // Preprocess formData to remove empty strings from arrays
                const cleanedFormData = Object.fromEntries(
                  Object.entries(formData).map(([key, value]) => {
                    if (Array.isArray(value)) {
                      return [key, value.filter((item) => !isEmpty(item))]
                    }
                    return [key, value]
                  })
                )
                // Step 1: Format the messageValue into a string
                let formattedData = messageValue
                  .filter(
                    (item) =>
                      cleanedFormData[item.label] !== undefined &&
                      !isEmpty(item.value) &&
                      shouldDisplayField(
                        formContent.formFields.find((f) => f.name === item.label)!,
                        cleanedFormData
                      )
                  )
                  .map((item) => item.value && `${item.value.join(', ')}`)
                  .join(', ')

                // Step 2: Iterate over formFields and handle select or checkbox types
                ;(content as FormComponent).formFields.forEach((formField: FormField) => {
                  if (!shouldDisplayField(formField, formData)) return
                  const isSelectOrCheckbox =
                    formField.fieldType === 'select' || formField.fieldType === 'checkbox'

                  // Check if the field is not in messageValue
                  const isFieldInMessageValue = messageValue.some(
                    (item) => item.label === formField.name
                  )

                  if (isSelectOrCheckbox && !isFieldInMessageValue) {
                    // Find the selected options' labels for this field (if multiple is true, there can be multiple)
                    const selectedLabels = formField.options
                      ?.filter((option) => formData[formField.name]?.includes(option.value))
                      .map((option) => option.label)
                      .filter((label) => label)

                    // Append the labels to formattedData
                    if (selectedLabels?.length) {
                      if (formattedData.length > 0)
                        formattedData += `, ${selectedLabels.join(', ')}`
                      else formattedData += `${selectedLabels.join(', ')}`
                    }
                  }
                })

                // Step 3: Add the message and handle submission
                addMessage && addMessage({ sender: 'user', text: formattedData })
                onSubmit({
                  ...formData,
                  id: formContent?.id ?? formContent?.title?.toLowerCase(),
                })
              })}
            >
              {formContent.description ? (
                <p
                  title={formContent.title}
                  className="rounded-lg px-3 py-1.5 bg-background-appLight text-base text-black-appDark font-inter"
                >
                  {hasButtonField
                    ? BUTTON_DISPLAY_TEXT[
                        (content as FormComponent)?.formFields?.[0]?.action ??
                          'REDIRECT_TO_GOAL_SETTING'
                      ]
                    : renderMarkdownContent(formatDescription ?? '')}
                </p>
              ) : (
                <></>
              )}
              {(content as FormComponent).formFields.map((formField: FormField, index: number) =>
                shouldDisplayField(formField, formData) ? (
                  <div key={index} className="bg-app-grey-5 px-6 py-3 rounded-lg w-[90%]">
                    {formField.fieldType === 'button' ? (
                      <div className="flex justify-center ">
                        <div>
                          <AppButton
                            label={formField.label}
                            type="submit"
                            onClick={() => {
                              const tenantId = getTenantId()
                              if (
                                formField.action === BUTTON_ACTIONS.REDIRECT_TO_GOAL_CENTER ||
                                formField.action ===
                                  BUTTON_ACTIONS.REDIRECT_GOAL_SETTING_TO_GOAL_CENTER
                              ) {
                                updateUserProfileMutation({
                                  input: {
                                    userId,
                                    tenantId,
                                    onboardingStatus: OnboardingStatus.Complete,
                                  },
                                })
                                redirectTo(`/${getRoleFromPath()}/${tenantId}/goals`)
                              }
                              if (formField.action === 'REDIRECT_TO_GOAL_SETTING') {
                                updateUserProfileMutation({
                                  input: {
                                    userId,
                                    tenantId,
                                    onboardingStatus: OnboardingStatus.Onboarding,
                                  },
                                })
                                createFlowChatSessionMutate(
                                  {
                                    input: {
                                      flow: OnboardingFlowType.GoalSetting,
                                    },
                                  },
                                  {
                                    onSuccess: (res) => {
                                      window.location.href = `/${getRoleFromPath()}/${tenantId}/onboarding/${
                                        ChatBotFlowUrl[OnboardingFlowType.GoalSetting]
                                      }/${res.createFlowChatSession.id}`
                                    },
                                  }
                                )
                              }
                            }}
                            size="sm"
                            variant="primary"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col gap-2 rounded-md">
                        <Label
                          title={formField.label}
                          fontSize="xs"
                          className="!text-base text-black-appDark !font-inter"
                        >
                          {formField.fieldType !== 'file'
                            ? formField.label
                            : isDisable
                            ? uploadedFile?.name || businessProfile?.businessPlan?.name || ''
                            : ''}
                        </Label>
                        {formField.fieldType === 'input' && (
                          <>
                            {['number', 'float'].includes(formField.validation?.type ?? '') && (
                              <Controller
                                name={formField.name}
                                control={control}
                                defaultValue={
                                  formField.defaultValue ? Number(formField.defaultValue) : null
                                }
                                render={({ field }) => (
                                  <AppNumericField
                                    type="numeric"
                                    size="md"
                                    placeholder={formField.placeholder}
                                    disabled={isDisable}
                                    error={errors[field.name]?.message as string}
                                    {...field}
                                    onChange={(e) => {
                                      setMessageValue((prev) =>
                                        getUpdatedMessageState(field.name, [e], prev)
                                      )
                                      field.onChange(e)
                                    }}
                                  />
                                )}
                              />
                            )}

                            {!['number', 'float'].includes(formField.validation?.type ?? '') && (
                              <Controller
                                name={formField.name}
                                control={control}
                                defaultValue={formField.defaultValue || ''}
                                render={({ field }) => (
                                  <AppTextField
                                    type="text"
                                    size="md"
                                    placeholder={formField.placeholder}
                                    disabled={isDisable}
                                    error={errors[field.name]?.message as string}
                                    {...field}
                                    onChange={(e) => {
                                      setMessageValue((prev) =>
                                        getUpdatedMessageState(field.name, [e.target.value], prev)
                                      )
                                      field.onChange(e.target.value)
                                    }}
                                  />
                                )}
                              />
                            )}
                          </>
                        )}

                        {formField.fieldType === 'checkbox' && (
                          <Controller
                            name={formField.name}
                            control={control}
                            defaultValue={formField.defaultValue}
                            render={({ field }) => (
                              <>
                                <AppCheckBoxSelect
                                  {...field}
                                  id="tile-select"
                                  value={field.value || []}
                                  placeholder={
                                    formField.placeholder
                                      ? formField.placeholder
                                      : !!formField.multiple
                                      ? 'Please Select'
                                      : (formField?.options?.length as number) > 1
                                      ? 'Please Select One'
                                      : ''
                                  }
                                  disableSearch={true}
                                  onChange={(input) => {
                                    const data = Array.isArray(input) ? input : [input]
                                    setValue(field.name, data)
                                    // Map the selected values to include both label and value
                                    const selectedOptions = formField?.options
                                      ?.filter((opt) => data.includes(opt.value))
                                      ?.map((opt) => opt.label)

                                    setMessageValue((prev) =>
                                      getUpdatedMessageState(
                                        field.name,
                                        selectedOptions ?? [],
                                        prev
                                      )
                                    )
                                    field.onChange(data)
                                  }}
                                  options={
                                    formField?.options?.map((opt) => ({
                                      label: opt.label,
                                      value: opt.value,
                                      noneOfTheAbove: opt.noneOfTheAbove,
                                    })) ?? []
                                  }
                                  error={errors[field.name]?.message as string}
                                  isMulti={!!formField.multiple}
                                  isDisabled={isDisable}
                                />
                              </>
                            )}
                          />
                        )}

                        {formField.fieldType === 'buttonSelect' && (
                          <Controller
                            name={formField.name}
                            control={control}
                            defaultValue={formField.defaultValue}
                            render={({ field }) => (
                              <>
                                <AppButtonSelect
                                  {...field}
                                  id="tile-select"
                                  value={field.value || []}
                                  onChange={(input) => {
                                    setValue(field.name, input)
                                    const data = Array.isArray(input) ? input : [input]
                                    // Map the selected values to include both label and value
                                    const selectedOptions =
                                      formField?.options
                                        ?.filter((opt) => data.includes(opt.value))
                                        ?.map((opt) => opt.label)[0] ?? ''
                                    addMessage &&
                                      addMessage({ sender: 'user', text: selectedOptions })
                                    handleSubmit((formData) => onSubmit({ ...formData }))()
                                  }}
                                  options={
                                    formField?.options?.map((opt) => ({
                                      label: opt.label,
                                      value: opt.value,
                                    })) ?? []
                                  }
                                  isDisabled={isDisable}
                                />
                              </>
                            )}
                          />
                        )}

                        {formField.fieldType === 'select' && (
                          <Controller
                            name={formField.name}
                            control={control}
                            defaultValue={
                              formField.defaultValue ??
                              (formField?.options?.length ? formField?.options[0]?.value : null)
                            }
                            render={({ field }) => (
                              <div onClick={scrollToBottom}>
                                <AppSelectField
                                  disabled={isDisable}
                                  options={formField?.options ?? []}
                                  size="sm"
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e.value)
                                    const selectedOptions = formField?.options
                                      ?.filter((opt) => opt.value === e.value) // Filter options based on value
                                      .map((opt) => opt.label) // Map to labels
                                    setMessageValue((prev) =>
                                      getUpdatedMessageState(
                                        field.name,
                                        selectedOptions ?? [],
                                        prev
                                      )
                                    )
                                  }}
                                />
                              </div>
                            )}
                          />
                        )}

                        {formField.fieldType === 'upload' && (
                          <Controller
                            name={formField.name}
                            control={control}
                            defaultValue={formField.defaultValue}
                            render={({ field }) => (
                              <>
                                <ImageUploadField
                                  {...field}
                                  name="imageData"
                                  label={`Upload Business Images *`}
                                  buttonLabel="Upload Business Images"
                                  className="flex-1"
                                  existingLogo={formField.defaultValue}
                                  onChange={(e) => {
                                    field.onChange(e[0])
                                  }}
                                />
                              </>
                            )}
                          />
                        )}
                        {formField.fieldType === 'date' && (
                          <Controller
                            name={formField.name}
                            control={control}
                            render={({ field }) => (
                              <>
                                <AppPicker
                                  type={'date'}
                                  {...field}
                                  onChange={(e) => {
                                    setMessageValue((prev) =>
                                      getUpdatedMessageState(
                                        field.name,
                                        isObject(e) ? [e.start, e.end] : [e],
                                        prev
                                      )
                                    )
                                    field.onChange(e)
                                  }}
                                  isdisable={isDisable}
                                  minDate={formField.validation?.dateMin?.value}
                                />
                                {errors[field.name] && (
                                  <p style={{ color: 'red' }}>{errors[field.name]?.message}</p>
                                )}
                              </>
                            )}
                          />
                        )}
                        {formField.fieldType === 'goalTemplate' && (
                          <Controller
                            name={formField.name}
                            control={control}
                            render={({ field }) => (
                              <>
                                <AppSmartGoalCard
                                  onChange={(smartGoal) => {
                                    field.onChange(JSON.stringify(smartGoal))
                                  }}
                                  disabled={isDisable || isSendingMessageTobot}
                                  smartGoal={JSON.parse(
                                    field.value || formField.defaultValue || '{}'
                                  )}
                                />
                                {errors[field.name] && (
                                  <p style={{ color: 'red' }}>{errors[field.name]?.message}</p>
                                )}
                              </>
                            )}
                          />
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )
              )}

              {!isDisable && (!hasButtonField || hasSkipButton) ? (
                <div className="flex mt-2">
                  {hasSkipButton && (
                    <div className="mr-3">
                      <AppButton
                        label="Skip"
                        size="sm"
                        variant="secondary"
                        disabled={isSendingMessageTobot}
                        onClick={() => {
                          handleSubmit((formData) => onSubmit({ ...formData, skip: 'true' }))()
                        }}
                      />
                    </div>
                  )}

                  {!(hasButtonSelectField || isUpload) && (
                    <AppButton
                      label="Submit"
                      type="submit"
                      size="sm"
                      variant="primary"
                      disabled={!isEmpty(errors) || isSendingMessageTobot}
                    />
                  )}
                </div>
              ) : (
                <></>
              )}
            </form>
          </div>
        )

      case OnboardingFlowStepTypes.ToolConnection:
        const createTargetOrigin = (tool: Tool) => {
          const encodedParams = encodeURIComponent(
            stringify({ connectorName: tool.toolName ?? '', toolId: tool.id })
          )
          return `${window.location.href}?flash=${encodedParams}`
        }
        return (
          <div>
            <div>
              {(content as ToolListComponent).tools?.map((tool: Tool, index: number) => (
                <div key={index}>
                  <p
                    title={tool.id ?? ''}
                    className="rounded-lg px-3 py-1.5 mb-4 mt-2 bg-app-grey-5 text-base text-black-appDark font-inter"
                  >
                    {`Connect ${tool.toolName}`}
                  </p>
                  <AppToolBlock
                    tool={tools?.find((t) => t?.id === tool.id) || tool}
                    getOAuthUrlAction={getOAuthUrlAction}
                    targetOrigin={createTargetOrigin(tool)}
                    bookmarkCallback={refetchTool}
                  />
                </div>
              ))}
            </div>
            {isLastMessage && (
              <div className="flex justify-left mt-2 ml-auto">
                <AppButton
                  label="Skip"
                  size="sm"
                  variant="secondary"
                  disabled={isSendingMessageTobot}
                  onClick={() => {
                    handleSubmit((formData) => onSubmit({ ...formData, skip: 'true' }))()
                  }}
                />
              </div>
            )}
          </div>
        )

      case OnboardingFlowStepTypes.GoalTemplate:
        return (
          <div className="w-[90%]">
            <AppSmartGoalTemplate
              isLoading={isLoadingGoalTemplates}
              isDisable={isDisable}
              templates={goalTemplates as any}
              onSelectGoal={(goal) => {
                addMessage && addMessage({ sender: 'user', text: goal.goalName })
                onSubmit(goal)
              }}
              smartGoalTitle={content.title}
              smartGoalDesciption={content.description ?? ''}
            />
          </div>
        )

      default:
        return (
          <div className="rounded-lg px-3 py-1.5 bg-background-appLight text-base text-black-appDark font-inter">
            {useTypingAnimation ? (
              <TypingMessage
                text={(content as TextComponent).text}
                onNewCharacter={typingAnimationCallback}
              />
            ) : (
              renderMarkdownContent((content as TextComponent).text)
            )}
          </div>
        )
    }
  }
)
