import React from 'react'

export const AppUploadImageIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="36" height="36" rx="8" fill="url(#paint0_linear_11114_5462)" />
      <path
        d="M9.875 21.125L14.1742 16.8258C14.9064 16.0936 16.0936 16.0936 16.8258 16.8258L21.125 21.125M19.875 19.875L21.0492 18.7008C21.7814 17.9686 22.9686 17.9686 23.7008 18.7008L26.125 21.125M11.125 24.25H24.875C25.5654 24.25 26.125 23.6904 26.125 23V13C26.125 12.3096 25.5654 11.75 24.875 11.75H11.125C10.4346 11.75 9.875 12.3096 9.875 13V23C9.875 23.6904 10.4346 24.25 11.125 24.25ZM19.875 14.875H19.8813V14.8813H19.875V14.875ZM20.1875 14.875C20.1875 15.0476 20.0476 15.1875 19.875 15.1875C19.7024 15.1875 19.5625 15.0476 19.5625 14.875C19.5625 14.7024 19.7024 14.5625 19.875 14.5625C20.0476 14.5625 20.1875 14.7024 20.1875 14.875Z"
        stroke="#5E5E5E"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11114_5462"
          x1="18"
          y1="0"
          x2="18"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F5F5F5" />
          <stop offset="1" stop-color="#ECECEC" />
        </linearGradient>
      </defs>
    </svg>
  )
}
