import React, { ReactNode } from 'react'
import { AchievementSummaryCardWrapper, AppDashboardGridItemWrapper } from '../style'
import AppDashboardGridItemTitle from '../AppDashboardGridItemTitle'
import AchievementBadge from 'components/Common/SvgIcons/AchievementBadge'
import AchievementTrophy from 'components/Common/SvgIcons/AchievementTrophy'
// import AchivementAvatar from 'components/Common/SvgIcons/AchivementAvatar'
import { UserProfile, UserTaskQuickFilter } from '__generated__/api-types-and-hooks'
import { useGetUserTasks } from 'appHooks/useGetUserTasks'
import { useGetUserBusinessGoals } from 'appHooks/useGetUserBusinessGoals'
// import { StyledProgressBar } from 'components/AppKanbanBoard/AppStatusBar/AppTaskStatusBar.style'
// import AppFireIcon from 'components/Common/AppSvgIcons/AppFireIcon'

interface IAppDashboardAchievmentsSummaryProps {
  user: UserProfile
}

interface IAchievementSummaryCardProps {
  icon: ReactNode
  displayMetric: string
  summaryText: string
  summarySubText?: string
}

const AppDashboardAchievmentsSummary: React.FC<IAppDashboardAchievmentsSummaryProps> = ({
  user,
}) => {
  const { completedTasks, uncompletedTasks } = useGetUserTasks({
    quickFilter: UserTaskQuickFilter.AllTasks,
  })
  const { completedTouchdowns } = useGetUserBusinessGoals()

  return (
    <AppDashboardGridItemWrapper>
      <AppDashboardGridItemTitle>
        <h3 className="font-inter">This month</h3>
        <h4 className="font-light font-inter text-sm">Don't give up, you're doing great!</h4>
      </AppDashboardGridItemTitle>
      <div className="mt-4 space-y-4 md:px-4 lg:flex lg:items-center lg:justify-center lg:space-x-10 lg:space-y-0">
        {/* Tasks completed */}
        <AchievementSummaryCard
          icon={<AchievementBadge />}
          displayMetric={`${completedTasks.length}`}
          summaryText="Plays Completed"
          summarySubText={`${uncompletedTasks.length} plays remaining`}
        />

        {/* Goals completed */}
        <AchievementSummaryCard
          icon={<AchievementTrophy />}
          displayMetric={`${completedTouchdowns}`}
          summaryText="Touchdowns Completed"
        />
      </div>
      {/* <div className="mt-8 flex items-center justify-center">
        <AchievementStreakContainer user={user} />
      </div> */}
    </AppDashboardGridItemWrapper>
  )
}

const AchievementSummaryCard: React.FC<IAchievementSummaryCardProps> = ({
  icon,
  displayMetric,
  summaryText,
  summarySubText,
}) => {
  return (
    <AchievementSummaryCardWrapper>
      <div>
        <div className="flex items-center space-x-2">
          <span>{icon}</span>
          <span className="text-white font-bold text-3xl font-inter">{displayMetric}</span>
        </div>
        <h5 className="text-white text-[15px] font-normal mt-2 font-inter">{summaryText}</h5>
      </div>
      {summarySubText && (
        <div>
          <span className="text-white text-[15px] font-light font-inter">{summarySubText}</span>
        </div>
      )}
    </AchievementSummaryCardWrapper>
  )
}

// const AchievementStreakContainer: React.FC<IAppDashboardAchievmentsSummaryProps> = ({ user }) => {
//   return (
//     <div className="relative min-w-full lg:min-w-[60%] min-h-[180px]">
//       <div className="flex flex-col items-center pt-4 bg-primary-appCard min-h-[110px] rounded-t-4xl">
//         <span className="font-bold text-xl">Streaks</span>
//         <div className="flex items-center w-full px-14 space-x-4">
//           <AppFireIcon />
//           <StyledProgressBar hasFixedWidth={false} width={25} />
//         </div>
//       </div>
//       <div className="flex items-center space-x-2 px-8 py-3 bg-[#F4F4F5] rounded-[28px] w-[80%] mx-auto absolute top-20 left-12">
//         <AchivementAvatar />
//         <div className="space-y-2">
//           <h4 className="font-medium text-sm">{user.firstName}</h4>
//           <h5 className="font-normal text-xs">590 points</h5>
//         </div>
//       </div>
//     </div>
//   )
// }

export default AppDashboardAchievmentsSummary
