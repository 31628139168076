import React, { FC } from 'react'
import { AppToolCards } from 'components/AppTools/AppToolCategoryPanel/AppToolCards/AppToolCards.Component'
import { AppToolsBubbleWrapper } from 'components/AppTools/AppToolsPanel/AppToolsBubbleWrapper.component'

interface IAppToolCategoryPanel {
  setSearch: (param: string) => void
}
export const AppToolCategoryPanel: FC<IAppToolCategoryPanel> = ({ setSearch }) => {
  return (
    <div className="col-span-3 border-r border-primary-disabled px-12 py-16 hidden mdl:grid">
      <AppToolsBubbleWrapper setSearch={setSearch}>
        <AppToolCards />
        <div></div>
      </AppToolsBubbleWrapper>
    </div>
  )
}
