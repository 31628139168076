import React, { FC, useEffect, useState } from 'react'
import ToolsButton from 'components/Tools/ToolsPanel/ToolsButton.component'
import ToolsLabel from 'components/Tools/ToolsPanel/ToolsLabel.component'
import ExternalLinkLaunch from 'assets/svgs/external-link-arrow.svg'
import GradientCircleNotchIcon from 'components/Common/SvgIcons/GradientCircleNotchIcon'
import { CardBlock } from 'components/Owner/Tools/style'

import {
  Tool,
  ToolSupportLevel,
  useDeauthenticateConnectorMutation,
} from '__generated__/api-types-and-hooks'
import useTenantId from 'hooks/useTenantId'
import { ampli } from 'ampli'
import ToolCardHeader from '../ToolCardHeader.component'
import PillBoxAndSetting from '../../../Owner/Tools/PillBoxAndSetting'

export interface IBlockProps {
  tool: Tool
  getOAuthUrlAction: (props: any) => void
  setActiveConnectingTool?: (tool: Tool) => void
  allowDisconnect?: boolean
  onDisconnect?: () => void
  targetOrigin?: string
  readonly?: boolean
}

const ToolBlock: FC<IBlockProps> = ({
  tool,
  allowDisconnect = true,
  getOAuthUrlAction,
  setActiveConnectingTool,
  onDisconnect,
  targetOrigin,
  readonly,
}) => {
  const [isConnected, setIsConnected] = useState(tool.isConnected)

  const tenantId = useTenantId()
  const {
    mutate,
    isLoading: isToolDisconnecting,
    data: toolDisconnectedResponse,
  } = useDeauthenticateConnectorMutation()

  useEffect(() => {
    if (toolDisconnectedResponse && !toolDisconnectedResponse?.deauthenticateConnector?.error) {
      setIsConnected(false)
      ampli.toolDisconnected({ name: tool.toolName ?? '' })
      if (onDisconnect) {
        onDisconnect()
      }
    }
    // eslint-disable-next-line
  }, [toolDisconnectedResponse])

  useEffect(() => {
    setIsConnected(tool.isConnected)
  }, [tool])

  const handleDisconnect = () => {
    mutate({ input: { connectorName: tool.toolName ?? '', tenantId: tenantId ?? '' } })
  }
  const isToolConnectable = tool.supportLevel && tool.supportLevel !== ToolSupportLevel.Open

  return (
    <>
      <CardBlock className="w-full  h-[315px] xs:max-w-[100%] sm:max-w-[330px] flex flex-col border border-primary-outline rounded-[20px] bg-white pt-6">
        <ToolCardHeader key={tool.id} tool={tool} readonly={readonly} />

        <div className="flex flex-col justify-between px-6 h-full rounded-b-[20px] pb-6">
          <ToolDisconnection isToolDisconnecting={isToolDisconnecting} />
          <ToolsLabel tool={tool} isToolDisconnecting={isToolDisconnecting} />
          {isToolConnectable && (
            <PillBoxAndSetting
              isToolDisconnecting={isToolDisconnecting}
              isConnected={isConnected || false}
              handleDisconnect={handleDisconnect}
              readonly={readonly}
            />
          )}
          <ToolsButton
            isConnected={isConnected || false}
            ExternalLinkLaunch={ExternalLinkLaunch}
            tool={tool}
            setActiveConnectingTool={setActiveConnectingTool}
            getOAuthUrlAction={getOAuthUrlAction}
            buttonType="text"
            isNewTools={true}
            targetOrigin={targetOrigin}
            readonly={readonly}
          />
        </div>
      </CardBlock>
    </>
  )
}

export default ToolBlock

const ToolDisconnection = ({ isToolDisconnecting }) => {
  return (
    isToolDisconnecting && (
      <div className="flex flex-row">
        <GradientCircleNotchIcon width="30" height="30" />
        <div className="ml-2 my-auto font-semibold">Disconnecting</div>
      </div>
    )
  )
}
