import React, { FC, useEffect, useState } from 'react'
import ToolBlock from './ToolBlock/ToolBlock.component'
import Heading from 'components/Common/Heading'
import { getRoleFromPath } from 'utils/helper'
import { ConnectedProps } from 'react-redux'
import { ToolListConnector } from '../Tools.connector'
import ToolPlaceholder from './PlaceholderBlock/ToolPlaceholder.component'
import { ToolConnectionInputFormModal } from '../../Owner/Tools/ToolConnectionInputFormModal'
import { Tool } from '../../../__generated__/api-types-and-hooks'

interface IToolListProps {
  toolCategoryLabel: string
  toolDescription: string
  hasSubCategory: boolean
  query: string
}
interface IToolListProps extends ConnectedProps<typeof ToolListConnector> {
  readonly?: boolean
}

const ToolList = ToolListConnector<FC<IToolListProps>>(
  ({
    toolCategoryLabel,
    toolDescription,
    hasSubCategory,
    tenantId,
    query,
    toolsList,
    groupedTools,
    searchedToolQuery,
    getOAuthUrlAction,
    updateSearchToolConnectionStatus,
    oAuthUrl,
    readonly,
  }) => {
    const role = getRoleFromPath()
    const [activeConnectingTool, setActiveConnectingTool] = useState<Tool | null>(null)
    const [isInputPropertiesModalOpen, setInputPropertiesModalOpen] = useState(false)

    useEffect(() => {
      if (activeConnectingTool?.options?.connectionForm?.inputProperties?.length) {
        setInputPropertiesModalOpen(true)
      }
    }, [activeConnectingTool, isInputPropertiesModalOpen])

    return (
      <div>
        <Heading variant="primary" className="font-[650]">
          {toolCategoryLabel ?? ''}
        </Heading>
        {query && (
          <div className={` ${groupedTools.length === 0 ? '' : 'pt-6 pb-14'}`}>
            <p className="text-grey-dark font-xl">Showing {toolsList.length} result(s) for:</p>
            <Heading variant="primary" className="font-[650] mt-3">
              {query}
            </Heading>
          </div>
        )}

        <ToolPlaceholder
          role={role!}
          tenantId={tenantId}
          toolCategoryLabel={toolCategoryLabel}
          toolDescription={toolDescription}
          toolsList={toolsList}
        />

        {!hasSubCategory ? (
          groupedTools.map((item, index) => (
            <SubCategory
              key={index}
              item={item}
              index={index}
              getOAuthUrlAction={getOAuthUrlAction}
              updateSearchToolConnectionStatus={updateSearchToolConnectionStatus}
              setActiveConnectingTool={setActiveConnectingTool}
              readonly={readonly}
            />
          ))
        ) : (
          <div className="flex flex-wrap gap-6">
            {toolsList.map((tool, index) => (
              <ToolBlock
                key={index}
                tool={tool}
                getOAuthUrlAction={getOAuthUrlAction}
                setActiveConnectingTool={setActiveConnectingTool}
                readonly={readonly}
                onDisconnect={() => {
                  updateSearchToolConnectionStatus({
                    isConnected: false,
                    toolId: tool.id ?? '',
                    isSuccess: true,
                    isBookmarked: !!tool.isBookmarked,
                  })
                }}
              />
            ))}
          </div>
        )}
        {/* For pagination */}
        {/* <div className='flex justify-center flex-col items-center py-10'>
        <p className='text-silver font-[650] text-xl pb-8'>Viewing 17 of 46</p>
        <Button label="View More" variant="secondary" onClick={() => {}} />
      </div> */}
        {isInputPropertiesModalOpen && activeConnectingTool ? (
          <ToolConnectionInputFormModal
            tool={activeConnectingTool}
            onClose={() => {
              setInputPropertiesModalOpen(false)
              setActiveConnectingTool(null)
            }}
            getOAuthUrlAction={getOAuthUrlAction}
            oAuthUrl={oAuthUrl}
          />
        ) : (
          <></>
        )}
      </div>
    )
  }
)

export default ToolList

const SubCategory = ({
  item,
  index,
  getOAuthUrlAction,
  updateSearchToolConnectionStatus,
  setActiveConnectingTool,
  readonly,
}) => {
  return (
    <div className={`pb-20`}>
      <p className="pb-5 text-black-light text-2xl font-medium">{item.subCategory}</p>
      <div className={`flex flex-wrap gap-6 ${index === 0 ? '' : 'pb-20'}`}>
        {item?.tools.map((tool) => (
          <ToolBlock
            key={tool.id}
            tool={tool}
            getOAuthUrlAction={getOAuthUrlAction}
            setActiveConnectingTool={setActiveConnectingTool}
            onDisconnect={() => {
              updateSearchToolConnectionStatus({
                isConnected: false,
                toolId: tool.id ?? '',
                isSuccess: true,
                isBookmarked: !!tool.isBookmarked,
              })
            }}
            readonly={readonly}
          />
        ))}
      </div>
    </div>
  )
}
