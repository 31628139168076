import React from 'react'
import { AppButton } from 'components/Common/AppButton/AppButton.component'
import { AppCheckIcon } from 'components/Common/AppSvgIcons/AppCheckIcon'
import AppCrossIcon from 'components/Common/AppSvgIcons/AppModalCrossIcon'
import { UserTaskSuggestedItemStatus } from '__generated__/api-types-and-hooks'
import { AppNameInitials } from 'components/Common/AppNameInitials/AppNameInitials.component'
interface AppGoalSuggestedByBoxProps {
  imageUrl?: string
  onAccept: () => void
  onReject: () => void
  status: UserTaskSuggestedItemStatus
  size?: 'sm' | 'lg' | 'md' | 'xs'
  name: { firstName: string; lastName: string }
  isDisabled?: boolean
}

export const AppSuggestedByBox: React.FC<AppGoalSuggestedByBoxProps> = ({
  imageUrl,
  onAccept,
  onReject,
  status,
  size = 'sm',
  name,
  isDisabled,
}) => {
  const isAccepted = status === UserTaskSuggestedItemStatus.Accepted
  const isRejected = status === UserTaskSuggestedItemStatus.Rejected

  // Extracted colors and variants based on status
  const checkIconColor = isAccepted ? '#61BA47' : '#999999'
  const crossIconColor = isRejected ? '#D0021B' : '#999999'
  const acceptButtonVariant = isAccepted ? 'success' : 'secondary'
  const rejectButtonVariant = isRejected ? 'alert' : 'secondary'

  return (
    <div className="flex items-center justify-between gap-4 w-full mt-2">
      <AppButton
        label="Suggested By"
        size={size}
        variant="secondary"
        RightComponent={
          (
            <AppNameInitials
              name={name}
              className="bg-app-grey-30 w-7 h-7 text-xs !font-medium !text-app-grey-70"
            />
          ) as any
        }
      />
      <div className="flex gap-1">
        <AppButton
          size={size}
          disabled={isDisabled || isAccepted}
          variant={acceptButtonVariant}
          LeftIcon={(props) => <AppCheckIcon {...props} color={checkIconColor} />}
          onClick={(e) => {
            e?.preventDefault()
            e?.stopPropagation()
            onAccept()
          }}
        />
        <AppButton
          size={size}
          disabled={isDisabled || isRejected}
          variant={rejectButtonVariant}
          LeftIcon={(props) => <AppCrossIcon {...props} color={crossIconColor} />}
          onClick={(e) => {
            e?.preventDefault()
            e?.stopPropagation()
            onReject()
          }}
        />
      </div>
    </div>
  )
}
