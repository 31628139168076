import PillTextBox, { PillColorTheme } from 'components/Common/PillTextBox'
import { FC } from 'react'
import Settings from 'components/Owner/Tools/Settings'
import React from 'react'

interface IPillBoxAndSetting {
  isToolDisconnecting: boolean
  isConnected?: boolean
  handleDisconnect: () => void
  readonly?: boolean
}

const PillBoxAndSetting: FC<IPillBoxAndSetting> = ({
  isToolDisconnecting,
  isConnected,
  handleDisconnect,
  readonly,
}) => {
  return (
    <div className="flex flex-row gap-4">
      {!isToolDisconnecting && (
        <PillTextBox
          colorTheme={isConnected ? PillColorTheme.SUCCESS : PillColorTheme.ERROR}
          size="small"
        >
          {!isConnected && 'Not '}Connected
        </PillTextBox>
      )}
      {isConnected && (
        <div>
          <Settings handleDisconnect={handleDisconnect} disabled={!isConnected || readonly} />
        </div>
      )}
    </div>
  )
}

export default PillBoxAndSetting
