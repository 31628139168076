import {
  BusinessGoal,
  useGetFilteredBusinessGoalsQuery,
  UserTaskItemStatus,
} from '__generated__/api-types-and-hooks'
import { GOALIDS, OTHERTASKS } from 'appConfig/enums'
import { getTenantId } from 'utils/getTenantId'
import { useParams } from 'react-router-dom'

interface IGoalOptions {
  label: string
  value: string
  planId: string | null
  planStartDate?: string
  planEndDate?: string
  goalExpectedEndDate?: string
}

interface IUseBusinessGoalsOptionsInput {
  paramUserId?: string
}

export const useBusinessGoalsOptions = ({ paramUserId }: IUseBusinessGoalsOptionsInput) => {
  const { clientId } = useParams()
  const tenantId = getTenantId()
  const { data, isLoading } = useGetFilteredBusinessGoalsQuery(
    {
      input: { userId: paramUserId || clientId, tenantId },
    },
    { refetchOnWindowFocus: false }
  )

  const createGoalsOptions = (goals: BusinessGoal[]) => {
    if (goals) {
      const options: IGoalOptions[] = []
      goals.forEach((goal) => {
        const plan = goal.plan
        if (
          goal.status !== UserTaskItemStatus.Archived &&
          goal.status !== UserTaskItemStatus.Paused &&
          goal.status !== UserTaskItemStatus.Done
        ) {
          options.push({
            label: goal.name ?? '',
            value: goal.goalId ?? '',
            planId: plan?.planId ?? '',
            planStartDate: plan?.startDate ?? '',
            planEndDate: plan?.expectedEndDate ?? '',
            goalExpectedEndDate: goal.expectedEndDate ?? '',
          })
        }
      })

      // Add the "My Other Tasks" option
      options.push({
        label: OTHERTASKS.MY_OTHER_TASKS,
        value: GOALIDS.MY_OTHER_TASKS,
        planId: null,
      })

      return options
    } else {
      return []
    }
  }

  const goalsOptions = data?.getBusinessGoals ? createGoalsOptions(data.getBusinessGoals) : []

  return { goalsOptions, isLoading }
}
