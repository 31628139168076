import { AppModalBox } from 'components/Common/AppModalBox/AppModalBox.component'
import AppTaskErrorIcon from 'components/Common/AppSvgIcons/AppTaskErrorIcon'
import AppTaskSuccesIcon from 'components/Common/AppSvgIcons/AppTaskSuccesIcon'
import React from 'react'
import { AppTaskCard } from '../AppTaskCard/AppTaskCard.component'
import { AppButton } from 'components/Common/AppButton/AppButton.component'
import { UserTask } from '__generated__/api-types-and-hooks'

interface IAppTaskCreationConfirmationProps {
  showAddTaskSuccessModal: boolean
  setShowAddTaskSuccessModal: (flag: boolean) => void
  setShowAddTaskErrorModal: (flag: boolean) => void
  setShowAddTaskModal: (flag: boolean) => void
  createdUserTask: UserTask
  userFirstName?: string
  userLastName?: string
  userAvatar?: string
  userId: string
  isUpdateTask?: boolean
}
export const AppTaskCreationConfirmation: React.FC<IAppTaskCreationConfirmationProps> = ({
  showAddTaskSuccessModal,
  setShowAddTaskSuccessModal,
  setShowAddTaskErrorModal,
  setShowAddTaskModal,
  createdUserTask,
  userId,
  userAvatar,
  userFirstName,
  userLastName,
  isUpdateTask,
}) => {
  return (
    <AppModalBox
      width="w-[320px]"
      onClose={() => {
        setShowAddTaskSuccessModal(false)
        setShowAddTaskErrorModal(false)
        if (isUpdateTask) {
          setShowAddTaskModal(true)
        }
      }}
    >
      <div className="p-6 space-y-8">
        <div className="flex flex-col items-center space-y-4">
          {showAddTaskSuccessModal ? <AppTaskSuccesIcon /> : <AppTaskErrorIcon />}
          {isUpdateTask ? (
            <h3 className="font-medium text-base font-inter text-center">
              {showAddTaskSuccessModal ? 'Upload Successful' : 'Upload Failed'}
            </h3>
          ) : (
            <h3 className="font-medium text-base font-inter text-center">
              {showAddTaskSuccessModal ? 'Success' : 'Error'}
            </h3>
          )}
          {isUpdateTask ? (
            <span className="font-normal text-sm font-inter text-app-grey-70 text-center">
              {showAddTaskSuccessModal
                ? 'No flags here! Your file(s) came through with a clean play and uploaded successfully'
                : 'Flag on the play! Your file(s) did not upload successfully. Please try again'}
            </span>
          ) : (
            <span className="font-normal text-sm font-inter text-app-grey-70 text-center">
              {showAddTaskSuccessModal
                ? 'The play has been successfully created. View details in your Play Board.'
                : 'The play could not be created. Please try again.'}
            </span>
          )}
        </div>
        <div className="mt-8 rounded-lg  border border-app-grey-20 shadow-md p-4 text-black min-h-[100px] bg-white cursor-pointer flex justify-between flex-col">
          <AppTaskCard
            task={createdUserTask}
            userId={userId}
            userAvatar={userAvatar ?? ''}
            userFirstName={userFirstName ?? ''}
            userLastName={userLastName ?? ''}
          />
        </div>
        {!showAddTaskSuccessModal && (
          <div>
            <AppButton
              label="Try Again"
              variant="alert"
              size="md"
              onClick={() => {
                setShowAddTaskSuccessModal(false)
                setShowAddTaskErrorModal(false)
                setShowAddTaskModal(true)
              }}
            />
          </div>
        )}
      </div>
    </AppModalBox>
  )
}
