import { useParams } from 'react-router-dom'
import {
  BusinessUserType,
  useGetBusinessProfileQuery,
  useGetMyProfileQuery,
} from '__generated__/api-types-and-hooks'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import { useMemo } from 'react'
import { getTenantId } from 'utils/getTenantId'
import Avatar from 'appAssets/appImages/Avatar.png'

export interface IAssigneeOptions {
  label: string
  value: string
  type: BusinessUserType
  user: {
    id: string
    firstName: string
    lastName: string
  }
}
interface IUseGetBusinessProfileProps {
  paramUserId?: string
  enabled?: boolean
}

export const useGetBusinessProfile = ({ paramUserId, enabled }: IUseGetBusinessProfileProps) => {
  const tenantId = getTenantId()
  const { clientId } = useParams()
  const userId = useSelector((state: RootState) => state.user.user.id)

  // Fetch business profile
  const { data: businessData } = useGetBusinessProfileQuery(
    { id: paramUserId ?? clientId ?? userId },
    { refetchOnWindowFocus: false, enabled: enabled ?? true }
  )

  // Fetch user profile
  const { data: userProfile } = useGetMyProfileQuery(
    { userId: paramUserId ?? clientId ?? userId, tenantId },
    { refetchOnWindowFocus: false, enabled: enabled ?? true }
  )

  const advisors = useMemo(() => businessData?.getBusinessProfile?.advisors, [businessData])

  const assigneeOptions: IAssigneeOptions[] = useMemo(() => {
    if (!businessData?.getBusinessProfile?.users) return []

    const userOptions = businessData.getBusinessProfile.users.map((user) => ({
      value: user.userId,
      label: `${user.user.firstName} ${user.user.lastName}`,
      type: user.type,
      user: {
        firstName: user.user.firstName ?? '',
        lastName: user.user.lastName ?? '',
        id: user.user.id,
      },
    }))

    if (advisors?.length) {
      advisors.forEach((advisor) => {
        userOptions.push({
          value: advisor.id,
          label: `${advisor.firstName} ${advisor.lastName}`,
          type: BusinessUserType.Member,
          user: {
            id: advisor.id,
            firstName: advisor.firstName ?? '',
            lastName: advisor.lastName ?? '',
          },
        })
      })
    }

    return userOptions
  }, [advisors, businessData?.getBusinessProfile?.users])

  // Compute userName from userProfile
  const userName = useMemo(() => {
    return `${userProfile?.getMyProfile.firstName} ${userProfile?.getMyProfile.lastName}`
  }, [userProfile])

  const userFirstName = useMemo(() => {
    return userProfile?.getMyProfile.firstName
  }, [userProfile])

  const userLastName = useMemo(() => {
    return userProfile?.getMyProfile.lastName
  }, [userProfile])

  // Compute advisorsData from userBoAdvisorsData and userName
  const advisorsData: Array<{ label: string; value: string }> = useMemo(() => {
    return [
      ...(advisors?.map((advisor) => ({
        value: advisor?.id,
        label: `${advisor?.firstName ?? ''} ${advisor?.lastName ?? ''}`,
      })) ?? []),
      {
        value: clientId ?? userId,
        label: userName,
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advisors, userName])

  // Get logo from businessData
  const logo = useMemo(
    () =>
      businessData?.getBusinessProfile?.logo?.url
        ? businessData?.getBusinessProfile?.logo?.url
        : businessData?.getBusinessProfile?.avatar ?? Avatar,
    [businessData]
  )

  const userAvatar = useMemo(() => businessData?.getBusinessProfile?.avatar, [businessData])

  // Get hours spent per day from businessData
  const hoursSpentPerDay: number = useMemo(
    () => Math.ceil((businessData?.getBusinessProfile?.hoursSpentPerWeek ?? 40) / 5),
    [businessData]
  )

  return {
    advisors,
    assigneeOptions,
    userName,
    userFirstName,
    userLastName,
    advisorsData,
    userAvatar,
    logo,
    hoursSpentPerDay,
  }
}
