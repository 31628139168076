import React, { FC } from 'react'

interface IBookMarkIconProps {
  className: string
  stroke?: string
}
const BookMarkIcon: FC<IBookMarkIconProps> = ({ className, stroke = '#5E06B9' }) => {
  return (
    <svg
      data-testid="bookmark-icon"
      className={className}
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor="pointer"
    >
      <path
        id="Vector"
        d="M7.39392 14.0809L7 13.912L6.60608 14.0809L1 16.4835V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H12C12.551 1 13 1.44554 13 2V16.4835L7.39392 14.0809Z"
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  )
}

export default BookMarkIcon
