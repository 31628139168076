import { useMemo } from 'react'
import { useGetBusinessProfileQuery } from '__generated__/api-types-and-hooks'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import { useParams } from 'react-router-dom'

export interface TeamMember {
  id: string
  firstName: string
  lastName: string
}

export const useGetTeammember = (): TeamMember[] => {
  const { clientId } = useParams()
  const currentUser = useSelector((state: RootState) => state.user.user)

  const { data: businessProfileData } = useGetBusinessProfileQuery(
    {
      id: clientId || currentUser.id,
    },
    { refetchOnWindowFocus: false }
  )

  const advisors = businessProfileData?.getBusinessProfile?.advisors

  return useMemo(() => {
    if (!businessProfileData?.getBusinessProfile?.users) return []

    const userOptions: TeamMember[] = businessProfileData.getBusinessProfile.users
      .filter((user) => user.user.firstName && user.user.lastName)
      .map((user) => ({
        id: user.userId,
        firstName: user.user.firstName!,
        lastName: user.user.lastName!,
      }))

    if (advisors?.length) {
      advisors
        .filter((advisor) => advisor.firstName && advisor.lastName)
        .forEach((advisor) => {
          userOptions.push({
            id: advisor.id,
            firstName: advisor.firstName!,
            lastName: advisor.lastName!,
          })
        })
    }

    return userOptions
  }, [advisors, businessProfileData?.getBusinessProfile?.users])
}
