import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Divider } from 'components/Calendar/style'
import Button from 'components/Common/Button'
import CheckBoxField from 'components/Common/CheckBoxField'
import ErrorMessage from 'components/Common/ErrorMessage'
import { HeadingWrapper } from 'components/DataReports/ClientReportManagement/styles'
import { ParagraphWrapper } from 'components/Owner/PlayBooksCategoryDetails/TakePlaysToast/styles'
import { FORM, USER_ROLES } from 'config/enums'
import useFormContainer from 'hooks/useFormContainer'
import { isEmpty } from 'lodash'
import { subscribeToSmsSchema } from 'pages/Owner/validation'
import { Controller, useForm } from 'react-hook-form'
import { useTenantId } from 'utils/useTenantId'
import { ButtonParentWrapper, ButtonWrapper, SmsHeadingWrapper, SmsParentWrapper } from './style'
import { isFunctionEmpty, smsSubscriptionAmpliLog } from 'utils/helper'
import { AppFC, ISmsSubscriptionNotification } from 'types'
import { formatMobileNumber } from 'appUtils'

const SmsSubscriptionNotification: AppFC<ISmsSubscriptionNotification> = ({
  user,
  onSubmit,
  isLoading,
  moveToNext,
  moveToNextTwo,
  handleBack,
  isWelcomeScreen,
  progressBarAction,
}) => {
  const tenantId = useTenantId()
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(subscribeToSmsSchema),
  })
  const {
    handleSubmit,
    control,
    clearErrors,
    formState: { errors },
  } = methods
  const onSubscribe = () => {
    onSubmit({
      isSmsEnabled: true,
      tenantId,
    })
    if (moveToNextTwo && progressBarAction) {
      moveToNextTwo()
      progressBarAction()
    }
    smsSubscriptionAmpliLog(user, 'Subscribed')
  }
  useFormContainer(FORM.SMS_ENABLED, methods)

  return (
    <SmsParentWrapper isWelcomeScreen={isWelcomeScreen}>
      <SmsHeadingWrapper isWelcomeScreen={isWelcomeScreen}>Don’t miss out!</SmsHeadingWrapper>
      <ParagraphWrapper className=" text-lg leading-6 !text-black-light mt-3 font-semibold">
        Get priority notifications to your phone to stay up-to-date on meeting requests, chats, and
        product updates.
      </ParagraphWrapper>
      {user.roles?.includes(USER_ROLES.BSO_ADVISOR) && (
        <ParagraphWrapper className=" text-lg leading-6 !text-black-light mt-3 font-semibold">
          Your number is never visible to your clients.
        </ParagraphWrapper>
      )}
      <ParagraphWrapper className="mt-8">
        <p className="!text-primary-text"> Mobile Phone Number</p>
        <p className="font-bold !text-black-light">
          {user?.mobileContactNumber
            ? formatMobileNumber(user?.mobileContactNumber)
            : 'XXX-XXX-XXXX'}
        </p>
      </ParagraphWrapper>
      <form onSubmit={handleSubmit(onSubscribe)}>
        <HeadingWrapper className="!pt-6 !gap-4">
          <Controller
            defaultValue={user.isSmsEnabled}
            name="isSmsOptedIn"
            control={control}
            render={({ field }) => (
              <CheckBoxField
                {...field}
                id="isSmsOptedIn"
                className="!mr-0 "
                label={'I agree to receive SMS'}
                onChange={(e) => {
                  field.onChange(e)
                  clearErrors('isSmsOptedIn')
                }}
                value={field.value}
                bgColor={'bg-white'}
              />
            )}
          />
        </HeadingWrapper>

        <ParagraphWrapper className="!text-primary-text text-xs pt-4 ">
          By subscribing, you agree to receive messages at the phone number provided. Reply STOP to
          cancel. Messaging rates may apply.
        </ParagraphWrapper>
        {!isEmpty(errors) && (
          <ErrorMessage id={'isSmsOptedIn'}>{errors.isSmsOptedIn?.message}</ErrorMessage>
        )}
        {!isWelcomeScreen && <Divider />}
        <ButtonParentWrapper isWelcomeScreen={!isFunctionEmpty(handleBack)}>
          {handleBack && (
            <Button
              className="!h-16"
              type="button"
              label="Back"
              variant="secondary"
              onClick={handleBack}
            />
          )}
          <ButtonWrapper isWelcomeScreen={!isFunctionEmpty(handleBack)}>
            <Button
              onClick={() => {
                clearErrors('isSmsOptedIn')
                onSubmit({
                  isSmsEnabled: false,
                  tenantId,
                })
                if (moveToNext && progressBarAction) {
                  moveToNext()
                  progressBarAction()
                }
                smsSubscriptionAmpliLog(user, 'UnSubscribed')
              }}
              disabled={isLoading}
              variant="text"
              type="button"
              label="Don't Subscribe"
            ></Button>
            <Button
              type="submit"
              disabled={isLoading}
              variant="primary"
              label="Subscribe"
              className={`${!isWelcomeScreen && '!px-4 !py-3'}`}
            ></Button>
          </ButtonWrapper>
        </ButtonParentWrapper>
      </form>
    </SmsParentWrapper>
  )
}
SmsSubscriptionNotification.defaultProps = {
  handleBack: undefined,
  moveToNext: undefined,
  isWelcomeScreen: false,
  progressBarAction: undefined,
}

export default SmsSubscriptionNotification
