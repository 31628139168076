import React, { ElementType, useMemo } from 'react'
import { AppFC } from 'types'

export const TagsText = {
  AI: 'AI',
  FREE: 'Free',
}

interface ITagsProps {
  tagText: string
  size?: 'small' | 'base' | 'large'
  textSize?: 'small' | 'normal' | 'large'
  Icon?: ElementType
}
const Tags: AppFC<ITagsProps> = ({
  children,
  tagText,
  size = 'small',
  className,
  textSize = 'small',
  Icon,
}) => {
  const backgroundColor = useMemo((): string => {
    return {
      [TagsText.AI]: 'bg-blue',
      [TagsText.FREE]: 'bg-blue-darkest1x',
    }[tagText]
  }, [tagText])

  const textColor = useMemo(() => {
    return {
      [TagsText.AI]: 'text-white',
      [TagsText.FREE]: 'text-white',
    }[tagText]
  }, [tagText])

  const sizeStyles = useMemo(() => {
    return {
      small: 'py-[3px] px-[8px]',
      base: 'py-[4px] px-5',
      large: 'py-[6px] px-5',
    }[size]
  }, [size])

  const textStyles = useMemo(() => {
    return {
      small: 'text-[10px] leading-[18px]',
      normal: 'text-base',
      large: 'text-lg',
    }[textSize]
  }, [textSize])

  return (
    <span
      className={`${backgroundColor} ${sizeStyles} rounded-md ${
        className ? className : 'text-center'
      } `}
    >
      <span className="flex gap-x-[6px] items-center">
        {Icon && <Icon />}
        <span className={`${textColor}  font-[650] ${textStyles} `}>{children}</span>
      </span>
    </span>
  )
}
export default Tags
