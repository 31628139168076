import { createSelector } from 'reselect'
import history from 'utils/history'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { BusinessProfile } from '__generated__/api-types-and-hooks'
import { getCurrentRoute, getOptions } from 'utils/helper'
export const getAssessmentQuestion = (state) => state.owner.questions
export const getOwnerGoals = (state) => state.owner.goals
export const getAssessmentOptions = (state) => state.owner.assessmentOptions

export const getAssessmentResponse = (state) => state.owner.assessmentResponse
export const getActiveQuestion = (state) => state.owner.activeQuestion

export const assessmentResponseByQuestion = (state) => state.owner.assessmentQuestion
export const getUserData = (state) => state.user.user
export const getshowSubscriptionPrompt = (state) => state.user.showSubscriptionPrompt
export const getBusinessProfile = (state) => state.user.businessProfile

const getStepUserResponseInfo = (activeQuestion, assessmentResponse, isAnswered) => {
  let id = '',
    value = '',
    next = ''
  const userReponse = assessmentResponse?.assessmentResponse?.find(
    (ar) => ar.id === activeQuestion.id
  )
  if (assessmentResponse?.currentStage === activeQuestion.id || !isAnswered || !userReponse) {
    id = activeQuestion.options[0]?.id
    value = activeQuestion.meta.questionType
    isAnswered = false
    if (assessmentResponse?.currentStage === activeQuestion.id && !isEmpty(userReponse)) {
      let selectedOption = activeQuestion?.options?.find((op) => op.id === userReponse.value)
      next = selectedOption?.action?.next?.default
      id = userReponse.value
      isAnswered = true
    }
  } else {
    let selectedOption = activeQuestion?.options?.find((op) => op.id === userReponse.value)
    if (
      !selectedOption &&
      userReponse &&
      (activeQuestion.optionType === 'upload' || activeQuestion.optionType === 'input')
    ) {
      selectedOption = activeQuestion?.options[0]
    }
    id = selectedOption?.id
    next = selectedOption?.action?.next?.default
    value = activeQuestion?.meta?.questionType
    isAnswered = true
  }
  return { id, value, isAnswered, next }
}

const getUniqueQuestions = (questions, activeQuestion, sum = 1) => {
  const next = activeQuestion?.options?.[0]?.action?.next?.default
  if (next) {
    const nextQuestion = questions?.find((q) => q.id === next)
    return getUniqueQuestions(questions, nextQuestion, sum + 1)
  }
  return sum
}

const getAssessmentProgress = (
  questions,
  activeQuestion,
  assessmentResponse?,
  isAnswered = true,
  sum = 0
) => {
  let stepProgressInfo: any = {}
  if (!questions.length || isEmpty(activeQuestion))
    return {
      stepProgressInfo: [],
      sum,
    }
  stepProgressInfo = getStepUserResponseInfo(activeQuestion, assessmentResponse, isAnswered)
  let next = activeQuestion?.options?.[0]?.action?.next?.default
  if (stepProgressInfo.isAnswered) {
    next = stepProgressInfo.next
  }
  if (next) {
    const nextQuestion = questions?.find((q) => q.id === next)
    const info = getAssessmentProgress(
      questions,
      nextQuestion,
      assessmentResponse,
      stepProgressInfo.isAnswered,
      sum + 1
    )
    info.stepProgressInfo.unshift(stepProgressInfo)
    return info
  } else if (!stepProgressInfo.isAnswered && !next) {
    return { stepProgressInfo: [stepProgressInfo], sum: sum + 1 }
  } else {
    return { stepProgressInfo: [stepProgressInfo], sum }
  }
}

export const getBusinessProfileAssessmentsSelector = createSelector(
  [getAssessmentQuestion],
  (assessment) => {
    return assessment
      ?.filter((question) => question?.meta.showToBusinessProfile)
      .sort((a, b) => a?.meta?.sequenceOrder - b?.meta?.sequenceOrder)
  }
)

export const getAssessmentResponseSelector = createSelector(
  [getAssessmentResponse],
  (assessment) => {
    if (assessment?.assessmentResponse) {
      const response = {}
      assessment?.assessmentResponse.forEach((answer) => {
        response[answer.id] = answer.value
      })
      return response
    }
    return {}
  }
)

export const getAssessmentOptionsSelector = createSelector(
  [getAssessmentOptions],
  (assessmentOptions) => {
    return getOptions(assessmentOptions)
  }
)

export const getAssessmentQuestionSelector = createSelector(
  [getAssessmentQuestion, getActiveQuestion],
  (questions, activeQuestion) => {
    let stepIndex = 0,
      questionIndex = 0,
      remainingSteps = 0,
      totalSteps = 0,
      progressBarIndex = 0

    if (questions.length && !isEmpty(activeQuestion)) {
      remainingSteps = getUniqueQuestions(questions, activeQuestion)
      totalSteps = getUniqueQuestions(questions, questions[0])
      progressBarIndex = totalSteps - remainingSteps
    }

    return {
      stepIndex,
      questionIndex,
      progressBarIndex: progressBarIndex > 0 ? progressBarIndex + 1 : 0,
      questions,
      totalSteps,
    }
  }
)

export const getCurrentGoalSelector = createSelector([getOwnerGoals], (goals) => {
  const pathname = history.location.pathname.split('/')
  const currentGoalId = pathname[pathname?.length - 1]
  const currentGoal = goals?.getUserGoals?.find((res) => res.id === currentGoalId)
  return currentGoal
})

export const getAssessmentProgressSelector = createSelector(
  [getAssessmentQuestion, getActiveQuestion, getAssessmentResponse],
  (questions, activeQuestion, assessmentResponse) => {
    const { sum: remainingSteps } = getAssessmentProgress(
      questions,
      activeQuestion,
      assessmentResponse
    )
    let { stepProgressInfo, sum: totalSteps } = getAssessmentProgress(
      questions,
      questions[0],
      assessmentResponse
    )
    const progressBarIndex = totalSteps - remainingSteps
    const ProgressTexts = {}
    stepProgressInfo = stepProgressInfo.filter((info) => {
      if (info.value in ProgressTexts) return false
      else {
        ProgressTexts[info.value] = true
        return true
      }
    })

    return {
      stepIndex: 0,
      stepProgressInfo,
      progressBarIndex: progressBarIndex > 0 ? progressBarIndex + 1 : 0,
      totalSteps,
      questions,
      remainingSteps,
    }
  }
)

export const getAssessmentQuestionOption = createSelector(
  [assessmentResponseByQuestion],
  (assessmentQuestion) => {
    const value = assessmentQuestion?.value

    let parsedQuestion = JSON.parse(assessmentQuestion?.question || '[]')
    const selectedOption = parsedQuestion?.options?.find((opt) => opt.id === value)

    return { icon: selectedOption?.icon, value: selectedOption?.value }
  }
)

export const getUserDataSelector = createSelector([getUserData], (user) => {
  const year = moment().format('YYYY')
  const month = moment().format('MMMM')
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const currentDate = moment(new Date()).tz(userTimeZone).format('YYYY-MM-DD')

  const submissionStartDay = moment(currentDate)
    .set('date', user?.submissionStartDay)
    .format('YYYY-MM-DD')

  const submissionEndDay = moment(currentDate)
    .set('date', user?.submissionEndDay)
    .format('YYYY-MM-DD')
  const submissionReminderDay = moment(currentDate)
    .set('date', user?.submissionReminderDay)
    .format('YYYY-MM-DD')

  let date: string = ''
  let message: string = ''
  let infoText: string = ''
  let variant: string = ''
  let showViewDetailsButton: boolean = true

  if (currentDate >= submissionStartDay && currentDate < submissionReminderDay) {
    variant = 'info'
    infoText = `You have a monthly impact statement to review and sign. The due date is ${month} ${user?.submissionEndDay}, ${year}.`
  } else if (currentDate >= submissionReminderDay && currentDate <= submissionEndDay) {
    variant = 'warning'
    infoText = `You still need to review and sign your monthly impact statement. The due date is ${month} ${user?.submissionEndDay}, ${year}.`
  }

  return {
    variant,
    infoText,
    message,
    date,
    showViewDetailsButton,
  }
})
export const getUserSubscriptionPrompt = createSelector(
  [getUserData, getshowSubscriptionPrompt],
  (user, showSubscriptionPrompt) => {
    const currentPath = getCurrentRoute()
    const lastSlashIndex = currentPath.lastIndexOf('/')
    if (currentPath.substring(lastSlashIndex + 1) === 'notification-settings') return false

    if (user?.isSmsEnabled === null && !user?.isNewUser && showSubscriptionPrompt) {
      return true
    } else if (user?.isSmsEnabled === null && !user?.isNewUser) {
      return true
    } else {
      return false
    }
  }
)

export const getBusinessProfileSelector = createSelector(
  [getBusinessProfile],
  (businessProfile: BusinessProfile) => {
    const updatedBusinessProfile = businessProfile || {}
    const impactReportFiles = updatedBusinessProfile.monthlyImpactReports || []

    updatedBusinessProfile.monthlyImpactReports = impactReportFiles.map((file) => ({
      url: file.url,
      name: `${moment(file.createdAt).subtract(1, 'months').format('MMMMYYYY')}.pdf`,
    }))

    return updatedBusinessProfile
  }
)
