import API from 'api'
import { getFileData, ISignedUrl } from 'utils/helper'
import { CustomFile } from 'types'
import CommonApi from 'api/common'

interface IUploadFilesProps {
  files: File[]
  tenantId: string
  clientId: string
  userId: string
}

export const uploadFiles = async ({ files, tenantId, clientId, userId }: IUploadFilesProps) => {
  // 1. Get signed URLs for all files
  const signedUrlPromises = files.map((file) => CommonApi.getSignedUrl(file.type, file.name))
  const signedUrlResponses = await Promise.all(signedUrlPromises)
  const signedUrls = signedUrlResponses.map(
    (res) => JSON.parse(res.getSignedUrl.data) as ISignedUrl
  )

  // 2. Upload files to S3
  const uploadPromises = files.map((file, index) =>
    API.uploadFile(signedUrls[index].signedUrl, file, file.type)
  )
  const uploadResults = await Promise.all(uploadPromises)

  // 3. Process file data
  const customFiles = files.map((file) => ({
    ...file,
    name: file.name,
    type: file.type,
    size: file.size,
  })) as CustomFile[]

  const { fileData } = getFileData({
    data: uploadResults,
    files: customFiles,
    signedUrl: signedUrls,
    tenantId,
    userId: clientId ?? userId,
    isMultiple: true,
  })
  return Array.isArray(fileData) ? fileData : [fileData]
}
