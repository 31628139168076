import React, { FC } from 'react'
import PlaceholderBlock from './PlaceholderBlock.component'
import SuggestionsIcon from 'components/Common/SvgIcons/SuggestionsIcon'
import { toolsCategory } from 'components/Tools/data'
import history from 'utils/history'
import { Tool } from '__generated__/api-types-and-hooks'
import { ROLES } from 'config'

interface IToolPlaceholderProps {
  toolCategoryLabel: string
  toolDescription: string
  tenantId: string
  role: string
  toolsList: Tool[]
}
const ToolPlaceholder: FC<IToolPlaceholderProps> = ({
  toolCategoryLabel,
  toolsList,
  toolDescription,
  role,
  tenantId,
}) => {
  return (
    <>
      <p className="pt-4 pb-16 text-grey-dark font-normal text-xl leading-7">{toolDescription}</p>
      {toolCategoryLabel === toolsCategory.toolKit.label &&
      role === ROLES.BUSINESS_OWNER &&
      toolsList.length === 0 ? (
        <PlaceholderBlock
          Icon={SuggestionsIcon}
          Paragraph={`
        <p class="!font-medium !text-primary-text">
          Browse our categories of tools to find your favorites!
        </p>
        <p class="!font-medium !text-primary-text pt-5 pb-3">
          For personalized GoTackle Tool recommendations, complete the assessments.
        </p>`}
          buttonLabel="Take an assessment"
          handleClick={() => history.push(`/${role}/${tenantId}/playbooks`)}
        />
      ) : toolsList.length === 0 ? (
        <PlaceholderBlock
          Icon={SuggestionsIcon}
          Paragraph={` <p class="!font-medium !text-primary-text">
         No Tool Found
        </p>`}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default ToolPlaceholder
