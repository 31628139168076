import React from 'react'

function ChevronBottomIcon({ className, fill }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="chevron-down">
        <path
          id="Vector"
          d="M7.41 8.58008L12 13.1701L16.59 8.58008L18 10.0001L12 16.0001L6 10.0001L7.41 8.58008Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default ChevronBottomIcon

ChevronBottomIcon.defaultProps = {
  className: 'shrink-0',
  fill: '#71717A',
}
