import React, { useEffect, useRef } from 'react'

import { isEmpty, size } from 'lodash'

import {
  ListItem,
  NotificationWrapper,
  NotificationPanelWrapper,
  NotificationPanelMobileIcon,
  NotificationPanelDesktopIcon,
  NotificationPanelHeader,
  NotificationPanelHeaderTitle,
  NotificationPanelHeaderLoading,
  NotificationBellIconWrapper,
  NotificationPanelCancelIcon,
} from 'components/NotificationPanel/style'
import Message from 'components/NotificationPanel/Message'
import CircularLoading from 'components/Common/CircularLoading'

import { useOutsideClick } from 'hooks/useClickOutside'

import { updateAmplitudeLog, viewNotificationDetails } from 'utils/helper'
import { AnnouncementContainer } from 'pages/Announcement/containers'

const NotificationPanel = ({
  name,
  isLoading,
  totalCount,
  activeStep,
  handleClose,
  businessName,
  notifications,
  lastEvaluatedKey,
  resetUserNotifications,
  getUserNoticationsAction,
  updateUserNoticationsAction,
  getUnreadNotificationsAction,
  setShowNotificationTooltip = (bool) => {},
}) => {
  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, handleClose, activeStep)

  const handleScroll = (e) => {
    let scrollDifference = e.target.scrollHeight - e.target.scrollTop
    scrollDifference = scrollDifference - e.target.clientHeight - 10
    if (scrollDifference < 0 && !isEmpty(lastEvaluatedKey)) {
      getUserNoticationsAction({ viewMore: true })
    }
  }

  useEffect(() => {
    getUserNoticationsAction()
    return () => {
      getUnreadNotificationsAction()
      updateUserNoticationsAction()
      resetUserNotifications()
    }
    // eslint-disable-next-line
  }, [getUserNoticationsAction, getUnreadNotificationsAction, updateUserNoticationsAction])

  return (
    <NotificationWrapper ref={wrapperRef}>
      <NotificationPanelWrapper>
        <NotificationPanelMobileIcon>
          <div onClick={handleClose} className="flex justify-between items-center w-full">
            <div className="flex items-center">
              <NotificationBellIconWrapper className="fill-app-primary-75" />
              <span className="font-medium text-black-light">Notifications</span>
            </div>
            <NotificationPanelCancelIcon />
          </div>
        </NotificationPanelMobileIcon>

        <NotificationPanelDesktopIcon onClick={handleClose}>
          <NotificationPanelCancelIcon />
        </NotificationPanelDesktopIcon>

        <NotificationPanelHeader>
          <NotificationPanelHeaderTitle>
            <NotificationBellIconWrapper />
            <NotificationPanelHeaderLoading>
              Notifications
              {isLoading && <CircularLoading className="ml-2 inline-block" />}
            </NotificationPanelHeaderLoading>
          </NotificationPanelHeaderTitle>

          {totalCount > 0 && (
            <div className="text-sm text-app-grey-60 mt-1">
              You have {totalCount} notification{totalCount !== 1 ? 's' : ''}
            </div>
          )}

          <AnnouncementContainer
            setShowNotificationTooltip={setShowNotificationTooltip}
            totalCount={totalCount}
          />
        </NotificationPanelHeader>
      </NotificationPanelWrapper>

      {size(notifications) === 0 ? (
        <div className="text-sm font-primary pt-8 px-6 text-center">
          <div className="bg-app-primary-5 rounded-xl p-6 shadow-sm">
            <h3 className="font-semibold text-black-light mb-2">No notifications yet</h3>
            <p className="text-app-grey-60">
              When you receive a chat message or a platform update, you'll see a notification in
              this area.
            </p>
          </div>
        </div>
      ) : (
        <ListItem id="notficationList" onScroll={handleScroll}>
          {notifications.map((notification) => (
            <li
              key={notification.id}
              className={`${notification.url ? 'cursor-pointer' : ''} li-body`}
              onClick={() => {
                viewNotificationDetails(notification, handleClose)
                updateAmplitudeLog(name, businessName, notification)
              }}
            >
              <div className="unread-icon-body">
                <Message notification={notification} handleClose={handleClose} />
              </div>
            </li>
          ))}
          {isLoading && (
            <div className="py-4 text-center">
              <CircularLoading />
              <p className="text-sm text-app-grey-60 mt-2">Loading more notifications...</p>
            </div>
          )}
        </ListItem>
      )}
    </NotificationWrapper>
  )
}

export default NotificationPanel
