import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import { Controller, FieldValues, UseFormReturn, UseFormSetValue } from 'react-hook-form'

import { FieldLabel } from 'components/AppKanbanBoard/AppTask/AppTask.style'
import { AppSelectField } from 'components/Common/AppFormFields/AppSelectField/AppSelectField.component'

import { AppBadge } from 'components/Common/AppBadge/AppBadge.component'
import CircularLoading from 'components/Common/CircularLoading'
import { useBusinessGoalsOptions } from './AppTaskGoalField.hook'
import { TaskFormFields } from 'components/AppKanbanBoard/config'

interface IAppTaskGoalFieldProps {
  methods: UseFormReturn
  setValue: UseFormSetValue<FieldValues>
  clickedFields: { [key: string]: boolean }
  handleClick: (field: string, isHovered: boolean) => void
  setGoalExpectedEndDate: Dispatch<SetStateAction<string>>
  planStartDate?: React.MutableRefObject<string | undefined>
  paramUserId?: string
}

export const AppTaskGoalField: React.FC<IAppTaskGoalFieldProps> = ({
  methods,
  clickedFields,
  setGoalExpectedEndDate,
  planStartDate,
  handleClick,
  setValue,
  paramUserId,
}) => {
  const goalWrapperRef = useRef<HTMLDivElement | null>(null)
  const { goalsOptions, isLoading } = useBusinessGoalsOptions({ paramUserId })

  const {
    control,
    formState: { errors },
  } = methods

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (goalWrapperRef.current && !goalWrapperRef.current.contains(event.target as Node)) {
        handleClick(TaskFormFields.goalId, false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [handleClick])

  const handleAssignPlanStartDate = (value: string) => {
    if (planStartDate) planStartDate.current = value
  }

  return (
    <>
      <FieldLabel>Goal</FieldLabel>
      <div className="col-span-7 font-inter text-black-dark">
        {isLoading ? (
          <CircularLoading className={'app-bg-primary'} />
        ) : (
          <>
            <Controller
              name={TaskFormFields.goalId}
              control={control}
              render={({ field }) => (
                <>
                  {clickedFields.goalId ? (
                    <div className="flex items-center w-2/3" ref={goalWrapperRef}>
                      <AppSelectField
                        size="sm"
                        {...field}
                        options={goalsOptions}
                        placeholder="Select Goal"
                        errors={errors.status?.message as string}
                        onBlur={() => handleClick(field.name, false)}
                        onChange={(e) => {
                          setGoalExpectedEndDate(
                            goalsOptions.find((option) => option.value === e.value)
                              ?.goalExpectedEndDate ?? ''
                          )

                          handleAssignPlanStartDate(
                            goalsOptions.find((option) => option.value === e.value)
                              ?.planStartDate ?? ''
                          )
                          setValue(
                            'planId',
                            goalsOptions.find((option) => option.value === e.value)?.planId
                          )
                          setValue(
                            'goalExpectedEndDate',
                            goalsOptions.find((option) => option.value === e.value)
                              ?.goalExpectedEndDate ?? ''
                          )
                          field.onChange(e.value)
                          handleClick(field.name, false)
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      className="col-span-7 font-inter text-primary-disabledTextLight py-1"
                      onClick={() => handleClick(field.name, true)}
                    >
                      <AppBadge
                        size="sm"
                        label={
                          goalsOptions.find((option) => option.value === field.value)?.label ??
                          'Select Goal'
                        }
                        color={'White'}
                        type={'Outline'}
                      />
                    </div>
                  )}
                </>
              )}
            />
          </>
        )}
      </div>
    </>
  )
}
