import React, { FC } from 'react'

import { Tool } from '__generated__/api-types-and-hooks'
import { AppIconWrapper } from 'components/AppTools/AppTools.style'
import { getImageUrl } from 'utils/helper'
import { AppBookmarkTool } from 'components/AppTools/AppToolsPanel/AppBookmarkTool.component'

interface IAppCardHeadProps {
  tool: Tool
  bookmarkCallback?: () => void
}
export const AppToolCardHeader: FC<IAppCardHeadProps> = ({ tool, bookmarkCallback }) => {
  return (
    <div className="flex flex-row justify-between items-start px-6">
      <AppIconWrapper
        className="!max-w-[150px]"
        src={getImageUrl(tool.imageUrl)}
        alt={`${tool?.toolName}`}
      />
      <AppBookmarkTool tool={tool} bookmarkCallback={bookmarkCallback} />
    </div>
  )
}
