export const toolsCategory = {
  Featured: {
    label: 'Featured',
    value: 'featured',
  },
  toolKit: {
    label: 'My Tech Stack',
    value: 'my_tech_stack',
  },
  businessEssentials: {
    label: 'Business Essentials',
    value: 'business_essentials',
  },
  communications: {
    label: 'Communications',
    value: 'communications',
  },
  finance: {
    label: 'Finance',
    value: 'finance',
  },
  humanResources: {
    label: 'Human Resources',
    value: 'humanResources',
  },
  marketing: {
    label: 'Marketing',
    value: 'marketing',
  },
  operations: {
    label: 'Operations',
    value: 'operations',
  },
  productivity: {
    label: 'Productivity',
    value: 'productivity',
  },
  sales: {
    label: 'Sales',
    value: 'sales',
  },
}
export const toolsData = [
  {
    ...toolsCategory.Featured,
    description: 'Some of our favorite and most useful tools.',
    clientDescription: 'Some of your clients favorite and most useful tools.',
  },
  {
    ...toolsCategory.toolKit,
    description: 'All of your connected and bookmarked tools in one place.',
    clientDescription: "All of your client's connected and bookmarked tools in one one place.",
  },
]
export const toolsCategoryData = [
  {
    ...toolsCategory.Featured,
    description: 'Some of our favorite and most useful tools.',
  },
  {
    ...toolsCategory.toolKit,
    description: 'All of your connected and bookmarked tools in one place.',
  },
  {
    ...toolsCategory.businessEssentials,
    description:
      'Manage core business functions, like your day-to-day schedule and business plan creation.',
  },
  {
    ...toolsCategory.communications,
    description: 'Improve your writing and communication channels.',
  },
  {
    ...toolsCategory.finance,
    description: 'Track and analyze your financial data.',
  },
  {
    ...toolsCategory.humanResources,
    description: 'Essential tools for seamless business operations.',
  },
  {
    ...toolsCategory.marketing,
    description: 'Expand your audience and market effectively.',
  },
  {
    ...toolsCategory.operations,
    description: 'Optimize operational workflows.',
  },
  {
    ...toolsCategory.productivity,
    description: 'Boost work efficiency.',
  },
  {
    ...toolsCategory.sales,
    description: 'Enhance and track sales performance.',
  },
]
