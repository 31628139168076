import { useParams } from 'react-router-dom'
import { getRoleFromPath } from 'utils/helper'
import { getTenantId } from 'utils/getTenantId'
import { useMemo } from 'react'

type RouteParams = {
  clientId?: string
}

export const useGetAppToolsPathPrefix = (): string => {
  const { clientId } = useParams<RouteParams>()
  const role = getRoleFromPath()
  const tenantId = getTenantId()

  const pathPrefix = useMemo(() => {
    if (!role || !tenantId) {
      return '/'
    }

    if (role.includes('admin') || role.includes('advisor')) {
      if (!clientId) {
        return `/${role}/${tenantId}/clients`
      }
      return `/${role}/${tenantId}/clients/${clientId}/techTools`
    }

    return `/${role}/${tenantId}/tools`
  }, [role, tenantId, clientId])

  return pathPrefix
}
