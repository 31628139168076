import { RootState } from 'App'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { getOAuthUrlAction, resetOAuthUrlAction } from 'store/actions/calendar'
import {
  getSearchToolsAction,
  getToolCategoriesAction,
  updateSearchToolConnectionStatus,
} from 'store/actions/clients'
import { getToolCategories, getToolsList } from 'store/selectors/clients'
import { Tool } from '../../__generated__/api-types-and-hooks'

const ToolsProps = (state: RootState) => {
  const { oAuthUrl } = state.calendar
  return {
    tenantId: state.user.tenantId,
    toolsCategoryData: getToolCategories(state),
    oAuthUrl,
  }
}

function ToolsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getToolCategoriesAction,
      resetOAuthUrlAction: resetOAuthUrlAction,
    },
    dispatch
  )
}
export const ToolsConnector = connect(ToolsProps, ToolsDispatch)

const ToolsBubbleWrapperProps = (state: RootState) => {
  return {
    tenantId: state.user.tenantId,
    toolsCategoryData: getToolCategories(state),
  }
}

function ToolsBubbleWrapperDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resetGetSearchToolsAction: getSearchToolsAction.RESET,
    },
    dispatch
  )
}
export const ToolsBubbleWrapperConnector = connect(
  ToolsBubbleWrapperProps,
  ToolsBubbleWrapperDispatch
)

const ToolsPanelProps = (state: RootState) => {
  return {
    tenantId: state.user.tenantId,
    isFetching: state.clients.isFetching,
    toolsList: state.clients?.tools as Tool[],
    errorMessage: state.clients.searchToolErrorMessage,
  }
}

function ToolsPanelDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getSearchToolsAction: getSearchToolsAction.STARTED,
    },
    dispatch
  )
}
export const ToolsPanelConnector = connect(ToolsPanelProps, ToolsPanelDispatch)

const ToolListProps = (state: RootState) => {
  const { oAuthUrl } = state.calendar
  return {
    tenantId: state.user.tenantId,
    groupedTools: getToolsList(state),
    toolsList: state.clients?.tools as Tool[],
    searchedToolQuery: state.clients.searchedToolQuery,
    oAuthUrl,
  }
}

function ToolListDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getOAuthUrlAction: getOAuthUrlAction.STARTED,
      updateSearchToolConnectionStatus,
    },
    dispatch
  )
}
export const ToolListConnector = connect(ToolListProps, ToolListDispatch)
