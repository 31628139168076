import crossIcon from '../../assets/svgs/cross-icon.svg'
import styled from 'styled-components'
import tw from 'twin.macro'

export const AppInputWrapper = styled.span`
  input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    content: URL(crossIcon);
    background: url(${crossIcon});
    background-size: 10px;
    padding-bottom: 5px;
    background-repeat: no-repeat;
    z-index: 4;
  }
`

export const AppConnectedCard = styled.div`
  background: linear-gradient(112.79deg, #52b4f9 0%, #8400f7 100%);
`

export const AppCardBlock = styled.div`
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
`
export const AppHelpDeskWrapper = styled.div`
  ${tw` relative   flex items-center justify-center  rounded-full cursor-pointer z-[999]`}
`

export const AppLinkAnchor = styled.div`
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
`
export const AppIconWrapper = styled.img`
  max-width: 250px;
  height: 50px;
  object-fit: contain;
`

export const AppHoverListMain = styled.ul`
  /* box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1); */
  /* border-radius: 10px; */
  /* background: #ffffff; */
  /* border: 1px solid #d4d4d8; */
  /* padding: 9px 0; */
  top: 8px;
  left: 5px;
  position: relative;
`

export const AppHoverList = styled.li`
  padding: 5px 24px;
  color: #5bbab9;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Futura Std';
  &:hover {
    background: rgba(91, 186, 185, 0.2);
  }
`
export const AppAllToolsModalStyle = styled.main.attrs({
  className: 'h-full xs:p-6 sm:p-8 lg:p-12',
})`
  & {
    .all-tools-modal-header {
      ${tw`flex flex-col justify-center items-center`}
    }
    .all-tools-modal-header-title {
      ${tw`xs:text-4xl sm:text-[40px] text-center lg:text-[52px] font-black xs:leading-[40px] sm:leading-[50px] lg:leading-[56px] font-secondary tracking-[2%] text-black block sm:pt-0 lg:pt-5`}
    }
    .all-tools-modal-header-label {
      ${tw`text-lg font-semibold text-primary-text xs:py-5 sm:py-6 lg:py-8 text-center font-primary`}
    }
    .all-tools-modal-header-hr {
      ${tw` text-secondary-text my-2`}
    }
  }
`
export const AppAllToolsModalBodyStyle = styled.main.attrs({
  className:
    'sm:border xs:h-[calc(100%-310px)] sm:h-[calc(100%-220px)] md:h-[calc(100%-200px)] lg:h-[calc(100%-240px)] sm:border-primary-outline rounded-xl pb-0 xs:pt-4 xs:px-0 sm:pt-8 sm:px-8 lg:pt-12 lg:px-12 justify-center ',
})`
  & {
    .all-tools-modal-box {
      ${tw`xs:max-h-full max-h-full xs:justify-center sm:justify-start overflow-auto xs:gap-x-5 xs:gap-y-11 sm:gap-x-16 sm:-mr-8 md:gap-x-20 lg:gap-x-16 lg:-mr-12 sm:gap-y-8 md:gap-y-10 lg:gap-y-12 flex flex-wrap`}
    }
    .all-tools-modal-body {
      ${tw`inline-flex flex-col  relative xs:gap-2 sm:gap-5`}
    }
    .all-tools-modal-body-icon {
      ${tw`absolute right-[-12px] top-[7px]`}
    }
    .all-tools-modal-body-active {
      ${tw`border-2 border-primary`}
    }
    .all-tools-modal-body-un-active {
      ${tw`border border-primary-outline`}
    }
    .all-tools-modal-body-box {
      ${tw`xs:h-[105px] xs:w-[105px] sm:h-[138px] sm:w-[138px]  inline-flex xs:p-4 sm:p-5 bg-secondary rounded-xl shadow hover:border-primary cursor-pointer`}
    }
    .all-tools-modal-body-span {
      ${tw`bg-silver-lighter inline-flex h-24 w-24 object-contain`}
    }
    .all-tools-modal-body-img {
      ${tw`xs:h-[73px] xs:w-[73px] sm:h-24 sm:w-24`}
    }
    .all-tools-modal-body-text {
      ${tw`xs:w-[105px] sm:w-[138px]  font-primary text-primary-text text-base leading-6 text-center`}
    }
  }
`
export const AppAllToolsModalFooterStyle = styled.main.attrs({
  className: ' pt-6 xs:hidden sm:flex items-end	justify-end	',
})``

export const AppToolsGuideWrapper = styled.div.attrs({
  className: 'flex xs:gap-3 sm:gap-6 justify-center xs:m-3 lg:m-0 xs:flex-wrap sm:flex-nowrap',
})``

export const AppCard = styled.div.attrs({
  className:
    'flex gap-6 rounded-xl flex-col shadow-lg w-full max-w-[514px] xs:p-6 justify-evenly sm:p-8 lg:p-12 items-center bg-white  ',
})`
  & {
    .tools-guide {
      ${tw`flex justify-center flex-col xs:gap-5 md:gap-10`}
    }
    .tools-guide-description {
      ${tw`xs:text-base md:text-lg text-primary-text xs:leading-5 sm:leading-7 font-semibold text-center font-primary `}
    }
    .tools-guide-sub-description {
      ${tw`xs:text-base sm:text-xl text-primary font-bold xs:leading-5 sm:leading-7 font-primary text-center`}
    }
    .tools-guide-btn {
      ${tw`py-3 px-5 bg-secondary w-auto m-auto`}
    }
  }
`
