import React from 'react'

const VerticalDotted = ({ className }) => {
  return (
    <svg
      className={className}
      width="6"
      height="30"
      viewBox="0 0 8 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 25.75C6.07109 25.75 7.75 27.4297 7.75 29.5C7.75 31.5703 6.07109 33.25 4 33.25C1.92891 33.25 0.25 31.5703 0.25 29.5C0.25 27.4297 1.92891 25.75 4 25.75ZM4 13.25C6.07109 13.25 7.75 14.9297 7.75 17C7.75 19.0703 6.07109 20.75 4 20.75C1.92891 20.75 0.25 19.0703 0.25 17C0.25 14.9297 1.92891 13.25 4 13.25ZM4 8.25C1.92891 8.25 0.25 6.57031 0.25 4.5C0.25 2.42891 1.92891 0.75 4 0.75C6.07109 0.75 7.75 2.42891 7.75 4.5C7.75 6.57031 6.07109 8.25 4 8.25Z" />
    </svg>
  )
}

export default VerticalDotted
