import { RootState } from 'App'
import {
  useGetBusinessLeadsConversionRateMetricsQuery,
  useGetBusinessNetProfitMetricsQuery,
  useGetBusinessNumberOfLeadsMetricsQuery,
  useGetBusinessRevenueMetricsQuery,
} from '__generated__/api-types-and-hooks'
import moment from 'moment'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const useGetBusinessMetrics = () => {
  const clientId = useSelector((state: RootState) => state.clients.client.profile.id)
  const userId = useSelector((state: RootState) => state.user.user.id)

  const {
    data: businessRevenueMetric,
    error: revenueMetricError,
    isLoading: isRevenueLoading,
  } = useGetBusinessRevenueMetricsQuery(
    {
      id: clientId ?? userId,
      asOfDate: moment().format('YYYY-MM-DD'),
    },
    { refetchOnWindowFocus: false }
  )

  const {
    data: businessNetProfitMetric,
    error: netProfitError,
    isLoading: isNetProfitLoading,
  } = useGetBusinessNetProfitMetricsQuery(
    {
      id: clientId ?? userId,
      asOfDate: moment().format('YYYY-MM-DD'),
    },
    { refetchOnWindowFocus: false }
  )

  const {
    data: businessNumberOfLeadsMetric,
    error: numberOfLeadsError,
    isLoading: isNumberOfLeadsLoading,
  } = useGetBusinessNumberOfLeadsMetricsQuery(
    {
      id: clientId ?? userId,
      asOfDate: moment().format('YYYY-MM-DD'),
    },
    { refetchOnWindowFocus: false }
  )

  const {
    data: businessLeadsConversionRateMetric,
    error: leadsConversionRateError,
    isLoading: isLeadsConversionRateLoading,
  } = useGetBusinessLeadsConversionRateMetricsQuery(
    {
      id: clientId ?? userId,
      asOfDate: moment().format('YYYY-MM-DD'),
    },
    { refetchOnWindowFocus: false }
  )

  const sales = useMemo(() => {
    const businessProfile = businessRevenueMetric?.getBusinessProfile
    const revenueThisMonth: number | null =
      businessProfile?.revenueThisMonth?.counter?.value ?? null
    const revenueGrowthRate: number | null =
      businessProfile?.revenueGrowthRate?.counter?.value ?? null
    const revenueYearToDate: number | null =
      businessProfile?.revenueYearToDate?.counter?.value ?? null

    return {
      displayHeader: 'Total Revenue',
      displayMetric: revenueThisMonth !== null ? `$${revenueThisMonth?.toFixed(2)}` : '---',
      metricPercentage:
        revenueGrowthRate !== null ? `${revenueGrowthRate?.toFixed(2)}% vs. last month` : '',
      metricSummary: revenueYearToDate !== null ? `$${revenueYearToDate?.toFixed(2)} YTD` : '---',
    }
  }, [businessRevenueMetric])

  const netProfit = useMemo(() => {
    const businessProfile = businessNetProfitMetric?.getBusinessProfile
    const netProfitThisMonth: number | null =
      businessProfile?.netProfitThisMonth?.counter?.value ?? null
    const netProfitGrowthRate: number | null =
      businessProfile?.netProfitGrowthRate?.counter?.value ?? null
    const netProfitYearToDate: number | null =
      businessProfile?.netProfitYearToDate?.counter?.value ?? null

    return {
      displayHeader: 'Net Profit',
      displayMetric: netProfitThisMonth !== null ? `$${netProfitThisMonth?.toFixed(2)}` : '---',
      metricPercentage:
        netProfitGrowthRate !== null ? `${netProfitGrowthRate?.toFixed(2)}% vs. last month` : '',
      metricSummary:
        netProfitYearToDate !== null ? `$${netProfitYearToDate?.toFixed(2)} YTD` : '---',
    }
  }, [businessNetProfitMetric])

  const leads = useMemo(() => {
    const businessProfile = businessNumberOfLeadsMetric?.getBusinessProfile
    const leadCount: number | null = businessProfile?.leadCount?.counter?.value ?? null
    const leadCountGrowthRate: number | null =
      businessProfile?.leadCountGrowthRate?.counter?.value ?? null

    let trendDir = 'nil'
    if (leadCountGrowthRate !== null && leadCountGrowthRate !== 0) {
      trendDir = leadCountGrowthRate < 0 ? 'down' : 'up'
    }

    return {
      displayHeader: 'All Channels',
      displayMetric: leadCount !== null ? `${leadCount?.toFixed(2)}` : '---',
      metricPercentage: `${
        leadCountGrowthRate !== null ? `${leadCountGrowthRate.toFixed(2)}% vs. last month` : '---'
      }`,
      trendDir,
    }
  }, [businessNumberOfLeadsMetric])

  const leadsConversion = useMemo(() => {
    let trendDir = 'nil'
    const businessProfile = businessLeadsConversionRateMetric?.getBusinessProfile
    const leadConversionRateGrowthRate: number | null =
      businessProfile?.leadConversionRateGrowthRate?.counter?.value ?? null
    const leadConversionRate: number | null =
      businessProfile?.leadConversionRate?.counter?.value ?? null

    if (leadConversionRateGrowthRate !== null && leadConversionRateGrowthRate !== 0) {
      trendDir = leadConversionRateGrowthRate < 0 ? 'down' : 'up'
    }
    return {
      displayHeader: 'All Channels',
      displayMetric: leadConversionRate !== null ? `${leadConversionRate?.toFixed(2)}%` : '---',
      metricPercentage: `${
        leadConversionRateGrowthRate !== null
          ? `${leadConversionRateGrowthRate.toFixed(2)}% vs. last month`
          : '---'
      }`,
      trendDir,
    }
  }, [businessLeadsConversionRateMetric])

  return {
    sales,
    netProfit,
    leads,
    leadsConversion,
    businessRevenueMetric,
    revenueMetricError,
    isRevenueLoading,
    businessNetProfitMetric,
    netProfitError,
    isNetProfitLoading,
    businessNumberOfLeadsMetric,
    numberOfLeadsError,
    isNumberOfLeadsLoading,
    businessLeadsConversionRateMetric,
    leadsConversionRateError,
    isLeadsConversionRateLoading,
  }
}
