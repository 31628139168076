import { useEffect } from 'react'

export function useAuthentication(
  oAuthUrl,
  getUserConnectorsAction,
  resetOAuthUrlAction,
  successCallback = () => {}
) {
  useEffect(() => {
    if (oAuthUrl) {
      // Redirect the user to the OAuth URL
      window.location.href = oAuthUrl
      resetOAuthUrlAction()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oAuthUrl, resetOAuthUrlAction])

  useEffect(() => {
    // Check for OAuth callback parameters in the URL
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('result')

    if (code) {
      const url = new URL(window.location.href)
      window.history.replaceState({}, '', url.pathname)
      // Process the callback and get user connectors
      getUserConnectorsAction()

      if (successCallback) {
        successCallback()
      }
    }
  }, [getUserConnectorsAction, successCallback])

  return null
}
