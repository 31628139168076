import React, { useMemo } from 'react'

import {
  AppStyledAvatarWrapper,
  AppStyledDotWrapper,
  AppAvatarWrapper,
} from 'components/Common/AppAvatar/AppAvatar.style'
import { AppStatusDot } from 'components/Common//AppStatusDot/AppStatusDot.component'

export type ColorType = 'Grey' | 'Primary' | 'Red' | 'Green' | 'Orange'
export interface AppAvatarProps {
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'
  type: 'image' | 'text' | 'mixed'
  shape: 'Squared' | 'Rounded'
  status?: boolean
  color?: ColorType
  src?: string
  text?: string
  textStyles?: string
}

// Function to generate a consistent color based on text
const getColorFromText = (text?: string): ColorType => {
  if (!text) return 'Primary'

  // Available colors - prioritizing vibrant colors by repeating them
  const colors: ColorType[] = [
    'Primary',
    'Red',
    'Green',
    'Orange',
    'Primary',
    'Red',
    'Green',
    'Orange',
    'Grey',
  ]

  // Generate a hash from the text
  let hash = 0
  for (let i = 0; i < text.length; i++) {
    hash = text.charCodeAt(i) + ((hash << 5) - hash)
  }

  // Use the hash to pick a color
  const index = Math.abs(hash) % colors.length
  return colors[index]
}

// Function to determine if a color is light or dark
const isLightColor = (hsl: { h: number; s: number; l: number }): boolean => {
  // Colors with lightness > 60% are considered light
  return hsl.l > 60
}

// Function to generate a hex color from text
const getHexColorFromText = (text?: string): { color: string; isLight: boolean } => {
  if (!text) return { color: '#7F8C8D', isLight: false } // Default to a neutral gray

  // Generate a hash from the text
  let hash = 0
  for (let i = 0; i < text.length; i++) {
    hash = text.charCodeAt(i) + ((hash << 5) - hash)
  }

  // More neutral colors with lower saturation and balanced lightness
  // Use a modulo with a smaller range to get more neutral hues
  // Neutral hues are often in the blue-green-gray spectrum (180-240)
  // or in the earthy tones (20-60)
  let h = Math.abs(hash) % 360 // Hue: 0-359

  // Adjust hue to favor more neutral colors
  // This creates a bias toward blues, greens, and earthy tones
  if (h > 60 && h < 180) {
    // Shift middle range hues toward blue-green
    h = 180 + (h % 60)
  } else if (h > 240 && h < 330) {
    // Shift purple-pink hues toward earthy tones
    h = 20 + (h % 40)
  }

  const s = 20 + (Math.abs(hash >> 8) % 25) // Saturation: 20-45% (more muted)
  const l = 60 + (Math.abs(hash >> 16) % 15) // Lightness: 60-75% (more neutral)

  const hslValues = { h, s, l }
  return {
    color: `hsl(${h}, ${s}%, ${l}%)`,
    isLight: isLightColor(hslValues),
  }
}

export const AppAvatar: React.FC<AppAvatarProps> = ({
  size,
  type,
  shape,
  status,
  color,
  src,
  text,
  textStyles,
}) => {
  // Determine color based on text if not explicitly provided
  const avatarColor = useMemo(() => color || getColorFromText(text), [color, text])

  // Generate a hex color for the background when using text
  const backgroundColorInfo = useMemo(() => {
    // If a predefined color is provided, use that
    if (color) return { color: undefined, isLight: false }

    // Otherwise generate a color from the text
    return getHexColorFromText(text)
  }, [color, text])

  return (
    <AppAvatarWrapper>
      <AppStyledAvatarWrapper size={size} shape={shape} color={color ? avatarColor : undefined}>
        {type === 'image' && <img src={src} alt="avatar" />}
        {type === 'text' && text}
        {type === 'mixed' && (
          <>
            {src ? (
              <img src={src} alt="avatar" />
            ) : (
              <span
                className={`rounded-full inline-flex justify-center text-white items-center font-semibold uppercase ${textStyles}`}
                style={{
                  backgroundColor:
                    backgroundColorInfo.color ||
                    `var(--app-${avatarColor.toLowerCase()}-80, var(--app-primary-80))`,
                  width: '100%',
                  height: '100%',
                  textShadow: backgroundColorInfo.isLight
                    ? 'none'
                    : '0px 1px 2px rgba(0, 0, 0, 0.2)',
                }}
              >
                {text}
              </span>
            )}
          </>
        )}
        {status && (
          <AppStyledDotWrapper>
            <AppStatusDot color={avatarColor ?? 'Green'} size={size} outline />
          </AppStyledDotWrapper>
        )}
      </AppStyledAvatarWrapper>
    </AppAvatarWrapper>
  )
}
