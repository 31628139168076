import React from 'react'

import { AppAlertIcon } from 'components/Common/AppSvgIcons/AppAlertIcon'
import {
  AppFieldWrapper,
  AppErrorWrapper,
  AppFieldParentWrapper,
  AppPrefixDividerWrapper,
  AppPrefixWrapper,
  AppErrorMessageWrapper,
  AppLabelWrapper,
  AppInputWrapper,
} from '../AppCommonStyles/AppFormField.style'
import appColors from 'appColors'
import { AppToolTip } from 'components/Common/AppToolTip/AppToolTip.component'
import { AppToolTipIcon } from 'components/Common/AppToolTip/AppToolTipIcon'
import moment from 'moment'

interface IconProps {
  color?: string
}

export interface AppTextFieldProps {
  mask?: string
  value?: string | number
  error?: string
  label?: string
  disabled?: boolean
  placeholder?: string
  prefix?: string
  size: 'lg' | 'md' | 'sm'
  type?: 'text' | 'password' | 'email' | 'date' | 'number'
  onChange?: (value) => void
  Icon?: React.ComponentType<IconProps>
  onBlur?: (value) => void
  name?: string
  toolTipText?: string
  LabelIcon?: React.ComponentType
  minDate?: string
  maxDate?: string
}

export const AppTextField: React.FC<AppTextFieldProps> = ({
  size,
  mask,
  Icon,
  value,
  label,
  disabled,
  onChange,
  onBlur,
  error = '',
  placeholder,
  type = 'text',
  prefix,
  name,
  toolTipText,
  LabelIcon,
  minDate,
  maxDate,
}) => {
  const today = moment().format('YYYY-MM-DD')

  const handleInputChange = (e) => {
    const inputValue = e.target.value

    // If the input type is 'date', validate the date format and check if valid
    if (type === 'date') {
      if (!moment(inputValue, 'YYYY-MM-DD', true).isValid()) {
        // Clear the input or keep the previous value
        e.target.value = ''
        return
      }
    }

    // Call the parent onChange handler
    onChange && onChange(e)
  }

  return (
    <div className="w-full">
      <div className="flex items-start gap-2 self-stretch ">
        {LabelIcon && (
          <div className="mb-2 flex items-center">
            <LabelIcon />
          </div>
        )}
        {label && (
          <AppLabelWrapper>
            {label}
            {toolTipText && (
              <AppToolTip
                name={'TextFieldToolTip'}
                text={toolTipText}
                variant="primary"
                place="bottom"
              >
                <AppToolTipIcon />
              </AppToolTip>
            )}
          </AppLabelWrapper>
        )}
      </div>
      <AppFieldParentWrapper>
        <AppFieldWrapper prefix={prefix} size={size} error={!!error} disabled={!!disabled}>
          {Icon && <Icon />}
          {prefix && (
            <>
              <AppPrefixWrapper size={size}>{prefix}</AppPrefixWrapper>
              <AppPrefixDividerWrapper size={size}>
                <div className="self-stretch bg-app-grey-40 relative w-px" />
              </AppPrefixDividerWrapper>
            </>
          )}
          <AppInputWrapper
            type={type}
            mask={mask}
            value={type === 'date' && value ? moment(value).format('YYYY-MM-DD') : value}
            disabled={disabled}
            onChange={handleInputChange}
            onBlur={onBlur}
            error={error}
            prefix={prefix}
            placeholder={placeholder || 'Placeholder'}
            data-testid={name}
            min={type === 'date' ? minDate ?? today : undefined}
            max={type === 'date' ? maxDate : undefined}
          />
        </AppFieldWrapper>
        {error && <AppErrorMessage error={error} />}
      </AppFieldParentWrapper>
    </div>
  )
}

export const AppErrorMessage = ({ error }) => {
  return (
    <AppErrorWrapper>
      <AppAlertIcon color={appColors['app-red']['70']} />
      <AppErrorMessageWrapper>{error}</AppErrorMessageWrapper>
    </AppErrorWrapper>
  )
}
