import { useMemo } from 'react'
import { toolsCategory } from '../data'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
export const useGetInput = (search: string, toolCategoryLabel: string) => {
  const searchFields = useMemo(() => {
    return {
      featured: (toolsCategory.Featured.label === toolCategoryLabel && isEmpty(search)) ?? false,
      inUserToolKit:
        (toolsCategory.toolKit.label === toolCategoryLabel && isEmpty(search)) ?? false,
      categories:
        toolsCategory.Featured.label === toolCategoryLabel ||
        toolsCategory.toolKit.label === toolCategoryLabel ||
        !isEmpty(search)
          ? []
          : [toolCategoryLabel],
    }
  }, [toolCategoryLabel, search])

  const { clientId } = useParams()
  const input = useMemo(() => {
    return {
      freeFormSearchQuery: isEmpty(search) ? '' : search,
      ...searchFields,
      userId: clientId,
    }
  }, [search, searchFields, clientId])

  return input
}
