import React, { FC, ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { ConnectedProps } from 'react-redux'
import ToolBubble from '../ToolCategoryPanel/ToolBubble/ToolBubble.component'
import { ToolsBubbleWrapperConnector } from '../Tools.connector'
import history from 'utils/history'
import { getRoleFromPath } from '../../../utils/helper'

interface IToolsBubbleWrapperProps {
  setSearch: (param: string) => void
  setOpen?: (param: boolean) => void
  children?: ReactNode
}

interface IToolsBubbleWrapperProps extends ConnectedProps<typeof ToolsBubbleWrapperConnector> {}

const ToolsBubbleWrapper = ToolsBubbleWrapperConnector<FC<IToolsBubbleWrapperProps>>(
  ({ tenantId, toolsCategoryData, setSearch, setOpen, children, resetGetSearchToolsAction }) => {
    const { toolSubTab } = useParams()

    return (
      <div className="flex flex-col h-full gap-40">
        <div className="flex flex-wrap gap-6 mdl:gap-3">
          {toolsCategoryData.map((category, index) => (
            <ToolBubble
              key={index}
              category={category}
              index={index}
              handleBubbleSelection={(category) => {
                if (category !== toolSubTab) {
                  resetGetSearchToolsAction()
                  setSearch('')
                  setTimeout(
                    () => history.push(`/${getRoleFromPath()}/${tenantId}/tools/${category}`),
                    0
                  )
                  setOpen && setOpen(false)
                }
              }}
              selectedBubble={toolSubTab}
            />
          ))}
        </div>
        {children && children}
      </div>
    )
  }
)

export default ToolsBubbleWrapper
