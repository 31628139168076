import React, { useEffect, useRef, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import moment from 'moment-timezone'

import { AppTextField } from 'components/Common/AppFormFields/AppTextField/AppTextField.component'
import { AppErrorMessage } from 'components/Common/AppErrorMessage/AppErrorMessage.component'
import { FieldLabel } from 'components/AppKanbanBoard/AppTask/AppTask.style'
import { TaskFormFields } from 'components/AppKanbanBoard/config'
import { AppBadge } from 'components/Common/AppBadge/AppBadge.component'
import { useFeatureFlag } from 'hooks/userFeatureFlag'
import { useSelector } from 'react-redux'
import { RootState } from 'App'

interface AppTaskDateFieldsProps {
  methods: UseFormReturn
  clickedFields: { [key: string]: boolean }
  handleClickStartDate: (field: string, isHovered: boolean) => void
  handleClickExpectedEndDate: (field: string, isHovered: boolean) => void
  isStartDateBeforePlanStartDate?: boolean
}

export const AppTaskDateFields: React.FC<AppTaskDateFieldsProps> = ({
  methods,
  clickedFields,
  handleClickStartDate,
  handleClickExpectedEndDate,
  isStartDateBeforePlanStartDate,
}) => {
  const startDateWrapperRef = useRef<HTMLDivElement | null>(null)
  const expectedEndDateWrapperRef = useRef<HTMLDivElement | null>(null)

  const {
    control,
    formState: { errors },
    unregister,
    register,
  } = methods

  const user = useSelector((state: RootState) => state.user.user)
  const allowPastEditableDates = useFeatureFlag(user?.id, 'operational-allow-past-task-dates')
  const [minDate, setMinDate] = useState<string | undefined>(undefined)
  const [maxDate, setMaxDate] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (allowPastEditableDates) {
      setMinDate(moment().add(-1, 'year').format('YYYY-MM-DD'))
      setMaxDate(moment().add(1, 'year').format('YYYY-MM-DD'))
    }
  }, [allowPastEditableDates, register, unregister])

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        startDateWrapperRef.current &&
        !startDateWrapperRef.current.contains(event.target as Node)
      ) {
        handleClickStartDate(TaskFormFields.startDate, false)
      }
      if (
        expectedEndDateWrapperRef.current &&
        !expectedEndDateWrapperRef.current.contains(event.target as Node)
      ) {
        handleClickExpectedEndDate(TaskFormFields.expectedEndDate, false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [handleClickStartDate, handleClickExpectedEndDate])

  return (
    <>
      <FieldLabel>Start date </FieldLabel>
      <div className="col-span-7 font-inter text-black-dark">
        <Controller
          name={TaskFormFields.startDate}
          control={control}
          render={({ field }) => (
            <>
              {!clickedFields.startDate ? (
                <div
                  className="col-span-7 font-inter text-black-dark"
                  onClick={() => handleClickStartDate(field.name, true)}
                >
                  <AppBadge
                    size="sm"
                    color={'White'}
                    type={'Outline'}
                    label={
                      field.value ? moment(field.value).format('MMM DD, YYYY') : 'Select start date'
                    }
                  />

                  {errors.startDate && !isStartDateBeforePlanStartDate && (
                    <AppErrorMessage id={field.name} size="sm">
                      {errors.startDate.message}
                    </AppErrorMessage>
                  )}
                  {!errors.startDate && isStartDateBeforePlanStartDate && (
                    <AppErrorMessage id={field.name} size="sm">
                      Start date cannot be before plan start date
                    </AppErrorMessage>
                  )}
                </div>
              ) : (
                <div className="flex items-center w-2/3" ref={startDateWrapperRef}>
                  <AppTextField
                    type="date"
                    minDate={minDate}
                    maxDate={maxDate}
                    placeholder="Enter start date"
                    size="sm"
                    error={errors.startData?.message as string}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e)
                    }}
                    onBlur={() => handleClickStartDate(field.name, false)}
                  />
                </div>
              )}
            </>
          )}
        />
      </div>

      <FieldLabel>Due date </FieldLabel>
      <div className="col-span-7 font-inter text-black-dark">
        <Controller
          name={TaskFormFields.expectedEndDate}
          control={control}
          render={({ field }) => (
            <>
              {!clickedFields.expectedEndDate ? (
                <div
                  className="col-span-7 font-inter text-black-dark"
                  onClick={() => handleClickExpectedEndDate(field.name, true)}
                >
                  <AppBadge
                    size="sm"
                    color={'White'}
                    type={'Outline'}
                    label={
                      field.value ? moment(field.value).format('MMM DD, YYYY') : 'Select due date'
                    }
                  />
                  {errors.expectedEndDate && (
                    <AppErrorMessage id={field.name} size="sm">
                      {errors.expectedEndDate.message}
                    </AppErrorMessage>
                  )}
                </div>
              ) : (
                <div className="flex items-center w-2/3" ref={expectedEndDateWrapperRef}>
                  <AppTextField
                    type="date"
                    minDate={minDate}
                    maxDate={maxDate}
                    size="sm"
                    placeholder="Enter due date"
                    error={errors.expectedEndDate?.message as string}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e)
                    }}
                    onBlur={() => handleClickExpectedEndDate(field.name, false)}
                  />
                </div>
              )}
            </>
          )}
        />
      </div>
    </>
  )
}
