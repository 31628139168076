import React, { useEffect, useRef, useMemo } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'

import { AppSelectField } from 'components/Common/AppFormFields/AppSelectField/AppSelectField.component'
import { FieldLabel, UserName } from 'components/AppKanbanBoard/AppTask/AppTask.style'
import { AppAvatar } from 'components/Common/AppAvatar/AppAvatar.component'
import { TaskFormFields } from 'components/AppKanbanBoard/config'
import { getInitials } from 'components/Chat/helper'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import { IAssigneeOptions } from 'appHooks/useGetBusinessProfile'

interface IAppTaskAssigneeFieldProps {
  methods: UseFormReturn
  clickedFields: { [key: string]: boolean }
  handleClick: (field: string, isHovered: boolean) => void
  userAvatar?: string
  assigneeOptionsParam: IAssigneeOptions[]
}

export const AppTaskAssigneeField: React.FC<IAppTaskAssigneeFieldProps> = ({
  methods,
  clickedFields,
  handleClick,
  userAvatar,
  assigneeOptionsParam,
}) => {
  const assigneWrapperRef = useRef<HTMLDivElement | null>(null)
  const currentUser = useSelector((state: RootState) => state.user.user)
  const teamMembers = useMemo(() => {
    return assigneeOptionsParam.map((option) => ({
      id: option.user.id,
      firstName: option.user.firstName,
      lastName: option.user.lastName,
    }))
  }, [assigneeOptionsParam])

  const assigneeOptions = useMemo(
    () =>
      teamMembers.map((member) => ({
        value: member.id,
        label: `${member.firstName} ${member.lastName}`,
      })),
    [teamMembers]
  )

  const {
    control,
    formState: { errors },
  } = methods

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (assigneWrapperRef.current && !assigneWrapperRef.current.contains(event.target as Node)) {
        handleClick(TaskFormFields.assigneeId, false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [handleClick])

  const getTeammember = (id: string) => teamMembers.find((member) => member.id === id)

  return (
    <>
      <FieldLabel>Assignee </FieldLabel>
      <div className="col-span-7 font-inter text-black-dark">
        <Controller
          name={TaskFormFields.assigneeId}
          control={control}
          defaultValue={currentUser.id}
          render={({ field }) => {
            const selectedMember = getTeammember(field.value)
            return (
              <>
                {clickedFields.assigneeId ? (
                  <div className="flex items-center w-2/3" ref={assigneWrapperRef}>
                    <AppSelectField
                      size="sm"
                      {...field}
                      options={assigneeOptions}
                      placeholder="Select assignee"
                      errors={errors.assigneeId?.message as string}
                      onBlur={() => {
                        handleClick(field.name, false)
                      }}
                      onChange={(e) => {
                        field.onChange(e.value)
                        handleClick(field.name, false)
                      }}
                    />
                  </div>
                ) : (
                  <div
                    className="flex justify-left gap-3 items-center"
                    onClick={() => handleClick(field.name, true)}
                  >
                    {selectedMember ? (
                      <>
                        <AppAvatar
                          size={'sm'}
                          type={'mixed'}
                          shape={'Rounded'}
                          text={getInitials({
                            firstName: selectedMember.firstName,
                            lastName: selectedMember.lastName,
                          })}
                        />
                        <UserName>
                          {`${selectedMember.firstName} ${selectedMember.lastName}`}
                        </UserName>
                      </>
                    ) : (
                      <div className="!font-inter text-black-dark">Select assignee</div>
                    )}
                  </div>
                )}
              </>
            )
          }}
        />
      </div>
    </>
  )
}
