import { AppBadge } from 'components/Common/AppBadge/AppBadge.component'
import styled from 'styled-components'
import tw from 'twin.macro'

export const AppContainer = styled.div`
  ${tw`border border-app-grey-40 rounded-xl shadow-xl`}
`

export const AppHeader = styled.div`
  ${tw`p-6 border-b border-app-grey-40`}
`

export const AppTitle = styled.div`
  ${tw`font-inter font-medium text-lg text-app-black-100 mb-4`}
`

export const AppDescription = styled.div`
  ${tw`font-inter font-normal text-sm text-app-grey-70 mb-4`}
`

export const AppSection = styled.div`
  ${tw`p-6`}
`

export const AppCategoryContainer = styled.div`
  ${tw`border-b border-app-grey-40 pb-4 mb-4 last:mb-0 last:border-b-0`}
`

export const AppCategoryHeader = styled.div`
  ${tw`flex justify-between items-center cursor-pointer`}
`

export const AppCategoryName = styled.div`
  ${tw`flex items-center gap-2`}
`

export const AppCategoryText = styled.span`
  ${tw`font-inter font-normal text-sm text-app-black-100`}
`

export const AppViewContainer = styled.div`
  ${tw`flex gap-1 items-center`}
`

export const AppViewBadge = styled(AppBadge)`
  ${tw`text-sm`}
`

export const AppChevronIcon = styled.div<{ rotated: boolean }>`
  ${tw`transform transition-transform`}
  ${({ rotated }) => rotated && tw`rotate-180`}
`

export const AppGoalsList = styled.div`
  ${tw`mt-4 space-y-3`}
`

export const AppGoalItem = styled.div`
  ${tw`flex items-center px-6 py-4 border border-app-grey-40 rounded-lg shadow-sm`}
`

export const AppGoalWrapper = styled.div`
  ${tw`w-[90%]`}
`

export const AppGoalHeading = styled.div`
  ${tw`mb-2`}
`

export const AppGoalText = styled.div`
  ${tw`font-inter font-normal text-sm text-app-grey-70 mb-4`}
`

export const AppGoalChevron = styled.div`
  ${tw`w-5 cursor-pointer`}
`

export const AppNoTemplateText = styled.div`
  ${tw`text-sm text-app-grey-70 mt-4`}
`
