import React from 'react'
import { FC } from 'react'
import { Tool } from '__generated__/api-types-and-hooks'
import BookMarkIcon from 'components/Common/SvgIcons/BookmarkIcon'
import GradientCircleNotchIcon from 'components/Common/SvgIcons/GradientCircleNotchIcon'
import useBookmark from '../hooks/bookmarkTool.hooks'

interface IToolBookmark {
  tool: Tool
  readonly?: boolean
}

const BookmarkTool: FC<IToolBookmark> = ({ tool, readonly }) => {
  const { handleClick, isBookmarkingInProgress } = useBookmark(tool)
  return (
    <span className="flex gap-1 items-center text-primary text-xs font-[650] relative">
      <div className="absolute right-4">
        {isBookmarkingInProgress && <GradientCircleNotchIcon width="30" height="30" />}
      </div>
      <span
        onClick={() => !readonly && handleClick(tool)}
        className={`${readonly ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
      >
        <BookMarkIcon
          stroke="#18181B"
          className={`${tool.isBookmarked ? 'fill-black-light' : ''} ${
            isBookmarkingInProgress || readonly ? 'pointer-events-none cursor-not-allowed' : ''
          }`}
        />
      </span>
    </span>
  )
}

export default BookmarkTool
