import React, { useState } from 'react'

import { isJsonString } from 'utils/helper'
import useDownloadPDF from 'hooks/useDownloadPdf'
import { Control, Controller, FieldValues } from 'react-hook-form'

import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import Markdown from 'react-markdown'
import { FORM } from 'appConfig/enums'
import hookForms from 'utils/hookForms'

import { UserTask } from '__generated__/api-types-and-hooks'
import AppTutorialError from 'appAssets/AppTutorialError.png'
import { AppButton } from 'components/Common/AppButton/AppButton.component'
import { AppModalBox } from 'components/Common/AppModalBox/AppModalBox.component'
import { AppVideoPlayer } from 'components/Common/AppVideoPlayer/AppVideoPlayer.component'
import { AppGenerateTutorialLoader } from 'components/Common/AppSvgIcons/AppGenerateTutorialLoader'

interface IAppGenerateTutorial {
  task: UserTask
  planName: string
  isLoading: boolean
  isViewMode: boolean
  generatedHowToDo: string
  tutorialNotFound: boolean
  control: Control<FieldValues>
}

export const AppGenerateTutorial: React.FC<IAppGenerateTutorial> = ({
  task,
  control,
  planName,
  isLoading,
  isViewMode,
  generatedHowToDo,
  tutorialNotFound,
}) => {
  const [showVideoTutorialModal, setShowVideoTutorialModal] = useState(false)
  const downloadPdf = useDownloadPDF()
  const data = hookForms.getForm(FORM.USER_TASK_FORM)?.getValues()

  const cleanText = (text) => {
    return text.replace(/\*\*/g, '').trim()
  }
  const processUrls = (urlInput) => {
    let urls = isJsonString(urlInput) ? JSON.parse(urlInput) : urlInput
    if (typeof urls === 'string') {
      const slicedUrls = urls.split(',')
      return slicedUrls.map((url: string) => <AppVideoPlayer key={url} url={url} />)
    }
    return urls.map((url: string) => (
      <div className="w-full mt-5">
        <AppVideoPlayer key={url} url={url} />
      </div>
    ))
  }

  return (
    <div>
      {isLoading ? (
        <AppGenerateTutorialLoader />
      ) : tutorialNotFound ? (
        <img className="mt-4 rounded-2xl" src={AppTutorialError} alt="Not Found" />
      ) : (
        isViewMode &&
        (task?.howToDo || generatedHowToDo) && (
          <div>
            <div className="flex justify-between items-center mt-4 gap-4 flex-wrap">
              <div className="flex items-center gap-2">
                <div className="text-sm font-inter font-normal text-app-grey-50">Tutorial</div>
                <div>/</div>
                <div>Text</div>
              </div>
              <div>
                <div className="flex gap-4 mb-4">
                  <AppButton
                    size="md"
                    variant="secondary"
                    label="Video Tutorial"
                    onClick={() => setShowVideoTutorialModal(true)}
                  />
                  <AppButton
                    label="Download PDF"
                    variant="primary"
                    size="md"
                    onClick={() => {
                      downloadPdf(
                        null,
                        `How-to-do-${(task?.name || '').replace(/ /g, '-')}.pdf`,
                        `${data.name} Tutorial`,
                        data.instructionForTask,
                        cleanText(generatedHowToDo || task?.howToDo || '')
                      )
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      )}
      <div className="p-1">
        <div className="text-sm text-app-black-10 mt-6 ">
          <Markdown remarkPlugins={[remarkGfm, remarkMath]}>
            {generatedHowToDo || task?.howToDo || ''}
          </Markdown>
        </div>
      </div>

      {showVideoTutorialModal && (
        <AppModalBox
          title={`Tutorial / Video`}
          height="max-h-[95vh] min-h-[50vh] h-fit"
          width="w-[60vw]"
          showBorder={false}
          titleSize="sm"
          onBack={() => {
            setShowVideoTutorialModal(false)
          }}
          onClose={() => {
            setShowVideoTutorialModal(false)
          }}
        >
          <div className="p-7">
            {!isLoading && (!data.howToLink || data.howToLink.length === 0) && (
              <Controller
                control={control}
                name="howToLinks"
                render={({ field }) => {
                  if (!field?.value || field?.value.length === 0 || !field?.value[0].url)
                    return null
                  return processUrls(field?.value[0].url)
                }}
              />
            )}
          </div>
        </AppModalBox>
      )}
    </div>
  )
}
