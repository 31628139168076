import styled from 'styled-components'
import tw from 'twin.macro'

import appColors from 'appColors'
import { BadgeSize, BadgeColor, BadgeType } from 'components/Common/AppBadge/AppBadge.component'

interface IAppBadgeWrapperProps {
  size: BadgeSize
  color: BadgeColor
  type: BadgeType
}

export const AppBadgeWrapper = styled.div<IAppBadgeWrapperProps>`
  ${tw`inline-flex items-center font-medium tracking-wide text-center`};

  /* Size Styles */
  ${({ size }) => size === 'xs' && tw`py-[2px] px-[4px] gap-1 text-xs leading-4 rounded-md`}
  ${({ size }) => size === 'sm' && tw`py-[2px] px-[6px] gap-[6px] text-sm leading-5 rounded-md`}
  ${({ size }) => size === 'md' && tw`py-[6px] px-[8px] gap-2 text-base leading-5 rounded-lg`}

  /* Color and Type Styles */
  ${({ color, type }) => {
    switch (color) {
      case 'Grey':
        return type === 'Outline'
          ? tw`bg-app-grey-20 text-app-grey-60 border border-app-grey-40`
          : type === 'Filled'
          ? tw`bg-app-grey-20 text-app-grey-60`
          : tw`text-app-grey-60`
      case 'Primary':
        return type === 'Outline'
          ? tw`bg-app-primary-20 text-app-primary-70 border border-app-primary-40`
          : type === 'Filled'
          ? tw`bg-app-primary-20 text-app-primary-70`
          : tw`text-app-primary-70`
      case 'Red':
        return type === 'Outline'
          ? tw`bg-app-red-25 text-app-red-65 border border-app-red-40`
          : type === 'Filled'
          ? tw`bg-app-red-25 text-app-red-65`
          : tw`text-app-red-65`
      case 'Green':
        return type === 'Outline'
          ? tw`bg-app-green-20 text-app-green-65 border border-app-green-40`
          : type === 'Filled'
          ? tw`bg-app-green-20 text-app-green-65`
          : tw`text-app-green-65`
      case 'Orange':
        return type === 'Outline'
          ? tw`bg-app-orange-20 text-app-orange-65 border border-app-orange-40`
          : type === 'Filled'
          ? tw`bg-app-orange-20 text-app-orange-65`
          : tw`text-app-orange-65`
      case 'White':
        return type === 'Outline'
          ? tw`bg-app-white-100 text-app-grey-60 border border-app-grey-40`
          : type === 'Filled'
          ? tw`bg-app-white-100 text-app-grey-60`
          : tw`text-app-grey-60`
      case 'Disabled':
        return type === 'Outline'
          ? tw`bg-app-grey-20 text-app-grey-40 border border-app-grey-40`
          : type === 'Filled'
          ? tw`bg-app-grey-20 text-app-grey-60`
          : tw`text-app-grey-60`
      default:
        return ''
    }
  }}
`

interface IDotProps {
  size: BadgeSize
  color: BadgeColor
}

export const AppDot = styled.span<IDotProps>`
  ${tw`w-1.5 h-1.5 rounded-full`};

  ${({ color }) => color === 'Grey' && tw`bg-app-grey-60`}
  ${({ color }) => color === 'Primary' && tw`bg-app-primary-70`}
  ${({ color }) => color === 'Red' && tw`bg-app-red-65`}
  ${({ color }) => color === 'Green' && tw`bg-app-green-65`}
  ${({ color }) => color === 'Orange' && tw`bg-app-orange-65`}
  ${({ color }) => color === 'White' && tw`bg-app-grey-60`}
`

interface IBadgeLabelProps {
  color: string
}

export const AppBadgeLabel = styled.div<IBadgeLabelProps>`
  ${tw`inline-block items-center font-medium p-1 tracking-wide mx-auto`};

  ${({ color }) => color === 'Grey' && tw` text-app-grey-60`}
  ${({ color }) => color === 'Primary' && tw` text-app-primary-70`}
  ${({ color }) => color === 'Red' && tw` text-app-red-65`}
  ${({ color }) => color === 'Green' && tw` text-app-green-65`}
  ${({ color }) => color === 'Orange' && tw` text-app-orange-65`}
  ${({ color }) => color === 'Disabled' && tw` text-app-grey-50`}
   ${({ color }) => color === 'White' && tw` text-app-grey-60`}
`

// AppBadge icons color
export const colorMap = {
  Grey: appColors['app-grey']['60'],
  Primary: appColors['app-primary']['70'],
  Red: appColors['app-red']['65'],
  Green: appColors['app-green']['65'],
  Orange: appColors['app-orange']['65'],
  White: appColors['app-grey']['60'],
}
