import ModalBox from 'components/Common/ModalBox'
import React, { FC } from 'react'
import ToolsBubbleWrapper from './ToolsBubbleWrapper.component'

interface IToolsBubbleMobileProps {
  setSearch: (param: string) => void
  setOpen: (param: boolean) => void
  open: boolean
}

const ToolsBubbleMobile: FC<IToolsBubbleMobileProps> = ({ setSearch, setOpen, open }) => {
  return (
    <div className="mdl:hidden">
      {open && (
        <ModalBox onClose={() => setOpen(false)} height="h-screen">
          <div className="bg-white h-full py-12 px-3">
            <ToolsBubbleWrapper setSearch={setSearch} setOpen={setOpen} />
          </div>
        </ModalBox>
      )}
    </div>
  )
}

export default ToolsBubbleMobile
