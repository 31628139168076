import React, { FC } from 'react'
import { IOption } from 'types'

interface IAppToolBubble {
  category: IOption
  selectedBubble: string
  index: number
  handleBubbleSelection: (p) => void
}

export const AppToolBubble: FC<IAppToolBubble> = ({
  category,
  selectedBubble,
  index,
  handleBubbleSelection,
}) => {
  return (
    <div
      key={index}
      className={`border ${
        category.value === selectedBubble
          ? 'bg-blue-lighter2x border-primary-brandBlueLight'
          : ' border-primary-disabled'
      } px-6 py-[10px] rounded-full  text-silver text-lg font-[650] w-fit cursor-pointer`}
      onClick={() => handleBubbleSelection(category.value)}
    >
      {category.label}
    </div>
  )
}
