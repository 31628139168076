import crossIcon from '../../assets/svgs/cross-icon.svg'
import styled from 'styled-components'

export const InputWrapper = styled.span`
  input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    content: URL(crossIcon);
    background: url(${crossIcon});
    background-size: 10px;
    padding-bottom: 5px;
    background-repeat: no-repeat;
    z-index: 4;
  }
`
