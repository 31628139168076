import React, { useEffect } from "react"
import { ChatSessionStatus, GetChatSessionsQuery, OnboardingFlowType, useCreateFlowChatSessionMutation } from "__generated__/api-types-and-hooks"
import { AppAIChatPopup } from "components/AppOnboarding/AppRooms"
import { AppButton } from "components/Common/AppButton/AppButton.component"
import { AppModalBox } from "components/Common/AppModalBox/AppModalBox.component"
import { useState } from "react"
import Avatar from 'appAssets/appImages/Avatar.png'
import { AppAvatar } from 'components/Common/AppAvatar/AppAvatar.component'
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "App"
import { getInitials } from "components/Chat/helper"

export const StartNewRegenModal = (props: {
    refetchGoals?: ({ hasFilter }: { hasFilter?: boolean }) => void
    chatSessions: GetChatSessionsQuery['getChatSessions']
    isModalOpen: boolean
    onClose: () => void
    goalId: string
    refetchChatSessions?: () => void
}) => {
    const [sessionId, setSessionId] = useState('')
    const [isAIChatOpen, setIsAIChatOpen] = useState(false)
    const { mutate: createFlowChatSessionMutate } = useCreateFlowChatSessionMutation()
    const { clientId } = useParams()
    const user = useSelector((state: RootState) => state.user.user)
    const lastSessionIncomplete = props.chatSessions?.find(
        (session) => {
            if(!session.metadata) return false
            const metadata = typeof JSON.parse(session.metadata) === 'string' ? JSON.parse(JSON.parse(session.metadata)) : JSON.parse(session.metadata)
            return metadata.goalId === props.goalId && session.flow === OnboardingFlowType.RegeneratePlan && session.status !== ChatSessionStatus.Completed
        }
    )
    useEffect(() => {
        if(!lastSessionIncomplete && props.isModalOpen) {
            createFlowChatSessionMutate(
                {
                  input: {
                    flow: OnboardingFlowType.RegeneratePlan,
                    ...(clientId ? { businessId: clientId } : {}),
                    metadata: JSON.stringify({
                        goalId: props.goalId,
                      }),
                  },
                },
                {
                  onSuccess: (res) => {
                    setSessionId(res.createFlowChatSession.id)
                    setIsAIChatOpen(true)
                    props.refetchChatSessions?.()
                  },
                  onSettled: () => {
                    props.onClose()
                  },
                }
              )
        }
    }, [clientId, createFlowChatSessionMutate, lastSessionIncomplete, props, props.isModalOpen])
    return (
        <div>
        {props.isModalOpen && lastSessionIncomplete && (
        <AppModalBox
          title="Incomplete Regenerate Plan"
          onClose={props.onClose}
          width="w-[600px]"
        >
          <div className="flex flex-col gap-4 px-6 py-6">
            <p className="text-base text-app-grey-75 text-left">It looks like you were in the middle of a conversation before. Would you like to continue where you left off or start fresh?</p>
            <div className="flex justify-end gap-3">
              <AppButton
                variant="secondary"
                size="lg"
                label="Start New"
                onClick={() => {
                  createFlowChatSessionMutate(
                    {
                      input: {
                        flow: OnboardingFlowType.RegeneratePlan,
                        ...(clientId ? { businessId: clientId } : {}),
                        metadata: JSON.stringify({
                            goalId: props.goalId,
                          }),
                      },
                    },
                    {
                      onSuccess: (res) => {
                        setSessionId(res.createFlowChatSession.id)
                        setIsAIChatOpen(true)
                      },
                      onSettled: () => {
                        props.onClose()
                      },
                    }
                  )
                }}
              />
              <AppButton
                variant="primary"
                size="lg"
                label="Continue"
                onClick={() => {
                    props.onClose()
                    setSessionId(lastSessionIncomplete?.id || '')
                    setIsAIChatOpen(true)
                    
                }}
              />
                </div>
                </div>
            </AppModalBox>
            )}
            <AppAIChatPopup
              isOpen={isAIChatOpen}
              onClose={() => setIsAIChatOpen(false)}
              sessionId={sessionId}
              refetchBusinessGoal={() => {
                props.refetchGoals?.({ hasFilter: true })
                props.refetchGoals?.({})
              }}
              title={<div className='flex items-center gap-3'>
                <AppAvatar src={Avatar} size={'md'} type={'image'} shape={'Rounded'} />
                <div className='text-md font-medium'>Coach Logic</div>
              </div>}
              userInitial={getInitials({
                firstName: user?.firstName || '',
                lastName: user?.lastName || '',
              })}
            />
        </div>
    )
}