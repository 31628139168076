import { useState } from 'react'
import { UserFile } from '__generated__/api-types-and-hooks'

interface FileAttachment {
  id: string
  fileName: string
  fileUrl: string | null
}

interface UseCommentProps {
  onSubmit: (content: string, fileIds: string[], mentionedUserIds: string[]) => void
  uploadFileAction: (options: { files: FileList; isMultiple: boolean; saveFile: boolean }) => void
  uploadedFileId: string
  uploadFileLoading: boolean
  uploadedFileName: string
  uploadedFileUrl: string | null
}

export const useComment = ({
  onSubmit,
  uploadFileAction,
  uploadedFileId,
  uploadedFileName,
  uploadedFileUrl,
  uploadFileLoading,
}: UseCommentProps) => {
  const [comment, setComment] = useState('')
  const [attachments, setAttachments] = useState<FileAttachment[]>([])
  const [uploadedFile, setUploadedFile] = useState<UserFile>()

  // Handle file upload
  const handleFileUpload = (file: File) => {
    setUploadedFile({
      id: uploadedFileId,
      name: file.name,
      size: file.size.toString(),
      url: uploadedFileUrl,
    })
    uploadFileAction({
      files: [file] as unknown as FileList,
      isMultiple: false,
      saveFile: true,
    })
  }

  // Handle successful file upload
  const handleFileUploadSuccess = () => {
    if (!uploadFileLoading && uploadedFileId && uploadedFile) {
      const newAttachment: FileAttachment = {
        id: uploadedFileId,
        fileName: uploadedFileName || 'Untitled',
        fileUrl: uploadedFileUrl || null,
      }
      setAttachments((prev) => [...prev, newAttachment])
      setUploadedFile(undefined)
    }
  }

  // Handle comment submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (!comment.trim() && attachments.length === 0) return

    const commentContent = {
      text: comment.trim(),
      attachments: attachments.map((att) => ({
        id: att.id,
        fileName: att.fileName,
        fileUrl: att.fileUrl,
      })),
    }

    onSubmit(
      JSON.stringify(commentContent),
      attachments.map((att) => att.id),
      []
    )
    setComment('')
    setAttachments([])
  }

  return {
    comment,
    setComment,
    attachments,
    handleFileUpload,
    handleFileUploadSuccess,
    handleSubmit,
  }
}
