import React from 'react'
import { EmojiHappyIcon } from '@heroicons/react/outline'
import moment from 'moment-timezone'
import { TaskComment, UserProfile } from '__generated__/api-types-and-hooks'
import { AppAvatar } from 'components/Common/AppAvatar/AppAvatar.component'
import { AppEmojiPicker } from '../../../AppShared/AppEmojiPicker/AppEmojiPicker.component'
import { FileAttachmentComment } from './AppComment.component'
import clsx from 'clsx'
import { AppCommentInput } from './AppCommentInput.component'
import { AppButton } from 'components/Common/AppButton/AppButton.component'
import { AppBinIcon } from 'components/Common/AppSvgIcons/AppBinIcon'
import PencilSquareIcon from 'assets/svgs/pencil-square.svg'
import { TeamMember } from 'hooks/useGetTeammember'
import './comment.css'

interface CommentContentProps {
  comment: TaskComment
  isReply?: boolean
  user: UserProfile
  replyToId: string | null
  expandedComments: Set<string>
  handleReaction: (commentId: string, emoji: string) => void
  setReplyToId: (id: string | null) => void
  toggleReplies: (commentId: string) => void
  getReactionsForComment: (
    comment: TaskComment
  ) => Array<{ type: string; id: string; userId: string }>
  replyText: string
  setReplyText: (text: string) => void
  isAddingComment: boolean
  teamMembers: TeamMember[]
  replyAttachments: Array<{ id: string; fileName: string; fileUrl: string | null }>
  handleReplyFileUpload: (file: File) => void
  onEditComment: (commentId: string, content: string) => void
  isEditingComment: boolean
  onDeleteComment: (commentId: string) => void
  isDeletingComment: boolean
  handleSubmitReply: (e: React.FormEvent, commentId: string, parentId?: string) => void
  uploadFileLoading: boolean
}

export const CommentContent: React.FC<CommentContentProps> = ({
  comment,
  isReply = false,
  user,
  replyToId,
  expandedComments,
  handleReaction,
  setReplyToId,
  toggleReplies,
  getReactionsForComment,
  replyText,
  setReplyText,
  isAddingComment,
  teamMembers,
  replyAttachments = [],
  handleReplyFileUpload,
  onEditComment,
  isEditingComment,
  onDeleteComment,
  isDeletingComment,
  handleSubmitReply,
  uploadFileLoading,
}) => {
  const [isEditing, setIsEditing] = React.useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false)
  const [editContent, setEditContent] = React.useState('')
  const [editAttachments, setEditAttachments] = React.useState<
    Array<{ id: string; fileName: string; fileUrl: string | null }>
  >([])
  const [showEmojiPicker, setShowEmojiPicker] = React.useState(false)
  const deleteConfirmRef = React.useRef<HTMLDivElement>(null)
  const emojiPickerRef = React.useRef<HTMLDivElement>(null)

  // Add click outside handler for emoji picker
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target as Node)) {
        setShowEmojiPicker(false)
      }
    }

    if (showEmojiPicker) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showEmojiPicker])

  // Add click outside handler for delete confirmation
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (deleteConfirmRef.current && !deleteConfirmRef.current.contains(event.target as Node)) {
        setShowDeleteConfirm(false)
      }
    }

    if (showDeleteConfirm) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showDeleteConfirm])

  const handleDelete = () => {
    if (isDeletingComment) return
    onDeleteComment(comment.id)
    setShowDeleteConfirm(false)
  }

  const handleTrashClick = () => {
    if (!isDeletingComment) {
      setShowDeleteConfirm(true)
    }
  }

  const handleStartEdit = () => {
    try {
      const parsedContent = JSON.parse(comment.content)
      setEditContent(parsedContent.text || '')
      setEditAttachments(parsedContent.attachments || [])
    } catch (e) {
      setEditContent(comment.content)
      setEditAttachments([])
    }
    setIsEditing(true)
  }

  const handleSaveEdit = (e: React.FormEvent) => {
    e.preventDefault()
    onEditComment(
      comment.id,
      JSON.stringify({
        text: editContent,
        attachments: editAttachments,
      })
    )
    setIsEditing(false)
  }

  const handleCancelEdit = () => {
    setIsEditing(false)
    setEditContent('')
    setEditAttachments([])
  }

  const handleEditFileUpload = (file: File) => {
    handleReplyFileUpload(file)
  }

  const isUserComment = user.id === comment.createdById
  const backgroundColor = isUserComment ? 'bg-app-primary-125' : 'bg-app-white-95'
  const textColor = isUserComment ? 'text-white' : 'text-app-grey-65'
  const createdName = isUserComment
    ? 'You'
    : `${comment.createdBy?.firstName} ${comment.createdBy?.lastName}`
  const reactions = getReactionsForComment(comment)
  const hasReplies = !isReply && comment.replies && comment.replies.length > 0
  const isExpanded = expandedComments.has(comment.id)

  const formatContent = (content: string) => {
    return content.replace(/@(\w+)/g, '<span class="text-indigo-600">@$1</span>')
  }

  const onEmojiClick = (emojiData: any) => {
    handleReaction(comment.id, emojiData.emoji)
    setShowEmojiPicker(false)
  }

  const lastReply = comment.replies?.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  )[0]

  const renderCommentContent = (content: string) => {
    try {
      const parsedContent = JSON.parse(content)
      return (
        <>
          <p
            className={clsx('text-sm whitespace-pre-wrap', textColor)}
            dangerouslySetInnerHTML={{ __html: formatContent(parsedContent.text || '') }}
          />
          {parsedContent.attachments && parsedContent.attachments.length > 0 && (
            <div className={`mt-2 flex flex-wrap gap-2 ${textColor}`}>
              {parsedContent.attachments.map(
                (attachment: { id: string; fileName: string; fileUrl: string | null }) => (
                  <FileAttachmentComment
                    key={attachment.id}
                    fileName={attachment.fileName}
                    fileUrl={attachment.fileUrl}
                    textColor={textColor}
                  />
                )
              )}
            </div>
          )}
        </>
      )
    } catch (e) {
      // If content is not JSON, treat it as plain text (for backward compatibility)
      return (
        <p
          className={clsx('text-sm whitespace-pre-wrap', textColor)}
          dangerouslySetInnerHTML={{ __html: formatContent(content) }}
        />
      )
    }
  }
  const sortedReplies = comment.replies?.sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  )
  return (
    <div className="flex flex-col w-full">
      <div className={clsx('flex items-start gap-3', isReply && 'pl-12')}>
        <AppAvatar
          size="sm"
          color="Primary"
          type="text"
          shape="Rounded"
          text={
            (comment.createdBy?.firstName?.[0] ?? '') + (comment.createdBy?.lastName?.[0] ?? '')
          }
        />
        <div className="flex-1">
          <div className="flex items-start gap-2 w-full">
            <div className={clsx('rounded-lg px-4 py-3 w-full', backgroundColor)}>
              <div className="flex items-center justify-between mb-1">
                <div className="flex items-center gap-2">
                  <span
                    className={clsx('font-medium', isUserComment ? 'text-white' : 'text-gray-900')}
                  >
                    {createdName}
                  </span>
                  <span
                    className={clsx('text-sm', isUserComment ? 'text-white/70' : 'text-gray-500')}
                  >
                    — {moment(comment.createdAt).fromNow()}
                  </span>
                </div>
                {isUserComment && (
                  <div className="flex items-center gap-2 relative">
                    <div
                      onClick={handleStartEdit}
                      className={clsx('cursor-pointer', isUserComment ? 'brightness-0 invert' : '')}
                    >
                      <img src={PencilSquareIcon} alt="pencil-square" className="w-6 h-6" />
                    </div>
                    <div
                      onClick={handleTrashClick}
                      className={clsx(
                        'cursor-pointer',
                        isUserComment ? 'text-white' : 'text-gray-600',
                        isDeletingComment && 'opacity-50 cursor-not-allowed'
                      )}
                    >
                      <AppBinIcon stroke={'white'} />
                    </div>
                    {showDeleteConfirm && (
                      <div
                        ref={deleteConfirmRef}
                        className="absolute right-0 top-6 bg-white shadow-lg rounded-md p-4 z-10 border border-gray-200 min-w-[200px]"
                      >
                        <p className="text-sm text-app-gray-600 mb-3">
                          Are you sure you want to delete this comment?
                        </p>
                        <div className="flex justify-end gap-2">
                          <AppButton
                            variant="primary"
                            label="Cancel"
                            onClick={() => {
                              setShowDeleteConfirm(false)
                            }}
                            size="xs"
                          >
                            Cancel
                          </AppButton>
                          <AppButton
                            variant="secondary"
                            label={isDeletingComment ? 'Deleting...' : 'Delete'}
                            onClick={handleDelete}
                            disabled={isDeletingComment}
                            size="xs"
                          >
                            {isDeletingComment ? 'Deleting...' : 'Delete'}
                          </AppButton>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {isEditing ? (
                <div className="mt-2">
                  <AppCommentInput
                    onFileUpload={handleEditFileUpload}
                    uploadFileLoading={uploadFileLoading}
                    value={editContent}
                    onChange={setEditContent}
                    onSubmit={handleSaveEdit}
                    isLoading={isEditingComment}
                    showAvatar={false}
                    attachments={editAttachments}
                    teamMembers={teamMembers}
                    placeholder="Edit your comment..."
                  />
                  <div className="mt-2 flex justify-end gap-2">
                    <AppButton
                      variant="secondary"
                      label="Cancel"
                      onClick={handleCancelEdit}
                      size="xs"
                    >
                      Cancel
                    </AppButton>
                  </div>
                </div>
              ) : (
                renderCommentContent(comment.content)
              )}
            </div>
          </div>

          <div className="mt-2 flex items-center gap-3">
            {reactions.length > 0 && (
              <div className="flex items-center gap-1">
                {Object.entries(
                  reactions.reduce(
                    (acc: Record<string, { count: number; hasUserReacted: boolean }>, reaction) => {
                      if (!acc[reaction.type]) {
                        acc[reaction.type] = { count: 0, hasUserReacted: false }
                      }
                      acc[reaction.type].count++
                      if (reaction.userId === user.id) {
                        acc[reaction.type].hasUserReacted = true
                      }
                      return acc
                    },
                    {}
                  )
                ).map(([type, { count, hasUserReacted }]) => (
                  <div
                    key={type}
                    className={clsx(
                      'flex items-center gap-1 px-2 py-1 rounded-full text-sm cursor-pointer',
                      hasUserReacted
                        ? 'bg-app-blue-600 text-white'
                        : 'bg-app-white-95 hover:bg-app-grey-40'
                    )}
                    onClick={() => handleReaction(comment.id, type)}
                  >
                    <span>{type}</span>
                    <span className={hasUserReacted ? 'text-white/80' : 'text-gray-600'}>
                      {count}
                    </span>
                  </div>
                ))}
              </div>
            )}
            <div className="flex items-center gap-2 text-gray-500">
              <button
                className="hover:text-gray-700"
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              >
                <EmojiHappyIcon className="w-5 h-5" />
              </button>
              {showEmojiPicker && (
                <div className="absolute z-10" ref={emojiPickerRef}>
                  <AppEmojiPicker
                    onEmojiClick={onEmojiClick}
                    isOpen={true}
                    onToggle={() => setShowEmojiPicker(false)}
                  />
                </div>
              )}
              {!comment.parentId && (
                <button
                  className="text-sm hover:text-gray-700"
                  onClick={() => setReplyToId(replyToId === comment.id ? null : comment.id)}
                >
                  Reply
                </button>
              )}
            </div>
          </div>

          {hasReplies && (
            <div className="flex justify-between">
              <div className="mt-2 flex items-center gap-2">
                <div className="flex -space-x-2">
                  {comment.replies?.slice(0, 2).map((reply, index) => (
                    <AppAvatar
                      key={index}
                      size="xs"
                      color="Primary"
                      type="text"
                      shape="Rounded"
                      text={
                        (reply.createdBy?.firstName?.[0] ?? '') +
                        (reply.createdBy?.lastName?.[0] ?? '')
                      }
                    />
                  ))}
                </div>
                <button
                  className="text-app-primary-125 text-sm hover:text-app-primary-125"
                  onClick={() => toggleReplies(comment.id)}
                >
                  {comment.replies?.length} {comment.replies?.length === 1 ? 'reply' : 'replies'}
                </button>
                <span className="text-gray-500 text-sm">
                  Last reply {moment(lastReply?.createdAt).fromNow()}
                </span>
              </div>
              <div
                className="text-app-primary-125 mt-2 text-sm hover:text-app-primary-125 cursor-pointer"
                onClick={() => toggleReplies(comment.id)}
              >
                {isExpanded ? 'Hide thread' : 'View thread'}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Replies container */}
      {!isReply && hasReplies && isExpanded && (
        <div className="mt-2 space-y-2">
          {sortedReplies?.map((reply) => (
            <CommentContent
              key={reply.id}
              comment={reply as TaskComment}
              isReply={true}
              user={user}
              replyToId={replyToId}
              expandedComments={expandedComments}
              handleReaction={handleReaction}
              setReplyToId={setReplyToId}
              toggleReplies={toggleReplies}
              getReactionsForComment={getReactionsForComment}
              replyText={replyText}
              setReplyText={setReplyText}
              isAddingComment={isAddingComment}
              teamMembers={teamMembers}
              replyAttachments={replyAttachments}
              handleReplyFileUpload={handleReplyFileUpload}
              onEditComment={onEditComment}
              isEditingComment={isEditingComment}
              onDeleteComment={onDeleteComment}
              isDeletingComment={isDeletingComment}
              handleSubmitReply={handleSubmitReply}
              uploadFileLoading={uploadFileLoading}
            />
          ))}
        </div>
      )}

      {/* Reply form using AppCommentInput */}
      {replyToId === comment.id && (
        <div className={clsx('mt-2', isReply ? 'pl-12' : 'pl-12')}>
          <AppCommentInput
            uploadFileLoading={uploadFileLoading}
            onFileUpload={handleReplyFileUpload}
            value={replyText}
            onChange={setReplyText}
            onSubmit={(e) => handleSubmitReply(e, comment.id, isReply ? comment.id : undefined)}
            isLoading={isAddingComment}
            showAvatar={true}
            avatarText={(user?.firstName?.[0] ?? '') + (user?.lastName?.[0] ?? '')}
            attachments={replyAttachments}
            teamMembers={teamMembers}
            placeholder="Write a reply..."
          />
        </div>
      )}
    </div>
  )
}
