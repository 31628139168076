import React from 'react'
import { FC } from 'react'
import { Tool } from '__generated__/api-types-and-hooks'
import BookMarkIcon from 'components/Common/SvgIcons/BookmarkIcon'
import GradientCircleNotchIcon from 'components/Common/SvgIcons/GradientCircleNotchIcon'
import { useBookmark } from 'components/AppTools/AppToolsHooks/bookmarkTool.hooks'

interface IAppToolBookmark {
  tool: Tool
  bookmarkCallback?: () => void
}

export const AppBookmarkTool: FC<IAppToolBookmark> = ({ tool, bookmarkCallback }) => {
  const { handleClick, isBookmarkingInProgress } = useBookmark(tool)
  return (
    <span className="flex gap-1 items-center text-primary text-xs font-[650] relative">
      <div className="absolute right-4">
        {isBookmarkingInProgress && <GradientCircleNotchIcon width="30" height="30" />}
      </div>
      <span onClick={() => handleClick(tool, bookmarkCallback)}>
        <BookMarkIcon
          stroke="#18181B"
          className={`${tool.isBookmarked ? 'fill-black-light' : ''} ${
            isBookmarkingInProgress ? 'pointer-events-none cursor-not-allowed' : ''
          }`}
        />
      </span>
    </span>
  )
}
