import React from 'react'

import { helpDesk } from 'utils/helper'
import SuggestionIcon from 'components/Common/SvgIcons/SuggestionIcon'
import QuestionMarkIcon from 'components/Common/SvgIcons/QuestionMarkIcon'
import { AppHelpCard } from 'components/Common/AppHelpCard/AppHelpCard.component'

export const AppToolCards = () => {
  return (
    <div>
      <div className="pt-3 pb-3">
        <AppHelpCard
          Icon={SuggestionIcon}
          label="Suggest a tool"
          handleOnClick={() =>
            window.open(
              'https://form.typeform.com/to/eV2JXFED?typeform-source=gotackle.typeform.com',
              '_blank'
            )
          }
        />
      </div>
      <AppHelpCard Icon={QuestionMarkIcon} label="Get Help" handleOnClick={() => helpDesk()} />
    </div>
  )
}
