import React, { useState, ReactNode } from 'react'

interface IAppTabContainerProps {
  tabAlignment?: 'center' | 'apart'
  tabHeaderClassName?: string
  tabHeaderTabsClassName?: string
  tabContentClassName?: string
  children: ReactNode
}

interface IAppTabProps {
  tabName: string
  tabCount?: number
  children: ReactNode
}

const AppTabContainerNew: React.FC<IAppTabContainerProps> = ({
  tabAlignment = 'apart',
  tabHeaderTabsClassName,
  tabContentClassName,
  children,
}) => {
  const tabs = React.Children.toArray(children) as React.ReactElement<IAppTabProps>[]
  const [activeTab, setActiveTab] = useState<string>(tabs[0]?.props.tabName || '')

  const currentTab = tabs.find((tab) => tab.props.tabName === activeTab)

  const headerAlignment = tabAlignment === 'center' ? 'justify-center' : 'justify-between'

  return (
    <div className="w-full">
      <div
        className={`flex space-x-4 text-base mb-8 font-semibold overflow-x-auto ${headerAlignment} ${tabHeaderTabsClassName}`}
      >
        {tabs.map((tab) => (
          <div
            key={tab.props.tabName}
            onClick={() => setActiveTab(tab.props.tabName)}
            className={`flex items-center space-x-2 py-1.5 px-4 rounded-lg shadow-sm cursor-pointer min-w-[150px] mdl:min-w-0 ${
              activeTab === tab.props.tabName
                ? 'bg-app-primary-70 shadow-sm'
                : 'border border-app-grey-30'
            }`}
          >
            <span
              className={`${
                activeTab === tab.props.tabName ? 'text-white' : 'text-app-grey-75'
              } font-medium text-sm font-inter`}
            >
              {tab.props.tabName}
            </span>
            {typeof tab.props.tabCount === 'number' && tab.props.tabCount >= 0 && (
              <span
                className={`${
                  activeTab === tab.props.tabName ? 'bg-white' : 'bg-app-grey-30 text-app-grey-75'
                } inline-block px-2 rounded-lg text-sm font-medium font-inter`}
              >
                {tab.props.tabCount}
              </span>
            )}
          </div>
        ))}
      </div>
      <div className={`${tabContentClassName}`}>{currentTab}</div>
    </div>
  )
}

const AppTab: React.FC<IAppTabProps> = ({ children }) => {
  return <div className="w-full font-inter">{children}</div>
}

export { AppTabContainerNew, AppTab }
