import React, { useRef, useState } from 'react'
import { uploadFiles } from './AppFileUploader.helper'
import { getTenantId } from 'utils/getTenantId'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import { UserFile } from '__generated__/api-types-and-hooks'
import { AppUploadImageIcon } from 'components/Common/AppSvgIcons/AppUploadImageIcon'
import GradientCircleNotchIcon from 'components/Common/SvgIcons/GradientCircleNotchIcon'

interface IAppFileUploaderProps {
  maxFiles?: number
  allowedFormats?: string[]
  maxFileSize?: number // in MB
  onUploadCompleted?: (uploadedFiles: UserFile[] | null) => void
  isFilesSaving?: boolean
}

export const AppFileUploader: React.FC<IAppFileUploaderProps> = ({
  onUploadCompleted,
  maxFiles = 10,
  allowedFormats = ['png', 'jpg', 'pdf', 'mkv', 'mp4', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'],
  maxFileSize,
  isFilesSaving,
}) => {
  const tenantId = getTenantId()
  const userId = useSelector((state: RootState) => state.user.user.id)
  const clientId = useSelector((state: RootState) => state.clients.client.profile.id)
  const [isDragging, setIsDragging] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isUploading, setIsUploading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(false)
    const droppedFiles = Array.from(e.dataTransfer.files)
    processFiles(droppedFiles)
  }

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || [])
    processFiles(selectedFiles)
  }

  const processFiles = (newFiles: File[]) => {
    setError(null)
    const fileExtensions = newFiles.map((file) => file.name?.split('.').pop())
    newFiles.forEach((file) =>
      console.log(
        file.type,
        allowedFormats.some((format) => fileExtensions?.includes(format))
      )
    )
    // Filter by file type if allowedFormats specified
    const validFiles =
      allowedFormats.length > 0
        ? newFiles.filter((file) =>
            allowedFormats.some((format) => fileExtensions?.includes(format))
          )
        : newFiles

    if (validFiles.length < newFiles.length) {
      setError(`Some files are not supported. Please check the file types and try again.`)
      fileInputRef.current && (fileInputRef.current.value = '')
      return
    }

    // Filter by file size
    const sizeValidFiles = maxFileSize
      ? validFiles.filter((file) => file.size <= maxFileSize * 1024 * 1024)
      : validFiles

    if (sizeValidFiles.length < validFiles.length) {
      setError(`Some files exceed the maximum size limit of ${maxFileSize}MB`)
      fileInputRef.current && (fileInputRef.current.value = '')
      return
    }

    // Limit number of files
    if (maxFiles && sizeValidFiles.length > maxFiles) {
      setError(`You can only upload up to ${maxFiles} files.`)
      fileInputRef.current && (fileInputRef.current.value = '')
      return
    }

    const finalFiles = sizeValidFiles.slice(0, maxFiles)
    handleUpload(finalFiles)
  }

  const handleUpload = async (filesToUpload: File[]) => {
    try {
      setIsUploading && setIsUploading(true)
      const uploadedFiles = await uploadFiles({ files: filesToUpload, tenantId, clientId, userId })
      onUploadCompleted &&
        onUploadCompleted(
          uploadedFiles.map((file) => ({ ...file, createdAt: new Date().toISOString() }))
        )
    } catch (error) {
      console.error(error)
      onUploadCompleted && onUploadCompleted(null)
    }

    setIsUploading && setIsUploading(false)
    fileInputRef.current && (fileInputRef.current.value = '')
  }

  return (
    <div className="w-full">
      <div
        className={`border-[1.5px] border-dashed border-app-grey-40 rounded-lg p-8 text-center cursor-pointer
          ${isDragging ? 'bg-gray-50' : 'bg-white'}`}
        onDragOver={(e) => {
          e.preventDefault()
          setIsDragging(true)
        }}
        onDragLeave={() => setIsDragging(false)}
        onDrop={handleDrop}
        onClick={() => document.getElementById('file-input')?.click()}
      >
        <div className="flex flex-col items-center gap-2">
          <AppUploadImageIcon />
          <p className="text-black-appDark font-inter text-sm">
            Drop your file here, or <span className="text-purple-strong">click to browse</span>
          </p>
          <p className="text-xs text-primary-disabledTextLight">
            {allowedFormats.map((format) => '.' + format).join(', ')}{' '}
            {maxFileSize ? `up to ${maxFileSize}MB` : ''}.
          </p>
        </div>
      </div>

      <input
        id="file-input"
        type="file"
        // multiple
        className="hidden"
        onChange={handleFileSelect}
        accept={allowedFormats.join(',')}
        ref={fileInputRef}
      />

      {(isUploading || isFilesSaving) && (
        <div className="mt-4 space-y-2">
          <div className="flex justify-center items-center gap-2">
            <GradientCircleNotchIcon width="30" height="30" />
            <span className="text-sm font-inter">Uploading...</span>
          </div>
        </div>
      )}

      {error && <div className="mt-4 text-red text-xs font-inter">{error}</div>}
    </div>
  )
}
