import React, { forwardRef, useEffect, useState } from 'react'
import ErrorMessage from 'components/Common/ErrorMessage'
import ToolTipWithButton from 'components/Common/ToolTipWithButton'
import InfoText from 'components/Common/InfoText'
import Button from 'components/Common/Button'
import CrossIcon from 'components/Common/SvgIcons/CrossIcon'
import { isEmpty } from 'lodash'

interface ImageUploadFieldProps {
  name?: string
  label?: string
  maxImages?: number
  allowedFormats?: string[]
  errors?: any
  disabled?: boolean
  hidden?: boolean
  className?: string
  labelClassName?: string
  labelFontSize?: string
  labelFontWeight?: string
  toolTipText?: string
  toolTipWidth?: string
  toolTipPlace?: string
  toolTipClass?: string
  toolTipOpacity?: number
  toolTipBackgroundColor?: string
  infoText?: string
  onChange?: (files: File[]) => void
  existingLogo?: string
  buttonLabel?: string
}
const ImageUploadField: React.ForwardRefRenderFunction<any, ImageUploadFieldProps> = (
  {
    name,
    label,
    maxImages = 1,
    allowedFormats = ['image/jpeg', 'image/png', 'image/gif'],
    errors,
    disabled,
    hidden,
    className,
    labelClassName,
    labelFontSize,
    labelFontWeight,
    toolTipText,
    toolTipWidth,
    toolTipPlace,
    toolTipClass,
    toolTipOpacity,
    toolTipBackgroundColor,
    infoText,
    onChange,
    existingLogo,
    buttonLabel,
  },
  ref
) => {
  const [imagePreviews, setImagePreviews] = useState<{ src: string; file: File }[]>([])
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || [])
    const validFiles = files.filter((file) => allowedFormats.includes(file.type))
    if (validFiles.length + imagePreviews.length > maxImages) {
      validFiles.splice(maxImages - imagePreviews.length)
    }
    const newImagePreviews = validFiles.map((file) => ({
      src: URL.createObjectURL(file),
      file,
    }))
    setImagePreviews((prev) => [...prev, ...newImagePreviews])
    if (onChange) onChange([...imagePreviews.map((preview) => preview.file), ...validFiles])
  }
  const handleImageDelete = (index: number) => {
    const updatedPreviews = imagePreviews.filter((_, i) => i !== index)
    setImagePreviews(updatedPreviews)
    if (onChange) onChange(updatedPreviews.map((preview) => preview.file))
  }
  useEffect(() => {
    if (disabled && imagePreviews.length > 0) {
      setImagePreviews([])
    }
  }, [disabled, imagePreviews.length])
  return (
    <div className={className}>
      <div className={`${hidden ? 'hidden' : ''}`}>
        {infoText && <InfoText infoText={infoText} />}
        <ToolTipWithButton
          label={label}
          toolTipText={toolTipText}
          toolTipWidth={toolTipWidth}
          toolTipClass={toolTipClass}
          toolTipOpacity={toolTipOpacity}
          toolTipBackgroundColor={toolTipBackgroundColor}
        />
        <Button
          label={buttonLabel}
          leftIcon="plus-circle"
          disabled={disabled}
          className={`upload-button ${labelClassName}`}
          onClick={(e) => {
            e.preventDefault()
            document.getElementById(`${name}`)?.click()
          }}
        />
        <input
          id={name}
          name={name}
          type="file"
          accept={allowedFormats.join(',')}
          multiple
          disabled={disabled}
          onChange={handleImageChange}
          className="hidden"
          ref={ref}
        />
        <div className="image-preview-grid mt-4 grid grid-cols-3 gap-2">
          {imagePreviews.map((preview, index) => (
            <div key={index} className="relative image-preview-item">
              <img src={preview.src} alt={`preview-${index}`} className="w-full h-auto rounded" />
              <CrossIcon
                className="absolute top-1 right-1 cursor-pointer "
                onClick={() => handleImageDelete(index)}
              />
            </div>
          ))}
          {imagePreviews.length === 0 && !isEmpty(existingLogo) && (
            <img src={existingLogo} alt={`preview-logo`} className="w-full h-auto rounded" />
          )}
        </div>
      </div>
      {errors && <ErrorMessage id={name}>{errors.message}</ErrorMessage>}
    </div>
  )
}
export default forwardRef(ImageUploadField)
