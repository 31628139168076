import React, { Fragment, useEffect, useMemo } from 'react'

import { isEmpty } from 'lodash'
import { useForm } from 'react-hook-form'
import { ROLES, ANNOUNCEMENT_NAMES, FORM } from 'config'

import PublishAnnouncement from 'components/Announcement/PublishAnnouncement'
import AnnouncementSuccessErrorScreen from 'components/Announcement/AnnouncementSuccessErrorScreen'
import { AnnouncementButtonWrapper } from 'components/NotificationPanel/style'
import Button from 'components/Common/Button'
import { PlusAnnouncementIcon } from 'components/Common/SvgIcons'

import useFormContainer from 'hooks/useFormContainer'
import { useFeatureFlag } from 'hooks/userFeatureFlag'
import { useGetBusinessesAndClients } from 'hooks/useGetBusinessesAndClients'

import { getRoleFromPath } from 'utils/helper'
import { IClients } from 'types'
import { AppModalBox } from 'components/Common/AppModalBox/AppModalBox.component'
import { getRandomColor } from 'appUtils'
import CircularLoading from 'components/Common/CircularLoading'
import hookForms from 'utils/hookForms'

const Announcement = ({
  userId,
  allClient,
  totalCount,
  activeStep,
  assignedClients,
  announcementSteps,
  announcementError,
  isFetchingResponse,
  isAllClientFetching,
  isOwnerDataLoading,
  getClientsAction,
  getBusinessesAction,
  setShowNotificationTooltip,
  setAnnouncementActiveStepAction,
  publishAnnouncementAction,
  uploadFileAction,
  uploadedFileId,
  uploadFileLoading,
}) => {
  const role = getRoleFromPath()

  useGetBusinessesAndClients(role, userId, getBusinessesAction, getClientsAction)

  const methods = useForm({
    mode: 'all',
  })
  const { control, reset, handleSubmit } = methods

  const createClientsOptions = (allClient: IClients[]) => {
    return allClient.map((client) => {
      // Split the name into parts, removing empty parts caused by extra spaces
      const nameParts = client.name
        ? client.name.split(' ').filter((part) => part.trim() !== '')
        : ['A', 'B']

      // Always pick the first and last parts for initials
      const initials =
        (nameParts[0][0]?.toUpperCase() || '') +
        (nameParts[nameParts.length - 1][0]?.toUpperCase() || '')

      return {
        label: client.name,
        value: client.userId,
        initials: initials,
        color: getRandomColor(),
      }
    })
  }

  const clientsOptions = useMemo(() => {
    return [
      { label: 'All My Clients', value: 'allMyOptions' },
      ...createClientsOptions(role === ROLES.BSO_ADVISOR ? assignedClients : allClient),
    ]
    // eslint-disable-next-line
  }, [assignedClients, allClient])

  const showAnnouncementButton = useMemo(() => {
    return role === ROLES.BUSINESS_OWNER || (isEmpty(allClient) && isEmpty(assignedClients))
      ? false
      : true
  }, [allClient, assignedClients, role])

  const onSubmit = (data) => {
    if (
      !data.receiverIds ||
      isEmpty(data.receiverIds) ||
      data.receiverIds.includes('allMyOptions')
    ) {
      if (role === ROLES.APP_ADMIN || role === ROLES.BSO_ADMIN) {
        data.receiverIds = allClient?.map((business) => business?.userId) || []
      } else if (role === ROLES.BSO_ADVISOR) {
        data.receiverIds = assignedClients?.map((client) => client?.id) || []
      }
    }
    if (data.fileData && isEmpty(uploadedFileId)) {
      uploadFileAction({
        files: data.fileData,
        isMultiple: false,
        saveFile: true,
      })
    } else {
      delete data.fileData
      publishAnnouncementAction({ ...data, fileId: uploadedFileId })
    }
  }
  const handleModalBoxClose = () => {
    reset()
    setShowNotificationTooltip(true)
    setAnnouncementActiveStepAction({ title: '' })
  }

  useEffect(() => {
    if (!uploadFileLoading && uploadedFileId) onSubmit(formValues)
    // eslint-disable-next-line
  }, [uploadedFileId])

  const isAnnouncementEnabled = useFeatureFlag(userId, 'release-announcement')
  useFormContainer(FORM.ANNOUNCEMENT_FORM, methods)
  const formValues = hookForms.getForm(FORM.ANNOUNCEMENT_FORM)?.getValues()

  return (
    <div>
      {isAnnouncementEnabled && (
        <Fragment>
          {isAllClientFetching ||
          role === ROLES.APP_ADMIN ||
          (role === ROLES.BSO_ADMIN && isOwnerDataLoading) ? (
            <div>
              <CircularLoading className={'app-bg-primary'} />
            </div>
          ) : (
            <AnnouncementButtonWrapper
              showAnnouncementButton={showAnnouncementButton}
              onClick={() => setAnnouncementActiveStepAction(announcementSteps[0])}
            >
              <PlusAnnouncementIcon />
              <Button
                variant="text"
                label={ANNOUNCEMENT_NAMES.NEW}
                className="focus:!border-none"
              />
            </AnnouncementButtonWrapper>
          )}
        </Fragment>
      )}
      {/* <NotificationPanelCount>({totalCount ? totalCount : 0})</NotificationPanelCount> */}

      {!isEmpty(activeStep) && (
        <AppModalBox
          onClose={handleModalBoxClose}
          bgColor="bg-transparent"
          margin="xs:px-3 mdl:px-0"
          title={activeStep === ANNOUNCEMENT_NAMES.CREATE ? ANNOUNCEMENT_NAMES.CREATE : ''}
        >
          {activeStep === ANNOUNCEMENT_NAMES.CREATE && (
            <PublishAnnouncement
              isLoading={uploadFileLoading}
              control={control}
              onSubmit={handleSubmit(onSubmit)}
              options={clientsOptions}
              isFetchingResponse={isFetchingResponse}
            />
          )}

          {activeStep === ANNOUNCEMENT_NAMES.AFTER_PUBLISH && !isFetchingResponse && (
            <AnnouncementSuccessErrorScreen
              announcementError={announcementError}
              reset={reset}
              setAnnouncementActiveStepAction={setAnnouncementActiveStepAction}
              announcementSteps={announcementSteps}
            />
          )}
        </AppModalBox>
      )}
    </div>
  )
}

export default Announcement
