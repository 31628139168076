import React from 'react'

export const AppCalendarPinIcon = () => {
  return (
    <svg width="41" height="8" viewBox="0 0 41 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.0517578"
        y="-5.53906"
        width="5.39131"
        height="13.4783"
        rx="2.69565"
        fill="#5A41F7"
      />
      <rect x="31.5508" y="-5.53906" width="5.39131" height="13.4783" rx="2.69565" fill="#5A41F7" />
    </svg>
  )
}
