export class ApiException extends Error {
  apiName: string
  constructor(message: string, apiName: string) {
    super(message)
    this.apiName = `${apiName}`
    this.name = `${this.constructor.name}: ${this.message}`
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    }
  }
}
