import React from 'react'

export const AppFileFormatIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="12" fill="url(#paint0_linear_10264_22532)" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.6875 15.25C17.8246 15.25 17.125 15.9496 17.125 16.8125V31.1875C17.125 32.0504 17.8246 32.75 18.6875 32.75H29.3125C30.1754 32.75 30.875 32.0504 30.875 31.1875V24.625C30.875 22.8991 29.4759 21.5 27.75 21.5H26.1875C25.3246 21.5 24.625 20.8004 24.625 19.9375V18.375C24.625 16.6491 23.2259 15.25 21.5 15.25H18.6875ZM20.25 26.5C20.25 26.1548 20.5298 25.875 20.875 25.875H27.125C27.4702 25.875 27.75 26.1548 27.75 26.5C27.75 26.8452 27.4702 27.125 27.125 27.125H20.875C20.5298 27.125 20.25 26.8452 20.25 26.5ZM20.875 28.375C20.5298 28.375 20.25 28.6548 20.25 29C20.25 29.3452 20.5298 29.625 20.875 29.625H24C24.3452 29.625 24.625 29.3452 24.625 29C24.625 28.6548 24.3452 28.375 24 28.375H20.875Z"
        fill="#7600ED"
      />
      <path
        d="M24.8093 15.5133C25.4733 16.2804 25.875 17.2808 25.875 18.375V19.9375C25.875 20.1101 26.0149 20.25 26.1875 20.25H27.75C28.8442 20.25 29.8446 20.6517 30.6117 21.3157C29.8719 18.4832 27.6418 16.2531 24.8093 15.5133Z"
        fill="#7600ED"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10264_22532"
          x1="24"
          y1="0"
          x2="24"
          y2="48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F5F5F5" />
          <stop offset="1" stop-color="#ECECEC" />
        </linearGradient>
      </defs>
    </svg>
  )
}
