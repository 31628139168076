import { RootState } from 'App'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { uploadFileAction } from 'store/actions/common'

const appCommentProps = (state: RootState) => {
  return {
    uploadedFileId: state.common.uploadedFile?.id,
    uploadedFileName: state.common.uploadedFile?.name,
    uploadedFileUrl: state.common.uploadedFile?.url,
    uploadFileLoading: state.common.uploadFileLoading,
  }
}

function appCommentDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      uploadFileAction: uploadFileAction.STARTED,
    },
    dispatch
  )
}

export const AppCommentConnector = connect(appCommentProps, appCommentDispatch)
