import React, { useEffect, useState } from 'react'

import {
  AppActiveGoalsWrapper,
  AppFilterButtonWrapper,
  AppActiveGoalsGrid,
  AppNoGoalsMessage,
  AppFilterHeader,
} from 'components/AppGoalCenter/AppGoalCenter.style'
import { AppGoalCard } from 'components/AppGoalCenter/AppGoalCard/AppGoalCard.component'
import { getRoleFromPath, isAdvisorRole } from 'utils/helper'
import history from 'utils/history'
import {
  BusinessGoal,
  ChatSessionStatus,
  useGetAdvisorAssignedTasksQuery,
  useGetChatSessionsQuery,
  useGetUserTasksQuery,
  UserTask,
  UserTaskItemStatus,
  UserTaskQuickFilter,
} from '__generated__/api-types-and-hooks'
import {
  BusinessGoalsFilterData,
  OnboardingFlowType,
  useCreateFlowChatSessionMutation,
} from '__generated__/api-types-and-hooks'
import { AppButton } from 'components/Common/AppButton/AppButton.component'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import { AppPlusIcon } from 'components/Common/AppSvgIcons/AppPlusIcon'
import redirectTo from 'utils/redirectTo'
import { ChatBotFlowUrl } from 'components/AppOnboarding/AppChatbot/AppChatBotFlows/constants'
import { getTenantId } from 'utils/getTenantId'
import { AppFilterIcon } from 'components/Common/AppSvgIcons/AppFilterIcon'
import { allTasksTodo, getLatestEndDate } from 'appUtils'
import { isEmpty, isUndefined } from 'lodash'
import { pageRoute } from 'V2/pages/config'
import { GOALIDS, OTHERTASKS } from 'appConfig/enums'
import { calculateGoalProgress } from 'components/AppDataReport/AppData/appUtils'
import { AppFilterMenu } from 'components/Common/AppFilterMenu/AppFilterMenu.component'
import { useClickOutside } from 'hooks/AppuseClickOutside/useClickOutside.hook'
import { goalsFilter, otherGoalIds } from 'appConfig/data'
import { useFeatureFlag } from 'hooks/userFeatureFlag'
import { AppModalBox } from 'components/Common/AppModalBox/AppModalBox.component'
import moment from 'moment'
import { LoadingIndicator } from 'stream-chat-react'
import { StartNewRegenModal } from './StartNewRegenModal'

interface IGoalProps {
  goals: BusinessGoal[]
  selectedFilters: BusinessGoalsFilterData
  setSelectedFilters: React.Dispatch<React.SetStateAction<BusinessGoalsFilterData>>
  refetchGoals?: ({ hasFilter }: { hasFilter?: boolean }) => void
  viewArchive: boolean
  setViewArchive: React.Dispatch<React.SetStateAction<boolean>>
  allPlays: BusinessGoal[]
}

export const AppGoals: React.FC<IGoalProps> = ({
  goals,
  selectedFilters,
  setSelectedFilters,
  refetchGoals,
  viewArchive,
  setViewArchive,
  allPlays,
}) => {
  const tenantId = getTenantId()
  const clientId = useSelector((state: RootState) => state.clients.client.profile.id)
  const userId = useSelector((state: RootState) => state.user.user.id)
  const role = getRoleFromPath()
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false)
  const [menuFilters, setMenuFilters] = useState<Record<string, (string | number)[]>>({})
  const [filtersCount, setFiltersCount] = useState(0)
  const [regeneratePlanGoal, setRegeneratePlanGoal] = useState<BusinessGoal | null>(null)
  const { mutate: createFlowChatSessionMutate, isLoading } = useCreateFlowChatSessionMutation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isRegeneratePlanModalOpen, setIsRegeneratePlanModalOpen] = useState(false)
  const [isFieldViewForMiscPlaysDisabled, setIsFieldViewForMiscPlaysDisabled] = useState(true)

  const isFieldViewForMiscPlaysDisabledState = useFeatureFlag(
    clientId ?? userId,
    'release-field-view-misc-plays'
  )

  useEffect(() => {
    if (isFieldViewForMiscPlaysDisabledState !== undefined) {
      setIsFieldViewForMiscPlaysDisabled(isFieldViewForMiscPlaysDisabledState)
    }
  }, [isFieldViewForMiscPlaysDisabledState])

  const isAdvisorAddGoalEnabled = useFeatureFlag(clientId ?? userId, 'release-advisor-add-goal')

  const { data: otherTasks } = useGetUserTasksQuery({
    input: {
      userId: clientId ?? userId,
      tenantId,
      filter: { quickFilter: UserTaskQuickFilter.NoProjectAssigned },
    },
  })

  const { data: advisorTasks } = useGetAdvisorAssignedTasksQuery(
    {
      input: {
        userId: clientId ?? userId,
        tenantId,
        filter: { quickFilter: UserTaskQuickFilter.NoProjectAssigned },
      },
    },
    {
      enabled: isAdvisorRole() && !clientId,
    }
  )

  const getFilterCount = (filters: Record<string, any>): number => {
    return Object.values(filters).reduce((count, values) => {
      return Array.isArray(values) ? count + values.length : count
    }, 0)
  }
  useEffect(() => {
    setFiltersCount(getFilterCount(selectedFilters))
    // eslint-disable-next-line
  }, [])

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const goalsPerPage = 6

  // Calculate the index of the last goal on the current page
  const indexOfLastGoal = currentPage * goalsPerPage
  const indexOfFirstGoal = indexOfLastGoal - goalsPerPage
  const currentGoals = goals?.slice(indexOfFirstGoal, indexOfLastGoal)

  // Calculate total pages
  const totalPages = Math.ceil(goals?.length / goalsPerPage)

  // Handler for toggling the archive view
  const handleToggleArchiveView = () => {
    setSelectedFilters((prev) => ({
      ...prev,
      isArchive: !viewArchive,
      statuses: [],
    }))
    setViewArchive(!viewArchive)
  }
  const goalsCount = viewArchive
    ? goals.filter(
        (goal) => goal.name !== OTHERTASKS.MY_OTHER_TASKS && goal.name !== OTHERTASKS.MY_ALL_PLAYS
      ).length
    : goals.length

  const { data: allTasks } = useGetUserTasksQuery({
    input: {
      tenantId,
      userId: clientId ?? userId,
      filter: {
        quickFilter: isAdvisorRole()
          ? UserTaskQuickFilter.NoProjectAssigned
          : UserTaskQuickFilter.AllGoalsTasks,
      },
    },
  })
  const { data: chatSessionsData, refetch: refetchChatSessions } = useGetChatSessionsQuery({
    input: {
      userId: clientId ?? userId,
    },
  })

  useEffect(() => {
    !isEmpty(menuFilters) && setSelectedFilters({ isArchive: viewArchive, ...menuFilters })
    // eslint-disable-next-line
  }, [isFilterPopupOpen])

  const handleFilterChange = (
    selectedFilters: Record<string, (string | number)[]>,
    filterCount: number
  ) => {
    setMenuFilters(selectedFilters)
    setFiltersCount(filterCount)
  }

  const divRef = useClickOutside(() => setIsFilterPopupOpen(false))
  const statusFilter = goalsFilter.find((filter) => filter.title === 'Status')
  const lastAddGoalSession = chatSessionsData?.getChatSessions?.find(
    (session) => session.flow === OnboardingFlowType.AddGoal
  )
  const lastSuggestGoalSession = chatSessionsData?.getChatSessions?.find(
    (session) => session.flow === OnboardingFlowType.SuggestGoal
  )
  if (statusFilter) {
    // Disable the specific options
    statusFilter.options.forEach((option) => {
      if (
        (option.value === UserTaskItemStatus.Todo ||
          option.value === UserTaskItemStatus.InProgress ||
          option.value === UserTaskItemStatus.Paused) &&
        viewArchive
      ) {
        option.hideOption = true
      } else if (option.value === UserTaskItemStatus.Done && !viewArchive) {
        option.hideOption = true
      } else {
        option.hideOption = false
      }
    })
  }

  const handleAddGoal = () => {
    if (
      clientId &&
      lastSuggestGoalSession?.status &&
      lastSuggestGoalSession?.status !== ChatSessionStatus.Completed
    ) {
      setIsModalOpen(true)
      return
    }
    if (
      !clientId &&
      lastAddGoalSession?.status &&
      lastAddGoalSession?.status !== ChatSessionStatus.Completed
    ) {
      setIsModalOpen(true)
      return
    }
    createFlowChatSessionMutate(
      {
        input: {
          flow: clientId ? OnboardingFlowType.SuggestGoal : OnboardingFlowType.AddGoal,
          ...(clientId ? { businessId: clientId } : {}),
        },
      },
      {
        onSuccess: (res) => {
          const role = getRoleFromPath()
          if (clientId) {
            redirectTo(
              `/${role}/${tenantId}/clients/${clientId}/onboarding/${
                ChatBotFlowUrl[OnboardingFlowType.AddGoal]
              }/${res.createFlowChatSession.id}`
            )
          } else {
            redirectTo(
              `/${role}/${tenantId}/onboarding/${ChatBotFlowUrl[OnboardingFlowType.GoalSetting]}/${
                res.createFlowChatSession.id
              }`
            )
          }
        },
      }
    )
  }

  const handleContinueGoal = () => {
    const role = getRoleFromPath()
    const lastSession = clientId ? lastSuggestGoalSession : lastAddGoalSession
    if (clientId) {
      redirectTo(
        `/${role}/${tenantId}/clients/${clientId}/onboarding/${
          ChatBotFlowUrl[OnboardingFlowType.AddGoal]
        }/${lastSession?.id}`
      )
    } else {
      redirectTo(
        `/${role}/${tenantId}/onboarding/${ChatBotFlowUrl[OnboardingFlowType.GoalSetting]}/${
          lastSession?.id
        }`
      )
    }
    setIsModalOpen(false)
  }

  const getEarliestTaskStartDate = (tasks: UserTask[] | BusinessGoal[] | null | undefined) => {
    if (tasks?.length === 0) {
      return undefined
    }

    const sortedTasks = tasks?.sort((a: UserTask | BusinessGoal, b: UserTask | BusinessGoal) => {
      if (!a.startDate || !b.startDate) return 0
      return moment(a.startDate).isSameOrBefore(moment(b.startDate)) ? -1 : 1
    })

    return sortedTasks?.[0]?.startDate
  }
  const inCompleteGoalCreationText = clientId
    ? 'Do you want to complete creating your suggested goal or start a new one?'
    : 'Do you want to complete creating your previous goal or start a new one?'


  const handleOpenRegeneratePlan = (goal: BusinessGoal) => {
    setRegeneratePlanGoal(goal)
    setIsRegeneratePlanModalOpen(true)
  }

  return (
    <AppActiveGoalsWrapper>
      {isModalOpen && (
        <AppModalBox
          title="Incomplete Goal Creation"
          onClose={() => setIsModalOpen(false)}
          width="w-[600px]"
        >
          <div className="flex flex-col gap-4 px-6 py-6">
            <p className="text-base text-app-grey-75 text-center">{inCompleteGoalCreationText}</p>
            <div className="flex justify-end gap-3">
              <AppButton
                variant="secondary"
                size="lg"
                label="Start New"
                onClick={() => {
                  createFlowChatSessionMutate(
                    {
                      input: {
                        flow: clientId
                          ? OnboardingFlowType.SuggestGoal
                          : OnboardingFlowType.AddGoal,
                        ...(clientId ? { businessId: clientId } : {}),
                      },
                    },
                    {
                      onSuccess: (res) => {
                        const role = getRoleFromPath()
                        if (clientId) {
                          redirectTo(
                            `/${role}/${tenantId}/clients/${clientId}/onboarding/${
                              ChatBotFlowUrl[OnboardingFlowType.AddGoal]
                            }/${res.createFlowChatSession.id}`
                          )
                        } else {
                          redirectTo(
                            `/${role}/${tenantId}/onboarding/${
                              ChatBotFlowUrl[OnboardingFlowType.AddGoal]
                            }/${res.createFlowChatSession.id}`
                          )
                        }
                      },
                      onSettled: () => {
                        setIsModalOpen(false)
                      },
                    }
                  )
                }}
              />
              <AppButton
                variant="primary"
                size="lg"
                label="Continue"
                onClick={handleContinueGoal}
              />
            </div>
          </div>
        </AppModalBox>
      )}
      <AppFilterHeader>
        <div className="lg:w-[45%]">
          <h3 className="text-3xl font-medium font-inter leading-9 ">
            {viewArchive ? 'Archived Goals' : 'Active Goals'}
          </h3>
          <p className="text-sm font-inter font-normal leading-6 text-app-grey-75">
            {viewArchive
              ? 'Below you will find your archived, declined, or completed goals. Click on each to view details or update the status.'
              : clientId
              ? "Below you will find your client's goals. Click on each to work towards it."
              : ' Below you will find your goals. Click on each to work towards it.'}
          </p>
        </div>

        <AppFilterButtonWrapper>
          {((isAdvisorRole() && isAdvisorAddGoalEnabled) || clientId || !isAdvisorRole()) && (
            <AppButton
              variant="primary"
              size="lg"
              label={clientId ? 'Suggest a Goal' : 'Add Goal'}
              RightIcon={() => (isLoading ? <LoadingIndicator size={20} /> : <AppPlusIcon />)}
              onClick={handleAddGoal}
              disabled={isLoading}
            />
          )}
          <div className="relative overflow-visible">
            <AppButton
              size="lg"
              variant="primary"
              RightIcon={AppFilterIcon}
              label={`Filters (${filtersCount})`}
              onClick={() => setIsFilterPopupOpen(true)}
            />
            <StartNewRegenModal
              isModalOpen={isRegeneratePlanModalOpen}
              onClose={() => setIsRegeneratePlanModalOpen(false)}
              goalId={regeneratePlanGoal?.goalId ?? ''}
              chatSessions={chatSessionsData?.getChatSessions}
              refetchGoals={refetchGoals}
              refetchChatSessions={refetchChatSessions}
            />
          </div>
          {isFilterPopupOpen && (
            <div ref={divRef} className="absolute z-50 top-16 right-0 -translate-x-1/2">
              <AppFilterMenu
                value={selectedFilters as Record<string, (string | number)[]>}
                categories={goalsFilter}
                onFilterChange={handleFilterChange}
              />
            </div>
          )}
          {((isAdvisorRole() && isAdvisorAddGoalEnabled) || !isAdvisorRole()) && (
            <AppButton
              variant="secondary"
              size="lg"
              label={viewArchive ? 'View Active Goals' : 'View Archive'}
              onClick={handleToggleArchiveView}
            />
          )}
        </AppFilterButtonWrapper>
      </AppFilterHeader>

      <AppActiveGoalsGrid>
        {!isUndefined(otherTasks) && allPlays && currentGoals?.length > 0
          ? currentGoals
              .filter(
                (goal) =>
                  !(
                    viewArchive &&
                    (goal.goalId === GOALIDS.MY_OTHER_TASKS ||
                      goal.name === OTHERTASKS.MY_ALL_PLAYS)
                  )
              )
              .map((goal) => {
                const isMyOtherTask = goal.goalId === GOALIDS.MY_OTHER_TASKS
                const isAllTasks = goal.name === OTHERTASKS.MY_ALL_PLAYS

                let tasks: UserTask[] = []
                if (isMyOtherTask) {
                  tasks = [
                    ...(otherTasks?.getUserTasks || []),
                    ...(advisorTasks?.getAdvisorAssignedTasks || []),
                  ]
                } else if (isAllTasks) {
                  tasks = [
                    ...(allTasks?.getUserTasks || []),
                    ...(advisorTasks?.getAdvisorAssignedTasks || []),
                  ]
                } else {
                  tasks = goal.plan?.tasks as UserTask[]
                }
                const progress = calculateGoalProgress(tasks)
                const expectedEndDate =
                  isMyOtherTask || isAllTasks ? getLatestEndDate(tasks) : goal.expectedEndDate!
                const startDate =
                  isMyOtherTask || isAllTasks ? getEarliestTaskStartDate(tasks) : goal.startDate

                const handleClick = () => {
                  const unclickableStatuses = [
                    UserTaskItemStatus.Creating,
                    UserTaskItemStatus.Pending,
                    UserTaskItemStatus.Archived,
                    UserTaskItemStatus.Draft,
                  ]
                  if (
                    unclickableStatuses.includes(goal.status as UserTaskItemStatus) ||
                    viewArchive
                  )
                    return
                  const basePath = `/${role}/${tenantId}`
                  const getPath = () => {
                    let revisedBasePath = basePath
                    if (clientId) {
                      revisedBasePath = `${basePath}/clients/${clientId}`
                    }
                    if (
                      otherGoalIds.includes(goal.goalId as GOALIDS) &&
                      isFieldViewForMiscPlaysDisabled
                    ) {
                      return `${revisedBasePath}/${goal.goalId}/task`
                    } else {
                      return `${revisedBasePath}/goals/${goal.goalId}/${pageRoute.gameMode}`
                    }
                  }
                  history.push(getPath())
                }

                return (
                  <AppGoalCard
                    key={goal.goalId}
                    progress={progress}
                    goalId={goal.goalId!}
                    onClick={handleClick}
                    logo={goal.logo ?? ''}
                    goalStatus={goal.status}
                    category={goal.category!}
                    refetchGoals={refetchGoals}
                    isMyOtherTask={isMyOtherTask || isAllTasks}
                    description={isMyOtherTask || isAllTasks ? goal?.description! : ''}
                    priority={goal.goalPriority!}
                    expectedEndDate={expectedEndDate}
                    startDate={startDate ?? undefined}
                    allTaskTodo={allTasksTodo(goal.plan)}
                    title={goal.name || 'No goal name provided'}
                    goal={goal}
                    openRegeneratePlanPopup={() => handleOpenRegeneratePlan(goal)}
                  />
                )
              })
          : null}
      </AppActiveGoalsGrid>

      {goalsCount === 0 &&
        (viewArchive ? (
          <AppNoGoalsMessage>No Archived Goals</AppNoGoalsMessage>
        ) : (
          <AppNoGoalsMessage>No Active Goals</AppNoGoalsMessage>
        ))}
      <div className="flex justify-center w-full">
        <div className="flex gap-2">
          <AppButton
            variant="clear"
            size="xs"
            label="Previous"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          />
          {Array.from({ length: totalPages }, (_, index) => (
            <AppButton
              key={index}
              variant="clear"
              size="xs"
              label={`${index + 1}`}
              onClick={() => setCurrentPage(index + 1)}
            />
          ))}
          <AppButton
            variant="clear"
            size="xs"
            label="Next"
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          />
        </div>
      </div>
    </AppActiveGoalsWrapper>
  )
}
