import React from 'react'

interface AppChevronRightIconProps {
  color?: string
}

export const AppChevronRightIcon: React.FC<AppChevronRightIconProps> = ({ color }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4140_1370)">
        <path
          d="M7.5 5L12.5 10L7.5 15"
          stroke="#A5A5A5"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4140_1370">
          <rect width="20" height="20" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
