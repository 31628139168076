import React from 'react'

export const AppTypingIndicator: React.FC = () => {
  return (
    <div className="h-4 w-10 flex items-center justify-center space-x-1 mt-auto mr-auto">
      <div className="w-3 h-3 bg-gray-600 rounded-full animate-bounce text-4xl">.</div>
      <div className="w-3 h-3 bg-gray-600 rounded-full animate-bounce animation-delay-150 text-4xl">
        .
      </div>
      <div className="w-3 h-3 bg-gray-600 rounded-full animate-bounce animation-delay-300 text-4xl">
        .
      </div>
    </div>
  )
}
