import { UserFile } from '__generated__/api-types-and-hooks'
import { AppFileFormatIcon } from 'components/Common/AppSvgIcons/AppFileFormatIcon'
import { AppFileMediaFormatIcon } from 'components/Common/AppSvgIcons/AppFileMediaFormatIcon'
import ThreeDotsIcon from 'components/Common/SvgIcons/ThreeDotsIcon'
import React, { useEffect, useState } from 'react'

interface IAppTaskFileCardProps {
  file: UserFile
}

export const AppTaskFileCard = ({ file }: IAppTaskFileCardProps) => {
  const [fileCategory, setFileCategory] = useState<'document' | 'media'>('document')
  const [fileSizeDisplay, setFileSizeDisplay] = useState<string>('')
  const mediaFileTypes = [
    'mp4',
    'mkv',
    'mp3',
    'wav',
    'avi',
    'mov',
    'wmv',
    'flv',
    'mpeg',
    'm4v',
    'webm',
  ]

  useEffect(() => {
    const fileExtension = file.name?.split('.').pop()
    if (
      fileExtension &&
      mediaFileTypes.some((type) => fileExtension.toLowerCase().includes(type))
    ) {
      setFileCategory('media')
    } else {
      setFileCategory('document')
    }

    //
    if (file.size && Number(file.size) < 1000) {
      setFileSizeDisplay(`${file.size} B`)
    } else if (file.size && Number(file.size) >= 1000 && Number(file.size) < 1_000_000) {
      setFileSizeDisplay(`${Math.round(Number(file.size) / 1000)} KB`)
    } else if (file.size && Number(file.size) >= 1_000_000 && Number(file.size) < 1_000_000_000) {
      setFileSizeDisplay(`${Math.round(Number(file.size) / 1_000_000)} MB`)
    } else if (
      file.size &&
      Number(file.size) >= 1_000_000_000 &&
      Number(file.size) < 1_000_000_000_000
    ) {
      setFileSizeDisplay(`${Math.round(Number(file.size) / 1_000_000_000)} GB`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  return (
    <div className="flex items-center justify-between bg-app-white-100 border border-app-grey-20 rounded-lg p-4 shadow-lg">
      <a
        href={file.url || ''}
        target="_blank"
        rel="noreferrer"
        className="flex items-center gap-3 w-[90%]"
      >
        {fileCategory === 'media' ? <AppFileMediaFormatIcon /> : <AppFileFormatIcon />}
        <div className="flex flex-col gap-1">
          <span className="text-sm font-normal font-inter">{file.name}</span>
          <span className="text-sm font-normal font-inter text-app-grey-70">{fileSizeDisplay}</span>
        </div>
      </a>
      <div>
        <ThreeDotsIcon />
      </div>
    </div>
  )
}
